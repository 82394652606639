<template>
  <div class="big-container" v-show="showLoading">
    <div class="loading-container">
      <v-card :color="backgroundColor" class="card-container" flat>
        <p :style="{ color: textColor}">{{ $t('loader.loading') }}</p>
        <v-progress-circular
          indeterminate
          :color="progressColor"
          class="mb-0"
        ></v-progress-circular>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showLoading: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String,
      default: "dark"
    },
    textColor: {
      type: String,
      default: "primary"
    },
    progressColor: {
      type: String,
      default: "primary"
    }
  },
};
</script>

<style lang="scss" scoped>
.big-container {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba($color: white, $alpha: 0.25);
  top: 0;
  left: 0;
  z-index: 1000;
  .loading-container {
    background-color: transparent;
    position: absolute;
    width: 100%;
    z-index: 1;
    display: flex;
    align-content: center;
    justify-content: center;
    bottom: 45%;
    left: 0;
    .card-container {
      padding: 20px 24px 24px 24px;
      text-align: center;
    }
  }
}
</style>
