
import game from "@/game"
import { eventBus } from '../../common/eventBus.js';
import i18n from '../../i18n.js';
import router from '../../router';

const state = {
  responseSignup: {},
  responseValidToken: {},
  responseActiveEmail: {},
  user_id: '',
};

const mutations = {
  SIGN_UP: (state, payload) => {
    state.responseSignup = payload
  },
  VERIFY_EMAIL: (state, payload) => {
    state.responseValidToken = payload
  },
  ACTIVE_EMAIL: (state, payload) => {
    state.responseActiveEmail = payload
  },
  SIGN_IN: (state, payload) => {
    state.user_id = payload
  },
  FORGOT_PASSWORD: () => {
  },
  START_LOADING: () => {

  },
};

const actions = {
  signUp({ commit }, email) {
    game.emailSignUp(email, function (res) {
      commit("SIGN_UP", res);
    })
  },
  verifyEmail({ commit }, token) {
    game.validToken(token, function (res) {
      commit("VERIFY_EMAIL", res);
    })
  },
  activeEmail({ commit }, req) {
    game.activateEmail(req, function (res) {
      commit("ACTIVE_EMAIL", res);
      switch(true){
        case res.message_key.includes("new_password_must_be_different_old_password"):
          eventBus.$emit("showNotification", {
            active: true,
            type: "error",
            message: i18n.t('validate.newPassword'),
          });
          break;
        default:
          eventBus.$emit('showNotification', {
            active: false,
          });
          break;
      }
    })
  },
  signIn({commit}, action) {
    game.emailSignIn(action.email,action.password, function(res) {
      if(res.data?.sesid) {
        router.push({
          path: (res.data?.role === "admin" || res.data?.role === "government") ? 
          "/admin/users" : "/user/play/"}).catch(()=>{});
          
        commit("SIGN_IN", res.data?.user_id);
        eventBus.$emit('showNotification', {
          active: false,
        })
      } else {
        switch(res.message_key){
          case "email_is_not_activated":
            eventBus.$emit('showNotification', {
              active: true,
              type: "error",
              message: i18n.t('signIn.emailNotActive')
            })
            break;
          default:
            eventBus.$emit('showNotification', {
              active: true,
              type: "error",
              message: i18n.t('signIn.errorMessage')
            })
            break;
        }
      }
    });
  },
  checkCookieSignIn() {
    console.log('cookie')
    game.tryCookieSignIn(function(res) {
      eventBus.$emit('showNotification', {
        active: false,
      });
      if(res.data?.user_id) {
        router.push({path: "/user/play/"}).catch(()=>{});
      } else {
        router.push({path: "/accounts/signin"}).catch(()=>{});
      }
    });
  },
  forgotPassword({commit}, action) {
    localStorage.setItem("emailForgot", action.email);
    game.forgotPassword(action.email, function(res) {
      commit("FORGOT_PASSWORD")
      console.log('res ', res.error)
      if (Number(res.code) === 200) {
        router.push({ path: "/accounts/resetpassword/waitingconfirmation" }).catch(()=>{});
      } else {
        switch (res.error) {
          case "not_found":
            eventBus.$emit("showNotification", {
              active: true,
              type: "error",
              message: i18n.t('signUp.emailInvalid'),
            });
            break;
          case "email_is_not_activated":
            router.push({ path: "/accounts/verifyemail/waitingconfirmation"}).catch(()=>{});
            eventBus.$emit('showNotification', {
              active: false,
            });
            break;
          default:
            eventBus.$emit('showNotification', {
              active: false
            })
            break;
        }
      }
    });
  },
  checkExpiredResetPassword({commit}, action){
    game.checkExpiredResetPassword(action.token, function(res) {
      commit('START_LOADING')
      if (res.code === 200) {
        eventBus.$emit("showNotification", {
          active: false,
        });
      } else {
        eventBus.$emit("showNotification", {
          active: true,
          type: "error",
          message: i18n.t('messageNotification.verifyInvalidToken'),
        });
        setTimeout( () =>router.push({ path: "/accounts/signin" }).catch(()=>{}), 1000);
      }
    });
  },
  resetPassword({commit}, action) {
    game.resetPassword(action.tokenResetPassword, action.password, function(res) {
      commit('START_LOADING')
      switch (res.error) {
        case "ok":
          setTimeout( () =>router.push({ path: "/accounts/signin" }).catch(()=>{}), 1000);
          break;
        default:
          if(res.message_key.includes("new_password_must_be_different_old_password")){
            eventBus.$emit("showNotification", {
              active: true,
              type: "error",
              message: i18n.t('validate.newPassword'),
            });
          } else{
            eventBus.$emit('showNotification', {
              active: false
            })
          }
          break;
      }
    });
  },
  signOut(){
    game.signOut(function (res) {
      res.code === 200 && router.push({ path: "/" });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
