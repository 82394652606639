<template>
  <v-container class="comment mt-3">
    <infinity-scroll
      maxHeight="calc(100vh - 360px)"
      height="calc(100vh - 360px)"
      :reverse="true"
      :isLoading="isLoadingComments"
      :loadData="loadData"
      :hasLoadMore="hasLoadMoreComments"
      :typeMessage="contentComment"
      lightTheme
    >
      <div v-for="(item, i) in comments" :key="i">
        <survey-item-comment :item="item"></survey-item-comment>
      </div>
    </infinity-scroll>
    <v-card
      color="transparent"
      elevation="0"
      class="pr-3 pl-3 mr-3 mb-1 bottom-form"
    >
      <v-form class="input-comment" ref="form" lazy-validation>
        <v-textarea
          solo
          color="#182750"
          flat
          rows="3"
          background-color="white"
          no-resize
          v-model="contentComment"
          :rules="rulesTextarea()"
          :placeholder="$t('survey.placeholderComment')"
          ref="comment"
        ></v-textarea>
        <v-btn
          class="mx-2"
          fab
          dark
          height="35"
          width="35"
          color="primary"
          depressed
          v-on:click="sendComment()"
          :disabled="isDisable"
        >
          <v-icon color="black" size="20"> mdi-send </v-icon>
        </v-btn>
      </v-form>
    </v-card>
  </v-container>
</template>
<script>
import InfinityScroll from "../../components/InfinityScroll/InfinityScroll.vue";
import SurveyItemComment from "../../components/survey-post/SurveyItemComment.vue";
import { mapGetters } from "vuex";
import WS from "../../services/ws";

export default {
  components: {
    SurveyItemComment,
    InfinityScroll,
  },
  data: () => ({
    contentComment: "",
    page: 0,
  }),
  watch: {
    addCommentSuccess() {
      if (this.addCommentSuccess?.code === 200) {
        this.contentComment = "";
      }
    },
  },
  mounted() {
    this.fetchingData();
  },
  created: function () {
    const _this = this;

    WS.onmessage = function (event) {
      if (JSON.parse(event?.data).surveyId || JSON.parse(event?.data).roundInspectionSurveyId) _this.fetchingData();
    };
  },
  methods: {
    fetchingData() {
      const body = {
        id: this.$route.params.id,
        page: 0,
      };
      if (Number(this.$route.query.type) === 2) {
        this.$store.dispatch("survey/getRoundComments", body);
      } else {
        this.$store.dispatch("survey/getSurveyComments", body);
      }
    },
    rulesTextarea() {
      return [
        (v) => v?.length <= 200 || this.$t("survey.textMax", { max: 200 }),
      ];
    },
    sendComment() {
      const data = {
        id: this.$route.params.id,
        body: {
          content: this.contentComment?.trim(),
        },
      };
      if (Number(this.$route.query.type) === 2) {
        this.$store.dispatch("survey/addRoundComment", data);
      } else {
        this.$store.dispatch("survey/addSurveyComment", data);
      }
    },
    loadData() {
      this.page++;
      this.listSurveyComments({
        page: this.page,
        idSurvey: this.$route.params.id,
      });
    },
    listSurveyComments(data) {
      const body = {
        id: this.$route.params.id,
        page: data.page,
      };
      if (Number(this.$route.query.type) === 2) {
        this.$store.dispatch("survey/getRoundComments", body);
      } else {
        this.$store.dispatch("survey/getSurveyComments", body);
      }
    },
  },

  computed: {
    ...mapGetters([
      "comments",
      "addCommentSuccess",
      "isLoadingComments",
      "hasLoadMoreComments",
    ]),
    isDisable() {
      return (
        this.contentComment.length > 200 || this.contentComment.length === 0
      );
    },
  },
};
</script>
<style lang="scss">
.comment {
  height: calc(100vh - 220px) !important;
  .bottom-form {
    margin-top: 5px;
    width: 100%;
    .input-comment {
      position: relative;
      width: 100%;
      .v-textarea textarea {
        color: #182750 !important;
        padding-right: 45px !important;
      }
      .v-textarea textarea::-webkit-scrollbar {
        display: none;
      }
      .v-textarea textarea::placeholder {
        color: #182750 !important;
      }
      .v-btn {
        position: absolute;
        top: 8px;
        right: 0;
        .v-icon {
          transform: rotate(-45deg);
          margin-top: -3px;
          margin-right: -3px;
        }
      }
      .v-btn.v-btn--disabled.v-btn--has-bg {
        background-color: rgba(37, 37, 37, 0.2) !important;
        .v-icon {
          color: rgba(22, 22, 22, 0.2) !important;
        }
      }
    }
  }
}
</style>