import game from "@/game"
import _ from 'lodash';
import store from "@/store";
import router from "../../router";
import { eventBus } from '../../common/eventBus.js';
import i18n from '../../i18n.js';

const state = {
  governments: {
    loading: true,
    currentPage: 1,
    resources: [],
    totalItems: 0,
    totalPages: 0
  },
  government: {},
  changePasswordSuccess: false,
  resetFormCreateGovernment: false
};

const mutations = {
  FETCH_GOVERNMENTS: (state, payload) => {
    state.governments = {...payload, currentPage: payload.currentPage + 1}
  },

  GOVERNMENT_ACTIVE_SUCCESS: (state, payload) => {
    state.governments.resources = _.map(state.governments.resources, (government) => {
      return government.id === payload.id ? {...government, active: payload.active} : government
    })
  },

  GOVERNMENT_ACTIVE_DETAIL_SUCCESS: (state, payload) => {
    state.government = {...state.government, active: payload.active}
  },

  GET_GOVERNMENT_SUCCESS: (state, payload) => {
    state.government = payload
  },

  REFRESH_DATA: (state) => {
    state.governments = {
      loading: true,
      currentPage: 1,
      resources: [],
      totalItems: 0,
      totalPages: 0
    }
  },

  UPDATE_GOVERNMENT_SUCCESS: () => {
  },

  CHANGE_PASSWORD_SUCCESS: (state) => {
    state.changePasswordSuccess = true
  },

  CREATE_GOVERNMENT_SUCCESS: (state) => {
    state.resetFormCreateGovernment = true
  },

  TOGGLE_RESET_FORM: (state, payload) => {
    state.resetFormCreateGovernment = payload
  },

};

const actions = {
  fetchGovernments({ commit }, configs) {
    return game.getGovernments(configs, function(res) {
      if(res.code === 200 && res.data) {
        commit("FETCH_GOVERNMENTS", res.data);
      }
    })
  },

  changeGovernmentActive({ commit }, id) {
    game.changeGovernmentActive(id, function(res) {
      if(res.code === 200 && res.data) {
        commit("GOVERNMENT_ACTIVE_SUCCESS", res.data?.user);
      }
    })
  },

  changeGovernmentActiveDetail({ commit }, id) {
    game.changeActiveGovernment(id, function(res) {
      if(res.code === 200 && res.data) {
        commit("GOVERNMENT_ACTIVE_DETAIL_SUCCESS", res.data?.user);
      }
    })
  },

  getGovernmentDetail({ commit }, id) {

    return game.getGovernmentDetail(id, function(res) {
      switch(res?.message_key){
        case 'ok':
          commit("GET_GOVERNMENT_SUCCESS", res.data?.user);
          commit("adminUsers/ADD_PREF_CITY", res.data?.user, { root: true });
          break;
        case 'not_found':
          eventBus.$emit("showNotification", {
            active: true,
            type: "error",
            message: "Not found",
          });
          router.push('/*').catch(()=>{});
          break;
        default:
          break;
      }
    })
  },

  createNewGovernment({ commit }, user) {
    let configs = {
      page: 0,
      keyword: null,
      limit: 10,
      sortType: 'ASC' 
    } 
    game.createGovernment(user, function(res) {

      switch (true) {
        case res.message_key === 'ok':
          commit("CREATE_GOVERNMENT_SUCCESS", res.data?.user);
          if(res?.data?.user) router.push({path: `government/${res?.data?.user?.id}`})
          store.dispatch("adminGovernments/fetchGovernments", configs)
          break;
        case res.message_key === 'already_exist':
          eventBus.$emit("showNotification", {
            active: true,
            type: "error",
            message: i18n.t('signUp.emailRegistered'),
          });
          commit("TOGGLE_RESET_FORM", false);
          break;
        case res.message_key === 'waiting_confirm':
          eventBus.$emit("showNotification", {
            active: true,
            type: "error",
            message: i18n.t('signUp.emailRegistered'),
          });
          commit("TOGGLE_RESET_FORM", false);
          break;
        case res.message_key.includes("nickname_is_exist"):
          eventBus.$emit('showNotification', {
            active: true,
            type: "error",
            timeout: 3000,
            message: i18n.t('validate.usernameTaken')
          })
          break;
        default: 
          commit("TOGGLE_RESET_FORM", false);
          break;
      }
    })
  },

  toggleResetForm({commit}, value) {
    commit("TOGGLE_RESET_FORM", value);
  },

  refreshDataGovernments({commit}) {
    commit("REFRESH_DATA");
  },

  editGovernment({commit}, data) {
    let configs = {
      page: 0,
      keyword: null,
      limit: 10,
      sortType: 'ASC' 
    } 
    game.editGovernment(data, function(res) {
      switch(res.message_key){
        case 'ok':
          commit("UPDATE_GOVERNMENT_SUCCESS", res.data?.user);
          store.dispatch("adminGovernments/getGovernmentDetail", res?.data?.user?.id)
          store.dispatch("adminGovernments/fetchGovernments", configs)
          break;
        default: 
          switch (true) {
            case res.error.includes("nickname_is_exist"):
              eventBus.$emit('showNotification', {
                active: true,
                type: "error",
                timeout: 3000,
                message: i18n.t('validate.usernameTaken')
              })
              break;
            case res.error.includes("email must be unique"):
              eventBus.$emit('showNotification', {
                active: true,
                type: "error",
                timeout: 3000,
                message: i18n.t('signUp.emailRegistered')
              })
              break;
            default:
              break;
          }
          commit("TOGGLE_RESET_FORM", false);
          break;
      }
    })
  },

  changePasswordGovernment({commit}, data){
    game.changePasswordGovernment(data, function(res) {
      switch(res.message_key){
        case 'ok':
          commit("CHANGE_PASSWORD_SUCCESS");
          break;
        default: 
          if(res.message_key.includes("new_password_must_be_different_old_password")){
            eventBus.$emit("showNotification", {
              active: true,
              type: "error",
              message: i18n.t('validate.newPassword'),
            });
          }
          commit("TOGGLE_RESET_FORM", false);
          break;
      }
    })
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};