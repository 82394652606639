<template>
  <v-card color="primary" elevation="0" class="mr-3 ml-3 mb-2 content-comment">
    <v-list-item class="pb-5">
      <v-list-item-avatar
        tile
        size="48"
      >
      <v-avatar
        color="transparent"
      >
        <v-img
            alt="user avatar"
            class="shrink"
            contain
            :src="item.user.avatar_url || avatarDefault"
            transition="scale-transition"
            width="48"
            height="48"
        />
      </v-avatar>
      </v-list-item-avatar>
      <v-list-item-content class="text-comment" color="#182750">
       <div class="date-name">
         <span class="nickname">{{item.user.nickname}}</span>
         <span class="date ml-2">{{formatTime(item.createdAt)}}</span>
       </div>
       <span class="content">{{item.content}}</span>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>
<script>

import moment from 'moment'

export default {
  props: {
    item: { type: Object, required: true },
  },
  data() {
    return {
      avatarDefault: require("@/assets/guadians-thumb.jpg")
    }
  },
  components: {
  },
  methods: {
    formatTime(time) {
      return moment(time).format("hh:mma")
    },
  }
};
</script>
<style lang="scss">
  .content-comment{
    .text-comment{
      color: #182750 !important;
      .date-name{
        display: flex;
        justify-content: space-between;
        .date{
          font-size: 12px;
        }
      }
      .content{
        font-size: 14px;
        white-space: pre-line;
        word-break: break-all;
      }
    }
    
  }
</style>