<template>
  <v-card class="mx-auto government-detail" max-width="90%" outlined dark>
    <div class="government-detail_title">{{`市区町村管理者詳細 #${$route.params.id}`}}</div>
    <v-container class="government-detail_content mt-4">
      <v-card color="transparent" flat>
        <v-list-item-avatar
          tile
          size="100"
          :rounded="true"
          v-if="government.avatarUrl !== ''"
          class="mb-5"
        >
          <v-avatar size="100">
            <img
              :src="government.avatarUrl"
              :alt="government.nickname"
            >
          </v-avatar>
        </v-list-item-avatar>
        <v-form 
          ref="form" 
          :key="`form-${{componentKey}}`"
          v-model="validInformation"
          lazy-validation
        >
          <p class="government-detail_content__main-title">詳細情報</p>
          <input-text
            title="ユーザー名"
            :value="username"
            type="input"
            :rules="usernameRules"
            :changeData="(e) => username = e"
          ></input-text>

          <input-text
            title="メールアドレス"
            :value="email"
            type="input"
            :rules="emailRules"
            :changeData="(e) => email = e"
          ></input-text>

          <input-text
            lightTheme
            title="都道府県"
            :value="pref"
            type="select"
            :data="prefectures.resources"
            valueData="pref"
            :nameData="(item) => item.name"
            :callbackSelect="choosePrefecture"
            :endIntersect="endIntersectPref"
            :rules="cityPrefRules"
          ></input-text>

          <input-text
            lightTheme
            title="市区町村"
            :value="city"
            type="select"
            :data="cities.resources"
            valueData="id"
            :nameData="(item) => item.name"
            :endIntersect="endIntersectCity"
            :callbackSelect="(e) => city = e"
            :rules="cityPrefRules"
          ></input-text>

          <div class="d-flex align-end justify-end mt-10">
            <v-btn
              @click="submitInformation"
              height="40"
              depressed
              class="mr-3 mb-5"
              color="primary dark--text"
              :disabled="!isEnabledButtonInformation || !validInformation"
              >{{ $t("profile.keep") }}
            </v-btn>
            <v-btn
              @click="resetFormInformation"
              height="40"
              depressed
              color="primary--text"
              class="mr-3 mb-5"
              >{{ $t("confirmDialog.cancel") }}
            </v-btn>
          </div>
        </v-form>


        <v-divider></v-divider>
        <v-form 
          ref="formPassword" 
          :key="`formPassword-${{componentKeyPassword}}`"
          v-model="validPassword"
          lazy-validation
        >
          <p class="government-detail_content__main-title">パスワードリセット</p>
          <input-text
            title="新しいパスワード"
            type="password"
            :value="password"
            :changeData="(e) => password = e"
            :rules="rulesPassword"
          ></input-text>
          <input-text
            title="新しいパスワード（確認）"
            type="password"
            :value="confirmPassword"
            :rules="confirmPasswordRules"
            :changeData="(e) => confirmPassword = e"
          ></input-text>
          <div class="d-flex align-end justify-end mt-10">
            <v-btn
              @click="submitPassword"
              height="40"
              depressed
              class="mr-3 mb-5"
              color="primary dark--text"
              :disabled="!isSubmitPassword"
              >{{ $t("profile.keep") }}</v-btn
            >
            <v-btn
              @click="resetFormPassword"
              height="40"
              depressed
              color="primary--text"
              class="mr-3 mb-5"
              >{{ $t("confirmDialog.cancel") }}</v-btn
            >
          </div>
        </v-form>

        <v-divider></v-divider>
        
        <p class="government-detail_content__main-title">状態更新</p>
        <div class="d-flex align-center justify-content-between status">
          <span>状態</span>
          <input-select 
            :value="government"
            type="government"
          >
          </input-select>
        </div>
      </v-card>
    </v-container>
  </v-card>

</template>

<script>

import { mapGetters } from 'vuex';
import InputSelect from '../../components/admin/InputSelectStatus.vue';
import InputText from '../../components/admin/InputText.vue';

export default {
  components: { 
    InputSelect,
    InputText
  },
  data () {
    return {
      value: {},
      username: "",
      email: "",
      pref: "",
      city: "",
      componentKey: 0,
      password: "",
      confirmPassword: "",
      validInformation: true,
      componentKeyPassword: 0,
      validPassword: true,
    }
  },
  watch: {
    async $route() {
      if(this.$route.name === "市区町村管理者アカウント詳細"){
        await this.getGovernment();
        this.resetFormInformation()
        this.resetFormPassword()
      }
    },

    pref () {
      if(this.pref !== '') {
        this.$store.dispatch("adminUsers/getCities", {
          id: this.pref,
          page: 0,
        })
      }
    },

    changePasswordSuccess() {
      if(this.changePasswordSuccess){
        this.resetFormPassword()
      }
    }
  },
  computed: {
    ...mapGetters([
      "government",
      "prefectures", 
      "cities",
      "changePasswordSuccess",
    ]),
   
    emailRules() {
      return [
        v => !!v || this.$t('validate.validAdminEmailAddress'),
        v => /.+@.+\..+/.test(v) || this.$t('validate.validAdminEmailAddress'),
      ]
    },
    usernameRules() {
      return [
        v => !!v || this.$t('validate.requiredUsername'),
        (v) => /^[A-Za-z0-9_]+$/.test(v) || this.$t("validate.usernameAcceptAdmin"),
        (v) => (v.length <= 50) || this.$t("validate.usernameLess"),
      ]
    },
    rulesPassword() {
      return [
        (v) => (v && v.length >= 6) || this.$t("validate.passwordAdminRule"),
        (v) => (v && v.length <= 30) || this.$t("validate.passwordAdminRule")
      ]
    },
    confirmPasswordRules() {
      return [
        v => !!v || this.$t('validate.requiredUsername'),
        (v) => v === this.password || this.$t("validate.passworAdmindMatch"),
        this.confirmPassword === this.password ||
          this.$t("validate.passworAdmindMatch"),
      ];
    },
    cityPrefRules() {
      return [
        (v) => (v > 0) || this.$t('validate.requiredUsername'),
      ]
    },
    isEnabledButtonInformation() {
      return this.username && this.email && this.pref && this.city
      && (this.username !== this.government.nickname || this.email !== this.government.email ||
      (this.pref !== this.government?.pref?.id && this.city) || this.city !== this.government?.city?.id)
    },
    
    isSubmitPassword () {
      return this.validPassword && this.password && this.confirmPassword && this.password === this.confirmPassword
    }
  },
  
  methods: {
    async getGovernment() {
      const _this = this;
      const id = _this.$route.params.id
      id && await _this.$store.dispatch("adminGovernments/getGovernmentDetail", id)
    },
    choosePrefecture(e) {
      this.pref = e
      this.city = null
    },

    forceRerender() {
      this.componentKey += 1;
    },

    resetFormInformation() {
      this.email = this.government.email
      this.username = this.government.nickname
      this.city = this.government?.city?.id
      this.pref = this.government?.pref?.id
      this.forceRerender();
    },

    submitInformation() {
      const data = {
        body: {
          email: this.email,
          nickname: this.username,
          city: this.city,
          pref: this.pref
        },
        id: this.$route.params.id
      }
      this.$refs.form.validate()
      this.$store.dispatch("adminGovernments/editGovernment", data)
    },

    submitPassword() {
      const data = {
        body: {
          password: this.password,
        },
        id: this.$route.params.id
      }
      this.$refs.formPassword.validate()
      this.isSubmitPassword && this.$store.dispatch("adminGovernments/changePasswordGovernment", data)
    },

    resetFormPassword() {
      this.$refs.formPassword.resetValidation()
      this.$refs.formPassword.reset()
    },

    endIntersectPref(isIntersecting) {
      if (isIntersecting && this.prefectures.currentPage + 1 < this.prefectures.totalPages) {
        this.$store.dispatch("adminUsers/getPrefectures", this.prefectures.currentPage + 1)
      }
    },

    endIntersectCity(isIntersecting) {
      if(isIntersecting && (this.cities.currentPage + 1 < this.cities.totalPages)){
       this.$store.dispatch("adminUsers/getCities", {
          id: this.pref,
          page: this.cities.currentPage + 1,
        })
      }
      if(this.cities.currentPage + 1 === this.cities.totalPages){
        this.forceRerender();
      }
    }

  },

  async mounted() {
    await this.getGovernment()
    await this.$store.dispatch("adminUsers/getPrefectures", 0)
    this.pref = this.government?.pref?.id
    this.city = this.government?.city?.id
    await this.$store.dispatch("adminUsers/getCities", {
      id: this.pref,
      page: 0,
    })
    this.resetFormInformation()
  }
}
</script>

<style lang="scss">
.government-detail{
  font-size: 12px;
  &_title{
    color: #81ffff !important;
    font-size: 20px !important;
    line-height: 2rem;
    letter-spacing: 0.1666666667em !important;
    text-transform: uppercase !important;
    margin: 1rem;
  }
  &_content{
    &__main-title{
      font-size: 1rem;
      margin: 1rem 0;
      color: #81ffff !important;
    }
  }
  .v-input__slot{
    min-width: 200px;
    justify-content: end;
    fieldset{
      color: #fff !important;
    }
  }
  .status{
    justify-content: space-between;
    select{
      min-width: 200px;
      box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
      border-collapse: collapse;
      border-color: #27f8f9;
      border-style: solid;
      border-width: 2px;
      height: 40px;
      border-radius: 15px;
      padding: 0 10px;
    }
  }
  .item{
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    &_information{
      box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
      border-collapse: collapse;
      border-color: #27f8f9;
      border-style: solid;
      border-width: 2px;
      height: 40px;
      border-radius: 15px;
      padding: 7px 10px;
      width: fit-content;
      min-width: 200px;
      color: #bababa;
      font-size: 14px;
    }
  }
}
  

</style>