// Manhole lid basic information
const lid_type = {
  title: 'survey.lid_type',
  type: "input",
  column: "lid_type",
  defaultValue: "",
  rules: "range0to10"
};


const manufacture_year = {
  title: 'survey.manufacture_year',
  type: "input",
  column: "manufacture_year",
  defaultValue: "",
  rules: "range1900to2022"
};

const lid_completion_year = {
  title: 'survey.lid_completion_year',
  type: "input",
  column: "lid_completion_year",
  defaultValue: "",
  rules: "range1950to2050"
};

const lid_completion_date = {
  title: 'survey.lid_completion_date',
  type: "date",
  column: "lid_completion_date",
  defaultValue: { id: '' },
};

const manufacture = {
  title: 'survey.manufacture',
  type: "input",
  column: "manufacturer",
  defaultValue: "",
  // rules: "interger"
};
const lid_diameter = {
  title: 'survey.lid_diameter',
  type: "select",
  column: "lid_diameter",
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: 300,
      id: 1,
    },
    {
      name: 400,
      id: 2,
    },
    {
      name: 500,
      id: 3,
    },
    {
      name: 600,
      id: 4,
    },
    {
      name: 750,
      id: 8,
    },
    {
      name: 900,
      id: 5,
    },
    {
      name: 'survey.900parent',
      id: 6,
    },
    {
      name: 'survey.other',
      id: 7,
    },
  ],
  defaultValue: {
    id: 4,
  },
};
const lid_count = {
  title: 'survey.lid_count',
  type: "select",
  column: "lid_count",
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: 1,
      id: 1,
    },
    {
      name: 2,
      id: 2,
    },
    {
      name: 3,
      id: 3,
    },
    {
      name: 4,
      id: 4,
    },
    {
      name: 5,
      id: 5,
    },
  ],
  defaultValue: {
    id: '',
  },
};
const material = {
  title: 'survey.material',
  type: "select",
  column: "material",
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: "FCD",
      id: 1,
    },
    {
      name: "FC",
      id: 2,
    },
    {
      name: "CR",
      id: 3,
    },
    {
      name: 'survey.grating',
      id: 6,
    },
    {
      name: 'survey.other',
      id: 5,
    },
    {
      name: 'survey.unknown',
      id: 4,
    },
  ],
  defaultValue: {
    id: 1,
  },
};
const pillar_structure = {
  title: 'survey.pillar_structure',
  type: "select",
  column: "pillar_structure",
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: 'survey.plain_bearings',
      id: 1,
    },
    {
      name: 'survey.mild_slope_bearings',
      id: 2,
    },
    {
      name: 'survey.steep_slope_bearings',
      id: 3,
    },
    {
      name: 'survey.other',
      id: 4,
    },
    {
      name: 'survey.unknown',
      id: 5,
    },
  ],
  defaultValue: {
    id: 1,
  },
};
const load_bearing_type = {
  title: 'survey.load_bearing_type',
  type: "select",
  column: "load_bearing_type",
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: "T-8",
      id: 2,
    },
    {
      name: "T-14",
      id: 3,
    },
    {
      name: "T-20",
      id: 4,
    },
    {
      name: "T-25",
      id: 5,
    },
    {
      name: 'survey.other',
      id: 6,
    },
    {
      name: 'survey.unknown',
      id: 1,
    },
  ],
  defaultValue: {
    id: 1,
  },
};
const distance_upper_gl = {
  title: 'survey.distance_upper_gl',
  type: "input-unit",
  unit: "cm",
  column: "distance_upper_gl",
  defaultValue: "",
  rules: "range0to10000"
};
const adjustment_ring_dimension = {
  title: 'survey.adjustment_ring_dimension',
  type: "array",
  column: "adjustment_ring_dimension",
  defaultValue: ['', '', '', ''],
  rules: "range0to10000"
};
const insulation = {
  title: 'survey.insulation',
  type: "select",
  column: "insulation",
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: 'survey.yes',
      id: 1,
    },
    {
      name: 'survey.no',
      id: 2,
    },
  ],
  defaultValue: {
    id: '',
  },
};

export const manholeLid = [
  lid_type,
  manufacture_year,
  lid_completion_year,
  lid_completion_date,
  manufacture,
  lid_diameter,
  lid_count,
  material,
  pillar_structure,
  load_bearing_type,
  distance_upper_gl,
  adjustment_ring_dimension,
  insulation,
];

// Inside the manhole Basic information
const manhole_type = {
  title: 'survey.manhole_type',
  type: "select",
  column: "manhole_type",
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: 'survey.manhole_type_0',
      id: 9,
    },
    {
      name: 'survey.manhole_type_1',
      id: 1,
    },
    {
      name: 'survey.manhole_type_2',
      id: 2,
    },
    {
      name: 'survey.manhole_type_3',
      id: 3,
    },
    {
      name: 'survey.manhole_type_4',
      id: 4,
    },
    {
      name: 'survey.manhole_type_5',
      id: 5,
    },
    {
      name: 'survey.special',
      id: 6,
    },
    {
      name: 'survey.small',
      id: 7,
    },
    {
      name: 'survey.resin',
      id: 10,
    },
    {
      name: 'survey.other',
      id: 8,
    },
  ],
  defaultValue: {
    id: 1,
  },
};
const manhole_depth = {
  title: 'survey.manhole_depth',
  type: "input-unit",
  unit: "m",
  column: "manhole_depth",
  defaultValue: "",
  rules: "float2"
};

export const insideManhole = [manhole_type, manhole_depth];

// Inspection area basic information

const processing_division = {
  title: 'survey.processing_division',
  type: "input",
  column: "processing_division",
  defaultValue: "",
  rules: "text50"
};

const drawing_name = {
  title: 'survey.drawing_name',
  type: "input",
  column: "drawing_name",
  defaultValue: "",
  rules: "text50"
};

const drawing_page = {
  title: 'survey.drawing_page',
  type: "input",
  column: "drawing_page",
  defaultValue: "",
  rules: "range0to10000",
};

const drawing_mesh = {
  title: 'survey.drawing_mesh',
  type: "input",
  column: "drawing_mesh",
  defaultValue: "",
  rules: "text50"
};

const pipeline_classification = {
  title: 'survey.pipeline_classification',
  type: "select",
  column: "pipeline_classification",
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: 'survey.main',
      id: 1,
    },
    {
      name: 'survey.branch',
      id: 2,
    },
    {
      name: 'survey.other',
      id: 3,
    },
    {
      name: 'survey.unknown',
      id: 4,
    },
  ],
  defaultValue: {
    id: 1,
  },
};

const downstream_number = {
  title: 'survey.downstream_number',
  type: "input",
  column: "downstream_number",
  defaultValue: "",
  rules: "text20"
};

const manhole_number = {
  title: 'survey.manhole_number',
  type: "input",
  column: "manhole_number",
  defaultValue: "",
  rules: "text20"
};

const road_type = {
  title: 'survey.road_type',
  type: "select",
  column: "road_type",
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: 'survey.national_road',
      id: 1,
    },
    {
      name: 'survey.main_road',
      id: 2,
    },
    {
      name: 'survey.prefectural_road',
      id: 3,
    },
    {
      name: 'survey.municipal_road',
      id: 4,
    },
    {
      name: 'survey.private_road',
      id: 5,
    },
    {
      name: 'survey.borrowing',
      id: 6,
    },
    {
      name: 'survey.other',
      id: 7,
    },
    {
      name: 'survey.unknown',
      id: 8,
    },
  ],
  defaultValue: {
    id: 1,
  },
};

const location = {
  title: 'survey.location',
  type: "select",
  column: "location",
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: 'survey.roadway',
      id: 1,
    },
    {
      name: 'survey.walkway',
      id: 2,
    },
    {
      name: 'survey.other',
      id: 3,
    },
    {
      name: 'survey.unknown',
      id: 4,
    },
  ],
  defaultValue: {
    id: 1,
  },
};
const location_driveway = {
  title: 'survey.location_driveway',
  type: "select",
  column: "location_driveway",
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: 'survey.furrow',
      id: 1,
    },
    {
      name: 'survey.center_of_lane',
      id: 2,
    },
    {
      name: 'survey.roadside',
      id: 3,
    },
    {
      name: 'survey.tree_planting_zone',
      id: 4,
    },
    {
      name: 'survey.median_strip',
      id: 5,
    },
    {
      name: 'survey.other',
      id: 6,
    },
  ],
  defaultValue: {
    id: 1,
  },
};

const road_width = {
  title: 'survey.road_width',
  type: "select",
  column: "road_width",
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: 'survey.more_than_5',
      id: 1,
    },
    {
      name: 'survey.less_than_5',
      id: 2,
    },
  ],
  defaultValue: {
    id: 1,
  },
};
const road_alignment = {
  title: 'survey.road_alignment',
  type: "select",
  column: "road_alignment",
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: 'survey.straight_line',
      id: 1,
    },
    {
      name: 'survey.slope',
      id: 2,
    },
    {
      name: 'survey.curve',
      id: 3,
    },
    {
      name: 'survey.intersection',
      id: 4,
    },
    {
      name: 'survey.other',
      id: 5,
    },
  ],
  defaultValue: {
    id: 1,
  },
};

const pavement_type = {
  title: 'survey.pavement_type',
  type: "select",
  column: "pavement_type",
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: "AS",
      id: 1,
    },
    {
      name: "CR",
      id: 2,
    },
    {
      name: 'survey.plate',
      id: 3,
    },
    {
      name: 'survey.gravel',
      id: 4,
    },
    {
      name: 'survey.other',
      id: 5,
    },
    {
      name: 'survey.unknown',
      id: 6,
    },
  ],
  defaultValue: {
    id: 1,
  },
};

const rubbing = {
  title: 'survey.rubbing',
  type: "input-unit",
  column: "rubbing",
  unit: "cm",
  defaultValue: "",
  rules: "range0to10000"
};

const area_characteristic = {
  title: 'survey.area_characteristic',
  type: "array-select",
  column: "area_characteristic",
  data: [
    {
      name: 'survey.bus_route',
      id: 1,
    },
    {
      name: 'survey.more_weighted_vehicles',
      id: 2,
    },
    {
      name: 'survey.near_building_pit',
      id: 3,
    },
    {
      name: 'survey.special_drainage',
      id: 4,
    },
    {
      name: 'survey.other',
      id: 5,
    },
  ],
  defaultValue: {
    id: [1, 2, 3],
  },
};

const step_drop = {
  title: 'survey.step_drop',
  type: "select",
  column: "step_drop",
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: 'survey.yes',
      id: 1,
    },
    {
      name: 'survey.no',
      id: 2,
    },
    {
      name: 'survey.unknown',
      id: 3,
    },
  ],
  defaultValue: {
    id: '',
  },
};

const secondary_pipe = {
  title: 'survey.secondary_pipe',
  type: "select",
  column: "secondary_pipe",
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: 'survey.yes',
      id: 1,
    },
    {
      name: 'survey.no',
      id: 2,
    },
    {
      name: 'survey.unknown',
      id: 3,
    },
  ],
  defaultValue: {
    id: '',
  },
};

export const inspectionArea = [
  processing_division,
  drawing_name,
  drawing_page,
  drawing_mesh,
  pipeline_classification,
  downstream_number,
  manhole_number,
  road_type,
  location,
  location_driveway,
  road_width,
  road_alignment,
  pavement_type,
  rubbing,
  area_characteristic,
  step_drop,
  secondary_pipe,
];
