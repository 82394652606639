<template>
  <v-card-text class="flex-row text-center pa-0">
    <!-- <v-avatar color="primary" size="64" @click.native="handleInputFile" v-if="!uploadInMap">
      <v-img
        :src="require('@/assets/icon-camera.svg')"
        alt="Image"
        class="shrink cursor-pointer"
        transition="scale-transition"
        width="64"
        height="64"
      />
    </v-avatar> -->
    <v-btn @click.native="handleInputFile" text block color="white" class="pa-0">
      {{textUploadImage}}
    </v-btn>
    <input ref="uploadImage" class="d-none" type="file" @change="handleUploadFile" accept="image/*"/>
  </v-card-text>
</template>
<script>

import { eventBus } from '@/common/eventBus'
import { mapGetters } from 'vuex';

export default {
  props: {
    verSelected: null,
    listVersion: Array,
    isCreateNew: Boolean,
    uploadInMap: Boolean,
    textUploadImage: String,
    selectedTypeImage: Number,
    isAdminPage: Boolean,
  },
  data: () => ({ 
  }),
  computed: {
    ...mapGetters([
      "defaultForm",
      "currentUser",
      "target",
      "dataSurvey",
    ]),
  },
  async mounted() {
  },
  methods: {
    handleInputFile() {
      this.$refs.uploadImage.click();
    },
    handleUploadFile($event) {
      const _this = this
      const files = $event.target.files || $event.dataTransfer.files;
      const form = this.getFormData(files, 'img');
      if (files) {
        if (files.length > 0) {
          this.filename = [...files].map(file => file.name).join(', ');
        } else {
          this.filename = null;
        }
      } else {
        this.filename = $event.target.value.split('\\').pop();
      }
      const lengthFileName = files[0]?.name?.length;
      const isSpecialFile = files[0]?.name?.substring( lengthFileName - 5, lengthFileName) === ".jfif";

      if((files[0].type === 'image/png' || files[0].type === 'image/jpeg' || files[0].type === 'jpg') && !isSpecialFile){
        // Call api
        let verSelectedFinal = (this.listVersion.length > 1 && this.verSelected === 'create') ? null : this.verSelected
        form.append('imgType', this.selectedTypeImage)
        form.append('surveyId', verSelectedFinal || this.listVersion[0].id);
        form.append('targetId', (this.isAdminPage ? this.dataSurvey.target_id : this.$route.params.id) || this.target.id);
        if(this.isCreateNew){
          form.append('surveyType', 1);
          form.append('surveyData', JSON.stringify({...this.defaultForm, investigator: this.currentUser.nickname, survey_type: 1})) 
        } else{
          form.append('surveyType', this.dataSurvey?.survey_type || 1);
        }
        this.uploadInMap ? this.$store.dispatch("survey/uploadImageMap", form) : 
        this.$store.dispatch("survey/uploadImage", form)
      } else {
        this.$refs.uploadImage.value = null;
        eventBus.$emit('showNotification', {
          active: true,
          type: "error",
          message: _this.$t('messageNotification.attachedImage'),
          timeout: 3000
        })
      }
      this.$refs.uploadImage.value = null
    },
    getFormData(files, formName){
      const data = new FormData();
      [...files].forEach(file => {
          data.append(formName, file, file.name);
      });
      return data;
    },
  }
}
</script>
<style lang="scss" scoped></style>