<template> 
  <v-card 
    flat 
    class="item-notification gradient-light-dark"
    @click="clickNotification(item)"
  >
    <div class="dot-read" v-if="!item.already_read"></div>
    <v-list-item-icon class="ma-0">
      <v-avatar
          size="48"
      >
        <v-img
            alt="user avatar"
            class="shrink"
            :src="item.image || require('@/assets/guadians-thumb.jpg')"
            transition="fade-transition"
            width="48"
            height="48"
        />
      </v-avatar>
    </v-list-item-icon>
    
    <v-list-item-content class="pl-2 pa-0">
      <v-list-item-title class="d-flex justify-space-between">
        <span class="item-notification_title" v-if="item.userAttach">{{item.userAttach.nickname}}</span>
        <span class="item-notification_title" v-else></span>
        <span class="item-notification_create">{{ moment(item.createdAt).format("hh:mm a") }}</span>
      </v-list-item-title>
      <p class="ma-0 item-notification_content">
          {{renderContentNotification(item)}}
      </p>
    </v-list-item-content>

  </v-card>
</template>
<script>

import moment from "moment";
export default {
  props: {
    item: Object,
  },
	data: () => ({
	}),
  methods: {
    moment(item) {
      return moment(item);
    },
    renderContentNotification(item) {
      switch(item.category){
        case 'edit_survey': 
          return this.$t('notification.notiUpdateSurvey', {idSurvey: item.id_data})
        case 'comment_survey': 
          return this.$t('notification.notificationComment', {idSurvey: item.id_data})
        default:
          break;
      }
    },
    clickNotification(item) {
      const typeSurvey = item?.survey_type === "SurveyRound" ? 2 : 1
      !item.already_read && this.$store.dispatch("global/readNotification", item.id)
      switch(item.category){
        case 'edit_survey': 
          return this.$router.push({path: `/survey/contents/${item.manholeId}`, 
          query: {idSurvey: item.id_data, typeSurvey: typeSurvey}})
        case 'comment_survey': 
          return this.$router.push({
            path: `/survey/target/${item.manholeId}/type/${typeSurvey}/survey/${item.id_data}/comments`
          })
        default:
          break;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.item-notification{
  padding: 9px;
  display: flex;
  margin: 10px 0;
  color: #000;
  cursor: pointer;
  .dot-read{
    height: 8px;
    width: 8px;
    background-color: red;
    position: absolute;
    border-radius: 10px;
    z-index: 1;
    top: 15px;
    right: 6px;
  }
  &_title{
    @media only screen and (max-width: 576px) {
      display: inline-block;
      width: 210px;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
      line-height: 2;
    }
  }
  &_create{
    font-size: 12px;
    margin-right: 13px;
  }
  &_content{
    font-size: 12px;
    margin-top: 2px;
    line-height: 1.5;
  }


}
</style>