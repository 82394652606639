<template>
    <v-row
        align="center"
        justify="center"
    >
        <v-card
            tile
        >
            <v-row justify="center">

                <v-overlay
                    :absolute="absolute"
                    :opacity="opacity"
                    :value="displayWelcome"
                    :z-index="zIndex"
                    color="dark darken-1"
                >

                    <v-carousel
                        height="calc(100vh);"
                        style="z-index: 9999;"
                        hide-delimiter-background
                        :show-arrows="false"
                        :continuous="false"
                    >
                        <v-carousel-item v-for="(slide,i) in slides" :key="i">
                            <v-container>
                                <v-row class="d-flex align-center" style="height: 100vh">
                                    <v-col>
                                        <v-card class="d-flex justify-center dark darken-1" flat>
                                            <v-card class="dark darken-1 pb-12" flat>
                                                <v-img
                                                    alt="user avatar"
                                                    contain
                                                    class="mx-auto"
                                                    :src="slide.src"
                                                    transition="fade-transition"
                                                    width="320"
                                                />
                                                <v-card-text class="text-center primary--text" v-text="slide.text"></v-card-text>
                                                <v-card-text v-if="slide.action" class="d-flex justify-center">
                                                    <v-btn color="primary dark--text" @click="currentSlide">
                                                      {{ $t('welcomeDialog.start') }}
                                                    </v-btn>
                                                </v-card-text>
                                            </v-card>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-carousel-item>
                    </v-carousel>

                </v-overlay>

            </v-row>
        </v-card>
    </v-row>
</template>

<script>
export default {
    props: {
        value: Boolean
    },
    data: () => ({
        absolute: false,
        opacity: 1,
        zIndex: 1022,
        slides: [
            // {
            //     src: require('../assets/step_05.svg'),
            //     text:'ようこそ鉄とコンクリートの守り人へEメールにてログインされた方はパスワードをアップデートして安全なアカウントにしてください。',
            // },
            {
                src: require('../assets/step_01.svg'),
                text:'車通りの多い道路上にあるマンホール蓋の撮影は危険ですので、絶対に行わないでください。（右から左へスワイプしてください）',
            },
            {
                src: require('../assets/step_02.svg'),
                text: '歩きながらスマホを使うのは危険です。マンホールの位置は都度止まってから確認してください。（右から左へスワイプしてください）',
            },
            {
                src: require('../assets/step_03.svg'),
                text: '写真を撮るときに、周囲の人々の迷惑にならないように注意してください。（右から左へスワイプしてください）',
            },
            {
                src: require('../assets/step_04.svg'),
                text: '待っている方がいたら、感謝の気持ちを伝えてください。',
                action:'Start'
            }
        ]
    }),
    methods: {
        currentSlide() {
            this.displayWelcome = false;
            localStorage.displayedWelcome = true;
        }
    },
    computed: {
        displayWelcome: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            }
        },
    },
    mounted() {
        this.slides = [
            {
                src: require('../assets/step_01.svg'),
                text: this.$t('welcomeDialog.firstContentDialog'),
            },
            {
                src: require('../assets/step_02.svg'),
                text: this.$t('welcomeDialog.secondContentDialog'),
            },
            {
                src: require('../assets/step_03.svg'),
                text: this.$t('welcomeDialog.thirdContentDialog'),
            },
            {
                src: require('../assets/step_04.svg'),
                text: this.$t('welcomeDialog.fourContentDialog'),
                action:'Start'
            }
        ]
    },
}
</script>
<style lang="scss" scoped>
</style>
