<template>
    <h1>{{ $t('notFoundPage') }}</h1>
</template>
<script>
export default {
    
}
</script>
<style lang="scss" scoped>

</style>