<template>
  <v-container class="form-photos">
    <v-card flat class="mt-3">
      <v-card-text class="flex-row text-center">
        <v-avatar color="primary" size="64" @click.native="handleUploadImage">
          <v-img
            :src="require('@/assets/icon-camera.svg')"
            alt="Image"
            class="shrink cursor-pointer"
            transition="scale-transition"
            width="64"
            height="64"
          />
        </v-avatar>
      </v-card-text>

      <v-card-text v-if="dataSurvey" :key="componentKey">
        <v-row>
          <v-col
            cols="12"
            sm="4"
            v-for="item in dataSurvey.images"
            :key="item.id"
          >
            <survey-image
              lightTheme
              :id="item.id"
              :url="item.url"
              :type="item.type"
              :targetId="dataSurvey.target_id"
              :surveyId="dataSurvey.id"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <dialog-upload-image
        :isOpen="isShowDialogUploadImage"
        :title="$t('instantPost.takePhotoFrontCover')"
        :clickCancel="closeDialogUploadImage"
        :listVersion="[]"
        :verSelected="$route.params.id"
        :isCreateNew="false"
        :isAdminPage="true"
      />
    </v-card>
  </v-container>
</template>
<script>
import { eventBus } from "@/common/eventBus";
import { mapGetters } from "vuex";
import SurveyImage from "../../components/survey-post/SurveyImage.vue";
import DialogUploadImage from '../../components/modal/DialogUploadImage.vue';

export default {
  components: {
    SurveyImage,
    DialogUploadImage,
  },

  data: () => ({
    componentKey: 0,
  }),

  computed: {
    ...mapGetters([
      "dataSurvey",
      "target",
      "form",
      "defaultForm",
      "currentUser",
      "isShowDialogUploadImage",
    ]),
  },

  async mounted() {
    if (this.$route?.params?.id) {
      if (Number(this.$route.query.type) === 2) {
        await this.$store.dispatch(
          "survey/getRoundSurvey",
          this.$route.params.id
        );
      } else {
        await this.$store.dispatch("survey/getPost", this.$route.params.id);
      }
    }
  },

  methods: {
    submit() {
      const objFinal = this.isCreateNew
        ? {
            ...this.defaultForm,
            ...this.form,
            investigator: this.currentUser.nickname,
          }
        : this.form;

      const surveyType = Number(this.dataSurvey?.survey_type) || Number(this.$route.query.type)
        if (surveyType === 2) {
          const req = {
            id: this.$route.params.id,
            data: objFinal,
          };
          this.$store.dispatch("survey/updateRoundSurvey", req);
        } else {
          const req = {
            onCreate: false,
            surveyId: this.$route.params.id,
            surveyData: objFinal,
          };
          this.$store.dispatch("survey/updateSurvey", req);
        }
      
    },

    getFormData(files, formName) {
      const data = new FormData();
      [...files].forEach((file) => {
        data.append(formName, file, file.name);
      });
      return data;
    },

    handleInputFile() {
      this.$refs.uploadImage.click();
    },

    handleUploadFile($event) {
      const _this = this;
      const files = $event.target.files || $event.dataTransfer.files;
      const form = this.getFormData(files, "img");
      if (files) {
        if (files.length > 0) {
          this.filename = [...files].map((file) => file.name).join(", ");
        } else {
          this.filename = null;
        }
      } else {
        this.filename = $event.target.value.split("\\").pop();
      }
      // this.$emit('input', this.filename);
      // this.$emit('formData', form);jfif
      const lengthFileName = files[0]?.name?.length;
      const isSpecialFile =
        files[0]?.name?.substring(lengthFileName - 5, lengthFileName) ===
        ".jfif";

      if (
        (files[0].type === "image/png" ||
          files[0].type === "image/jpeg" ||
          files[0].type === "jpg") &&
        !isSpecialFile
      ) {
        // Call api
        form.append("surveyId", this.$route.params.id);
        form.append("targetId", this.dataSurvey?.target_id || this.target.id);
        form.append("surveyType", this.dataSurvey?.survey_type || 2);
        this.$store.dispatch("survey/uploadImage", form);
      } else {
        this.$refs.uploadImage.value = null;
        eventBus.$emit("showNotification", {
          active: true,
          type: "error",
          message: _this.$t("messageNotification.attachedImage"),
          timeout: 3000,
        });
      }
      this.$refs.uploadImage.value = null;
    },
    closeDialogUploadImage() {
      this.isShowDialogUploadImage && this.$store.dispatch("instantPost/toggleShowUploadImage")
      this.selectedTypeImage = 1
    },
    handleUploadImage() {
      this.$store.dispatch("instantPost/toggleShowUploadImage")
    },
  },
};
</script>
<style lang="scss" scoped>
.form-photos {
  .item-box_select {
    font-size: 14px;
  }
  ::v-deep .v-text-field.v-text-field--solo .v-input__control {
    min-height: 40px;
    font-size: 13px;
  }

  ::v-deep .v-select__selections input {
    display: none;
  }

  ::v-deep .v-select__selection {
    max-width: 100%;
  }
  ::v-deep .v-text-field--outlined fieldset {
    border-color: #27f8f9;
    box-shadow: 0px 10px 20px black;
  }
}
</style>