<template>
  <v-container class="survey-image">
    <item-survey
      :data="listVersion"
      :value="listVersion[0]"
      :type="'select'"
      nameData="survey_version"
      valueData="id"
      :callbackSelect="selectVersion"
    ></item-survey>
    <v-card flat class="dark mt-3">
      <v-card-text class="flex-row text-center">
        <v-avatar color="primary" size="64" @click.native="handleUploadImage" v-if="isSelf">
          <v-img
            :src="require('@/assets/icon-camera.svg')"
            alt="Image"
            class="shrink cursor-pointer"
            transition="scale-transition"
            width="64"
            height="64"
          />
        </v-avatar>
      </v-card-text>
      
      <v-card-text v-if="dataSurvey" :key="componentKey">
        <v-row>
          <v-col
            cols="12"
            sm="4"
            v-for="item in dataSurvey.images"
            :key="item.id"
          >
            <survey-image
              :id="item.id"
              :url="item.url"
              :type="item.type"
              :surveyId="dataSurvey.id"
              :disabled="!isSelf"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <dialog-upload-image
      :isOpen="isShowDialogUploadImage"
      :title="$t('instantPost.takePhotoFrontCover')"
      :clickCancel="closeDialogUploadImage"
      :listVersion="listVersion"
      :verSelected="verSelected"
      :isCreateNew="isCreateNew"

    />
    
  </v-container>
</template>
<script>
import SurveyImage from '../../components/survey-post/SurveyImage.vue';
import ItemSurvey from "../../components/ItemSurvey.vue";
import { mapGetters } from 'vuex';
// import UploadImageSurvey from '../../components/user/UploadImageSurvey.vue';
import { defaultTypeImage } from '../survey/defaultData/dataImageType'
import DialogUploadImage from '../../components/modal/DialogUploadImage.vue';

export default {
  components: {
    SurveyImage,
    ItemSurvey,
    DialogUploadImage,
    // UploadImageSurvey,
  },

  data: () => ({
    selected: '点検日　2021年3月16日',
    items: ['点検日　2021年3月16日', '新規作成'],
    verSelected: null,
    componentKey: 0,
    selectedTypeImage: 1,
    itemsTypeImage: [],
    isSelf: false,
  }),

  computed: {
    ...mapGetters([
      "dataSurvey",
      "listVersion",
      "target",
      "form",
      "defaultForm",
      "currentUser",
      "showChooseTypeImage",
      "isShowDialogUploadImage",
    ]),
    isCreateNew() {
      return this.verSelected === "create";
    },
  },

  async mounted() {
    this.itemsTypeImage = defaultTypeImage
    await this.$store.dispatch("survey/getVersion", this.$route.params.id);
    this.listVersion.length === 1
      ? (this.verSelected = "create")
      : (this.verSelected = "");
    this.isCreateNew && (this.$store.dispatch("survey/createNew"))
    this.forceRerender()
    this.isSelf = this.currentUser.id === this.dataSurvey.user_id;
  },

  methods: {
    async selectVersion(e) {
      this.verSelected = e;
      if(this.isCreateNew) {
        this.$store.dispatch("survey/createNew");
        this.submit()
        this.verSelected = null
        this.$store.dispatch("survey/toggleChooseTypeImage", true);
        this.isSelf = true;
      } else {
        const selected = this.listVersion.filter((item) => item.id === e)[0];
        this.$store.dispatch("survey/toggleChooseTypeImage", false);
        this.selectedTypeImage = 1;
        if (selected.survey_type === 2) {
          await this.$store.dispatch("survey/getRoundSurvey", e);
        } else {
          await this.$store.dispatch("survey/getSurvey", e);
        }
        this.isSelf = this.currentUser.id === this.dataSurvey.user_id;
      }
    },

    changeTypeImage(e) {
      this.selectedTypeImage = e
    },

    submit() {
      const objFinal = this.isCreateNew
        ? { ...this.defaultForm, ...this.form, investigator: this.currentUser.nickname }
        : this.form;
      const surveyType = Number(this.dataSurvey?.survey_type)

      if (this.isCreateNew) {
        const req = {
          onCreate: true,
          targetId: this.$route.params.id,
          surveyData: {...objFinal, survey_type: 1},
        };
        this.$store.dispatch("survey/updateSurvey", req);
      } else {
        if (surveyType === 2) {
          const req = {
            id: this.verSelected,
            data: objFinal,
          };
          this.$store.dispatch("survey/updateRoundSurvey", req);
        } else {
          const req = {
            onCreate: false,
            surveyId: this.verSelected,
            surveyData: objFinal,
          };
          this.$store.dispatch("survey/updateSurvey", req);
        }
      }
    },

    getFormData(files, formName){
      const data = new FormData();
      [...files].forEach(file => {
          data.append(formName, file, file.name);
      });
      return data;
    },

    handleInputFile() {
      this.$refs.uploadImage.click();
    },

    closeDialogUploadImage() {
      this.isShowDialogUploadImage && this.$store.dispatch("instantPost/toggleShowUploadImage")
      this.selectedTypeImage = 1
    },

    handleUploadImage() {
      this.$store.dispatch("instantPost/toggleShowUploadImage")
    },

    forceRerender() {
      this.componentKey += 1;
    },

  }
};
</script>
<style lang="scss" scoped>
.survey-image{
  .item-box_select{
    font-size: 14px;
  }
  ::v-deep .v-text-field.v-text-field--solo .v-input__control {
    min-height: 40px;
    font-size: 16px;
  }

  ::v-deep .v-select__selections input {
    display: none;
  }

  ::v-deep .v-select__selection {
    max-width: 100%;
  }
  ::v-deep .v-text-field--outlined fieldset{
      border-color: #27f8f9;
      box-shadow: 0px 10px 20px #081539;
  }

}

</style>