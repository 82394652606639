<template>
  <div class="manhole-info">
    <v-container class="full-mobile">
      <leaf-let class="view-map" :manholeMap="true" :key="index" ref="mapv" />
    </v-container>
    <v-container>
      <v-form ref="form" lazy-validation v-model="valid">
        <v-card color="transparent" elevation="0" class="pr-3 pl-3 pt-2 mb-12">
          <item-survey
            :title="$t('survey.ledgerManholeNo')"
            :value="(!this.isAddTarget && this.target) ? this.target.ledger_manhole_no : ''"
            type="input"
            column="ledger_manhole_no"
            valueData="ledger_manhole_no"
            ></item-survey>
        </v-card>
        <v-card color="transparent" elevation="0" class="pr-3 pl-3 pt-2 mb-12">
          <!-- Manhole lid basic information -->
          <p class="primary--text">{{$t('survey.manholeLidBasic')}}</p>
          <div v-for="(info, i) in basicInfo" :key="i">
            <item-survey
              :title="$t(info.title)"
              :data="info.data"
              :value="info.defaultValue"
              :type="info.type"
              :column="info.column"
              :nameData="item => $t(item.name)"
              valueData="id"
              :callbackSelect="selectData"
              :unit="info.unit"
              :rules="info.rules"
            ></item-survey>
          </div>
        </v-card>
        <v-card color="transparent" elevation="0" class="pr-3 pl-3 pt-2 mb-12">
          <!-- Inside the manhole Basic information -->
          <p class="primary--text">{{$t('survey.insideManholeBasic')}}</p>
          <div v-for="(info, i) in insideInfo" :key="i">
            <item-survey
              :title="$t(info.title)"
              :data="info.data"
              :value="info.defaultValue"
              :type="info.type"
              :column="info.column"
              :nameData="item => $t(item.name)"
              valueData="id"
              :callbackSelect="selectData"
              :unit="info.unit"
              :rules="info.rules"
            ></item-survey>
          </div>
        </v-card>
        <v-card color="transparent" elevation="0" class="pr-3 pl-3 pt-2 mb-12">
          <!-- Inspection area basic information -->
          <p class="primary--text">{{$t('survey.inspectionAreaBasicInformation')}}</p>
          <div v-for="(info, i) in inspectionInfo" :key="i">
            <item-survey
              :title="$t(info.title)"
              :data="info.data"
              :value="info.defaultValue"
              :type="info.type"
              :column="info.column"
              :nameData="item => $t(item.name)"
              valueData="id"
              :callbackSelect="selectData"
              :unit="info.unit"
              :rules="info.rules"
            ></item-survey>
          </div>
        </v-card>
        <div class="d-flex justify-space-between">
          <v-btn 
            height="40"
            small color="error" 
            class="sh-urbane dark--text" 
            @click="openDeleteDialog"
            v-if="!this.isAddTarget"
          >
            <span>
                {{$t('survey.delete')}}
            </span>
          </v-btn>
          <v-btn
            :disabled="disableSubmitButton()"
            @click="submit"
            height="40"
            depressed
            color="primary dark--text btn-keep"
            class="mr-3 mb-10"
            >{{$t('profile.keep')}}</v-btn
          >
          
        </div>
      </v-form>
    </v-container>
    <selection-dialog
      :isOpen="toggleDeleteDialog"
      :title="$t('homePage.detailPanel.confirmationDelete')"
      :clickAccept="deleteTarget"
      :clickCancel="cancelDeleteDialog"
      :isCameraTargetDialog="true"
      :textCancel="$t('homePage.detailPanel.cancel')"
      :textBtn="$t('homePage.detailPanel.delete')"
    />
  </div>
</template>
<script>
import ItemSurvey from "../../components/ItemSurvey.vue";
import LeafLet from "../../components/LeafLet.vue";
import SelectionDialog from "../../components/modal/SelectionDialog.vue";
import {
  manholeLid,
  insideManhole,
  inspectionArea,
} from "./defaultData/dataManholeInfo";
import game from "@/game";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  components: { ItemSurvey, LeafLet, SelectionDialog },
  props: {
    skipSurveyCountCheck: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      valid: true,
      index: 1,
      basicInfo: [],
      insideInfo: [],
      inspectionInfo: [],
      submitData: [],
    };
  },

  async mounted() {

    if(this.isAddTarget){
      this.$store.dispatch("instantPost/resetFormInstant")
    } else{
      await this.$store.dispatch("instantPost/getTargetById", this.$route.params.id);
    }
    if(!this.skipSurveyCountCheck && this.target?.survey_count < 1) {
      this.$store.dispatch("instantPost/toggleShowUploadImage")
    }
    this.basicInfo = manholeLid;
    this.insideInfo = insideManhole;
    this.inspectionInfo = inspectionArea;
    this.fillData(this.basicInfo);
    this.fillData(this.insideInfo);
    this.fillData(this.inspectionInfo);
    game.playView = this;
    game.setPlayView(this);
  },
  methods: {
    fillData(arrData) {
      arrData.map((itemDefault) => {
          const fakeData = this.isAddTarget ? this.defaultFormInstantPost : this.target;
          switch (itemDefault.column) {
            case "adjustment_ring_dimension":
              switch(fakeData[itemDefault.column]?.length) {
                case 0: itemDefault.defaultValue = ["", "", "", ""] 
                  break;
                default:
                  itemDefault.defaultValue = fakeData[itemDefault.column]?.split(",")
                  break;
              }
              break;
            case "area_characteristic":
              itemDefault.defaultValue.id = JSON.parse("[" + fakeData[itemDefault.column] + "]") 
              break;
            default:
              if (itemDefault.type === "input" || itemDefault.type === "input-unit") {
                itemDefault.defaultValue = fakeData[itemDefault.column];
              } else if (itemDefault.type === "date") {
                itemDefault.defaultValue.id = fakeData[itemDefault.column]
                  ? moment(fakeData[itemDefault.column]).tz("Asia/Tokyo").format("YYYY-MM-DD HH:mm")
                  : "";
              } else {
                itemDefault.defaultValue.id = fakeData[itemDefault.column]
              }
              return itemDefault;
          }
      });
    },
    selectData(value, column, arr) {
      if(column === "area_characteristic"){
        this.submitData.push([column, arr.id.toString()]);
      } else{
        this.submitData.push([column, arr?.length ? arr.toString() : value]);
      }
    },
    submit() {
      if(this.isAddTarget){
        this.$store.dispatch("instantPost/postAddTarget", {...this.defaultFormInstantPost, ...this.formInstantPost, lat: this.$route.query.lat, lng: this.$route.query.lng});
      } else{
        this.$store.dispatch("instantPost/editTarget", {target_id: this.$route.params.id, data: this.formInstantPost});
      }
    },
    onMapTypeClicked() {
      console.log("click manhole", game.getMapType());

      if (game.getMapType() == "roadmap") game.setMapType("satellite");
      else game.setMapType("roadmap");
      this.index++;
    },

    openDeleteDialog() {
      this.$store.dispatch('instantPost/toggleDeleteDialog')
    },
    cancelDeleteDialog() {
      this.$store.dispatch('instantPost/toggleDeleteDialog')
    },
    deleteTarget() {
      this.$store.dispatch("instantPost/deleteTarget", this.$route.params.id);
    },
    disableSubmitButton() {
      return this.isAddTarget ? !this.valid : (!this.enableSubmitButton || !this.valid)
    }
  },
  computed: {
    ...mapGetters(["target", "formInstantPost", "defaultFormInstantPost", "enableSubmitButton"]),
    isAddTarget() {
      return this.$route.params.id === "add-target";
    },
    toggleDeleteDialog : {
      get () {
        return this.$store.state.instantPost.toggleDeleteDialog
      },
      set (value) {
        this.$store.commit('instantPost/TOGGLE_DELETE_DIALOG', value)
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.manhole-info {
  p {
    font-size: 16px;
  }
  .view-map {
    ::v-deep #map-app {
      height: 25vh;
      position: relative;
      .leaflet-control-zoom,
      .leaflet-control-attribution {
        display: none;
      }
    }
  }

  .btn-keep {
    box-shadow: 0px 10px 20px #081539;
  }
  ::v-deep .leaflet-bottom{
    margin-bottom: 0;
  }
}
</style>