<template>
  <v-card dark class="list-post">
    <v-card>
      <v-card-title class="primary--text">
        <div class="list-post_groupButton search-filter mt-3 mb-3">
          <item-survey
            :data="listFilter"
            :value="searchBy"
            :type="'select'"
            :callbackSelect="selectFilter"
            nameData="name"
            valueData="value"
            lightTheme
          ></item-survey>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="検索"
            single-line
            hide-details
            class="mt-0"
          ></v-text-field>
        </div>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <!-- <div class="list-post_groupButton">
          <v-btn
            class="ml-2 mt-3 mb-3"
            color="primary"
            outlined
            @click="exportList"
            >Export List</v-btn
          >
        </div> -->
      </v-card-title>

      <v-data-table
        :headers="headers"
        :search="search"
        :page="adminTargets.currentPage + 1"
        :pageCount="adminTargets.totalPages"
        :server-items-length="adminTargets.totalItems"
        :items="adminTargets.resources"
        :items-per-page="10"
        :footer-props="{
          'disable-items-per-page': true,
          'disable-pagination': adminTargets.loading,
          'items-per-page-text': '１ページあたりの行数',
          'page-text': '{2}中の{0}-{1}'
        }"
        :options.sync="options"
        :loading="adminTargets.loading"
        loading-text='読込中です...'
        no-data-text='データがありません'
      >
        <template v-slot:[`item.createdAt`]="{ item }">
          <span>{{ moment(item.createdAt).tz('Asia/Tokyo').format("YYYY-MM-DD") }}</span>
        </template>

        <template v-slot:[`item.user`]="{ item }">
          <span>{{ renderNickname(item) }}</span>
        </template>

        <template v-slot:[`item.frontCover`]="{ item }">
          <v-img
            alt="user avatar"
            class="shrink mt-2 mb-2"
            cover
            :src="item.frontCover"
            transition="scale-transition"
            width="120"
            height="80"
          />
        </template>
        <template v-slot:[`item.pref`]="{ item }">
          <span>{{ renderPrefCity(item, 'pref') }}</span>
        </template>
        <template v-slot:[`item.city`]="{ item }">
          <span>{{ renderPrefCity(item, 'city') }}</span>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon class="mr-2" @click="editItem(item.id)" title="編集">
            mdi-pencil</v-icon
          >
        </template>
        <template v-slot:[`item.viewsurveys`]="{ item }">
          <v-icon class="mr-2" @click="linkItem(item.id)" title="点検リンク">
            mdi-eye</v-icon
          >
        </template>
      </v-data-table>
    </v-card>
  </v-card>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import _ from "lodash";
import ItemSurvey from "../../components/ItemSurvey.vue";

export default {
  components: {ItemSurvey},
  data() {
    return {
      search: "",
      options: {},
      itemSwitch: {},
      headers: [
        {
          text: "蓋表写真",
          value: "frontCover",
          align: "start",
          sortable: false,
          width: '200px'
        },
        {
          text: "マンホールID",
          value: "id",
          align: "start",
        },
        {
          text: this.$t('survey.ledgerManholeNo'),
          value: "ledger_manhole_no",
          align: "start",
        },
        {
          text: "作成者名",
          value: "user",
          align: "start",
          width: '200px'
        },
        {
          text: "都道府県",
          value: "pref",
          align: "start",
        },
        {
          text: "市区町村",
          value: "city",
        },
        {
          text: "作成日",
          value: "createdAt",
          align: "start",
        },
        {
          text: "点検内容を確認",
          value: "viewsurveys",
          sortable: false,
          align: "center",
        },
        {
          text: "操作",
          value: "actions",
          sortable: false,
          cellClass: "action-column",
          align: "center",
        },
      ],
      dialogDelete: false,
      listFilter: [
        {
          name: "マンホールID",
          value: "id",
        },
        {
          name: this.$t('survey.ledgerManholeNo'),
          value: "ledger_manhole_no",
        },
        {
          name: "作成者名",
          value: "nickname",
        },
        {
          name: "都道府県",
          value: "pref",
        },
        {
          name: "市区町村",
          value: "city",
        },
        {
          name: "作成日",
          value: "createdAt",
        },
      ],
      searchBy: "id",
    };
  },
  watch: {
    $route() {
      this.options = {
        ...this.options,
        page: 1,
        sortBy: [],
        sortType: [],
        sortDesc: []
      }
      this.search = ""
    },
    options: {
      handler() {
        if(this.isAdminManholes) this.fetchData();
      },
    },
    search: _.debounce(function () {
      if(this.isAdminManholes) this.fetchData();
    }, 500),
    deep: true,
    changeStatusPost() {
      if (this.changeStatusPost) this.fetchData();
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    editItem(id) {
      this.$router.push({ path: `/admin/manhole/${id}/info` });
    },

    linkItem(id) {
      this.$store.dispatch("adminPosts/linkManhole", id);
    },

    handleChange(item) {
      this.dialogDelete = true;
      this.itemSwitch = item;
    },

    deleteItemConfirm() {
      this.$store.dispatch("adminPosts/deletePost", this.itemSwitch.survey_id);
      this.closeDelete();
    },

    closeDelete() {
      this.dialogDelete = false;
    },
    moment(item) {
      return moment(item);
    },
    async fetchData() {
      const { page, sortBy, sortDesc } = this.options;
      let sortByColumn = sortBy[0] || "id";
      if (sortBy[0]) {
        switch (sortBy[0]) {
          case "user":
            sortByColumn = "nickname";
            break;
          default:
            sortByColumn = sortBy[0];
            break;
        }
      }
      const sortType = !sortDesc[0] ? "DESC" : "ASC";

      let configs = {
        page: page - 1,
        listManhole: true,
        keyword: this.search,
        sortBy: sortByColumn,
        sortType: sortType,
        searchBy: this.searchBy
      };
      await this.$store.dispatch("adminPosts/fetchTargets", configs);
    },
    exportCSV(id) {
      this.$store.dispatch("adminPosts/exportSurvey", id);
    },
    exportList() {
    },
    selectFilter(e) {
      this.searchBy = e;
      this.fetchData();
    },
    renderPrefCity(item, type) {
      return type === "pref" ? item?.pref?.name : item?.city?.cityname
    },
    renderNickname(item) {
      return item?.user?.nickname
    }
  },

  computed: {
    ...mapGetters(["adminTargets"]),
    isAdminManholes() {
      return this.$route.path === "/admin/manholes";
    },
  },
};
</script>

<style lang="scss" scoped>
.list-post {
  ::v-deep .v-data-footer__select {
    .v-text-field {
      input {
        padding: 10px;
      }
    }
    .v-input__append-inner {
      display: none !important;
    }
    .v-input__icon--append {
      display: none !important;
    }
    .v-input__slot::before {
      display: none !important;
    }
  }
  &_groupButton {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    @media (max-width: 414px) {
      width: 100%;
    }
    @media (max-width: 810px) {
      &.search-filter {
        width: 100%;
      }
    }
    ::v-deep.v-text-field {
      input {
        padding: 10px 10px 10px 0;
      }
    }
  }
  ::v-deep .item-box {
    margin-bottom: 0rem;
    --box-shadow: 0px 0px 0px transparent !important;
    margin-right: 1rem;
  }
}
</style>