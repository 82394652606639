<template>
  <div class="d-flex mb-4" v-if="currentUser.role !== 'government'">
    <v-col
      cols="7"
      sm="6"
      class="pt-0 pb-0"
      ref="pref"
    >
      <v-select
        :value="pref"
        :items="prefecturesData"
        menu-props="auto"
        :item-text="(item) => item.name"
        item-value="pref"
        single-line
        label="都道府県"
        ref="pref"
        @change="choosePrefecture"
        color="black darken-2"
        :rules="requiredRules"
      >
        <template v-slot:append-item>
          <div v-intersect="endIntersectPrefecture" />
        </template>
      </v-select>
    </v-col>
    <v-col
      cols="5"
      sm="6"
      class="pt-0 pb-0"
    >
      <v-select
        :value="city"
        :items="citiesData"
        menu-props="auto"
        :item-text="(item) => item.name"
        item-value="id"
        color="black darken-2"
        label="市区町村"
        ref="city"
        single-line
        @change="chooseCity"
        :rules="requiredRules"
      >
        <template v-slot:append-item>
          <div v-intersect="endIntersectCity" />
        </template>
      </v-select>
    </v-col>
  </div>
  <div v-else class="d-flex mb-4">
    <v-col
      cols="7"
      sm="6"
      class="pt-0 pb-0"
    >
      <v-select
        :value="currentUser.pref.id"
        :items="prefGovernment"
        menu-props="auto"
        :item-text="(item) => item.name"
        item-value="id"
        color="black darken-2"
        label="都道府県"
        single-line
        disabled
      >
      </v-select>
    </v-col>
    <v-col
      cols="5"
      sm="6"
      class="pt-0 pb-0"
    >
      <v-select
        :value="currentUser.city.id"
        :items="cityGovernment"
        menu-props="auto"
        :item-text="(item) => item.cityname"
        item-value="id"
        color="black darken-2"
        label="市区町村"
        single-line
        disabled
      >
      </v-select>
    </v-col>
  </div>
</template>

<script>

export default {
  props: {
    choosePrefecture: Function,
    chooseCity: Function,
    citiesData: Array,
    prefecturesData: Array,
    city: Number,
    pref: Number,
    endIntersectPrefecture: Function,
    endIntersectCity: Function,
    currentUser: Object,
  },
  components: { 
  },
  data() {
    return {
      showConfirmationDialog: false,
      page: 0,
      listElm: null,
      observer: null,
      limit: 10,
      search: '',
      cityGovernment: [],
      prefGovernment: []
    };
  },
  watch: {},

  async mounted(){
    this.cityGovernment.push(this.currentUser.city)
    this.prefGovernment.push(this.currentUser.pref)
  },
  computed: {
    requiredRules() {
      return [
        (v) => (v > 0) || this.$t('validate.requiredUsername'),
      ]
    },
  }
};
</script>

<style lang="scss" scoped>
.item-box {
  font-size: 13px;
  height: 67px;
  select {
    width: 7rem;
    color: #fff;
    cursor: pointer;
    -webkit-appearance: auto;
    option{
      color: #000;
      cursor: pointer;
    }
  }
  
  select:focus{
    outline: none;
  }
}

</style>
