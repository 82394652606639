
const state = {
  device: "",
  game: {}
};

const mutations = {
  TOGGLE_DEVICE: (state, device) => {
    state.device = device;
  },

};

const actions = {
  toggleDevice({ commit }, device) {
    commit("TOGGLE_DEVICE", device);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
