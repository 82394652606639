<template>
  <v-snackbar
    v-model="active"
    :timeout="timeout"
    :top="true"
    :right="true"
    :color="color"
    class="box-snackbar"
    @click.native="clickDetailSnackbar"
  >
    <span v-html="message"></span>
    <template class="mr-0" v-slot:action="{ attrs }">
      <v-icon
        
        color="#fff"
        small
         v-bind="attrs"
        @click="active = false"
      >
        mdi-window-close
      </v-icon>
    </template>
  </v-snackbar>
</template>

<script>
import { eventBus } from '../../common/eventBus'
export default {
  data () {
    return {
        active: false,
        color: '#415fff',
        message: '',
        timeout: 3000,
        idDetail: 0,
    }
  },

  created () {
    eventBus.$on('showNotification', (config) => {
        this.active = config.active;
        this.message = config.message;
        this.idDetail = config.idDetail;
        if(Object.prototype.hasOwnProperty.call(config, 'timeout')) {
          this.timeout = config.timeout;
        }
        switch (config.type) {
          case "error":
             this.color = 'red'
            break;

          case "success":
            this.color = '#415fff'
            break;
        
          default:
            break;
        }
    })
  }, 
  methods: {  
    clickDetailSnackbar() {
      this.idDetail ? this.$router.push(`/survey/manhole-info/${this.idDetail}`) : null
    }
  }
}
</script>

<style lang="scss" scoped>
.box-snackbar{
  z-index: 2000 !important;
}
</style>