<template>
  <div class="text-center">
    <v-dialog
      v-model="isOpen"
      width="500"
      persistent
    >

      <v-card
        light
        class="card-custom"
      >
        <v-card-title class="justify-content-center mb-3">
          {{ title }}
        </v-card-title>

        <v-card-text class="pb-3 pl-0 pr-0" v-for="content in contents" :key="content">
          {{ content }}
        </v-card-text>

        <v-card-actions class="justify-content-center mt-3">
          <v-btn
          class="pl-5 pr-5"
            color="primary dark--text"
            :to="linkBtn"

          >
            {{ textBtn }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {

    props: {
      title: { type: String, required: false },
      contents: { type: Array, required: false },
      textBtn: { type: String, required: false },
      linkBtn: { type: String, required: false },
      isOpen: { type: Boolean, default: false, required: true }
    },

    data () {
      return {
        dialog: false,
      }
    },
    
  }
</script>

<style lang="scss" scoped>
.card-custom{
  padding: 19px;
  text-align: center;
  .justify-content-center{
    justify-content: center;
  }
}
</style>