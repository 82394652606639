<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="4">
        <v-card flat color="dark">
          <v-card-text class="pb-2 text-center">
            <router-link to="/">
              <v-avatar color="dark" size="250" tile>
                <v-img
                  alt="user avatar"
                  class="shrink ma-auto"
                  contain
                  :src="require('../assets/logo_pro.png')"
                  transition="scale-transition"
                  width="250"
                  height="100%"
                />
              </v-avatar>
            </router-link>
          </v-card-text>

          <v-card-title
            class="
              pb-4
              primary--text
              subtitle-2
              text-uppercase
              font-weight-black
              justify-center
            "
            v-if="platform == 'email'"
          >
            {{ $t("signUp.createAccountEmail") }}
          </v-card-title>
          <v-card-title
            class="
              pb-4
              primary--text
              subtitle-2
              text-uppercase
              font-weight-black
              justify-center
            "
            v-if="platform == 'twitter'"
          >
            {{ $t("signUp.twitter") }}
          </v-card-title>
          <v-card-title
            class="
              pb-4
              primary--text
              subtitle-2
              text-uppercase
              font-weight-black
              justify-center
            "
            v-if="platform == 'line'"
          >
            {{ $t("signUp.line") }}
          </v-card-title>
          <v-card-title
            class="
              pb-4
              primary--text
              subtitle-2
              text-uppercase
              font-weight-black
              justify-center
            "
            v-if="platform == 'wee'"
          >
            {{ $t("signUp.WEE") }}
          </v-card-title>

          <v-card-text class="pb-0">
            <v-form ref="form" lazy-validation>
              <v-text-field
                v-if="platform == 'email'"
                :label="emailText"
                outlined
                dense
                class="mb-2 sh-textfield"
                v-model="email"
                hide-details="auto"
                autocomplete="off"
                :rules="emailRules"
              >
              </v-text-field>
              <small
                class="text-center font-weight-bold d-block mb-4"
                v-html="$t('signUp.byCreating')"
              >
              </small>
              <v-btn
                block
                depressed
                color="primary dark--text"
                class="mb-1"
                v-on:click="onSignUpButton()"
                :disabled="!isNotValid"
                height="40"
              >
                {{ $t("signUp.create") }}
              </v-btn>
              <v-btn
                height="40"
                block
                depressed
                color="primary dark--text"
                class="mb-1"
                to="/accounts/signin"
              >
                {{ $t("signUp.signIn") }}
              </v-btn>
              <v-btn
                height="40"
                block
                depressed
                text
                color="black--text"
                class="mb-1"
                to="/accounts/resetpassword"
              >
                {{ $t("signUp.passwordReset") }}
              </v-btn>
            </v-form>
          </v-card-text>

          <!-- <v-card-text class="py-0">
            <v-divider></v-divider>
          </v-card-text> -->

          <!-- <v-card-text
            class="overline text-uppercase font-weight-black text-center pb-0"
          >
            {{ $t("signUp.sns") }}
          </v-card-text> -->

          <!-- <v-card-text class="text-center mb-5">
            <v-btn
              fab
              color="primary"
              depressed
              small
              class="mx-2"
              v-on:click="onTwitter()"
            >
              <v-img
                alt="user avatar"
                class="shrink"
                contain
                :src="require('../assets/logo-twitter.svg')"
                transition="scale-transition"
                width="40"
              />
            </v-btn>
            <v-btn
              fab
              color="primary"
              depressed
              small
              class="mx-2"
              v-on:click="onWEE()"
            >
              <v-img
                alt="user avatar"
                class="shrink"
                contain
                :src="require('../assets/logo-wef.svg')"
                transition="scale-transition"
                width="40"
              />
            </v-btn>
          </v-card-text> -->
        </v-card>
      </v-col>
    </v-row>
    <verify-dialog
      :title="$t('signUp.verifyEmail')"
      :contents="[$t('signUp.contentRegistered'), $t('signUp.contentVerify')]"
      :textBtn="$t('signUp.goToSignIn')"
      :linkBtn="'/accounts/signin'"
      :isOpen="openNotification"
    />
  </v-container>
</template>
<script>
// import game from "@/game"
import { mapGetters } from "vuex";
import VerifyDialog from "../components/modal/VerifyDialog.vue";
import { eventBus } from "../common/eventBus";

export default {
  components: {
    VerifyDialog,
  },

  data: function () {
    return {
      platform: "email", // email, twitter, line, wee
      email: "",
      confirm: "",
      openNotification: false,
    };
  },

  watch: {
    language: {
      deep: true,
      handler() {
        this.$refs.form.reset();
      },
    },
    responseSignup() {
      switch (this.responseSignup.error) {
        case "waiting_confirm":
          this.openNotification = true;
          eventBus.$emit("showNotification", {
            active: false,
          });
          break;
        case "already_exist":
          eventBus.$emit("showNotification", {
            active: true,
            type: "error",
            message: this.$t("signUp.emailRegistered"),
          });
          break;
        case "invalid arg":
          eventBus.$emit("showNotification", {
            active: true,
            type: "error",
            message: "Please enter a valid email.",
          });
          break;
        default:
          this.$router.push({ path: "/accounts/signup/waitingconfirmation" });
          break;
      }
    },
  },

  created() {
    this.$gtm.trackEvent({
      event: null, // Event type [default = 'interaction'] (Optional)
      category: "Calculator",
      action: "click",
      label: "Signup page SIP calculator",
      value: 5000,
      noninteraction: false, // Optional
    });
  },

  methods: {
    onSignUpButton() {
      console.log("signupbutton", this.email, this.confirm);
      if (this.platform == "email") {
        this.$store.dispatch("authen/signUp", this.email);
      } else {
        if (this.platform == "twitter") {
          this.$router.push({ path: "/twsg" });
        } else if (this.platform == "line") {
          this.$router.push({ path: "/linesg" });
        } else if (this.platform == "wee") {
          this.$router.push({ path: "/weesg" });
        }
      }
    },
    onTwitter() {
      console.log("ontw");
      this.platform = "twitter";
    },
    onLine() {
      this.platform = "line";
    },
    onWEE() {
      this.platform = "wee";
    },
  },
  computed: {
    ...mapGetters(["language", "responseSignup"]),
    emailRules() {
      return [
        (v) => !!v || this.$t("signIn.emailAddressRequired"),
        (v) => /.+@.+\..+/.test(v) || this.$t("signIn.validEmailAddress"),
      ];
    },
    isNotValid() {
      if (this.platform != "email") return true;
      const checkEmail = /.+@.+\..+/.test(this.email);
      return checkEmail;
    },
    emailText() {
      return this.$t("signIn.email");
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  padding-bottom: 0;
}
.v-card__text {
  padding-bottom: 0;
}
.v-text-field--outlined {
  ::v-deep fieldset {
    border-color: #81ffff;
  }
  &.v-input--has-state ::v-deep fieldset {
    border-color: currentColor;
  }
}
</style>