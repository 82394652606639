<template>
  <v-menu
    offset-y
    origin="center center"
    :nudge-bottom="10"
    transition="scale-transition"
  >
    <template v-slot:activator="{on}">
      <v-btn
        icon
        large
        text
        v-on="on"
      >
        <v-avatar size="36px">
          <img
            :src="currentUser.avatar_url || avatarDefault"
            alt="name"
          >
        </v-avatar>
      </v-btn>
    </template>
    <v-list class="pa-0" light>
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <img
              :src="currentUser.avatar_url || avatarDefault"
              alt="name"
            >
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ currentUser.nickname }}</v-list-item-title>
            <v-list-item-subtitle>{{ currentUser.email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider />
      <v-list-item
        v-for="(item,index) in menuitems"
        :key="index"
        :to="!item.href ? {name: item.name} : null"
        :href="item.href"
        ripple="ripple"
        :disabled="item.disabled"
        :target="item.target"
        rel="noopener"
        @click="item.click"
      >
        <v-list-item-action v-if="item.icon">
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AppProfile',
  data() {
    return {
      avatarDefault: require("@/assets/guadians-thumb.jpg"),
      menuitems: [
        {
          icon: 'mdi-home',
          href: '#',
          title: 'bottomNavigation.home',
          click: () => {
            this.$router.push({path: "/"});
          },
        },
        {
          icon: 'mdi-exit-to-app',
          href: '#',
          title: 'setting.logOut',
          click: () => {
            this.$store.dispatch("authen/signOut");
          },
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  methods: {
    logout() {
      console.log("Logout")
    },
  },
};
</script>
