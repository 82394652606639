<template>
  <v-card class="mx-auto admin-post" max-width="90%" outlined dark>
    <v-list-item>
      <v-list-item-content>
        <div class="text-overline primary--text admin-post_title mb-2 mt-2">
          点検内容詳細 {{ `#${$route.params.id}` }}
          <div>
            <v-btn
              class="ml-3 mt-1 mb-1"
              dark
              color="primary dark--text"
              @click="exportCSV"
              >エクスポート</v-btn
            >
            <v-btn
              class="ml-3 mt-1 mb-1"
              light
              color="primary"
              @click="changeStatus"
              outlined
              >{{ dataSurvey.active ? "非表示" : "地図で見る" }}</v-btn
            >
          </div>
        </div>
      </v-list-item-content>
    </v-list-item>
    <post-detail-ctrl></post-detail-ctrl>
    <v-divider></v-divider>
    <FormPostDetail v-if="$route.name === '詳細情報を投稿する'" />
    <FormPostComments v-if="$route.name === 'コメントする'" />
    <FormPostPhoto v-if="$route.name === '写真を投稿する'" />
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import FormPostComments from "./FormPostComments.vue";
import FormPostDetail from "./FormPostDetail.vue";
import FormPostPhoto from "./FormPostPhoto.vue";
import PostDetailCtrl from "./PostDetailCtrl.vue";

export default {
  components: {
    PostDetailCtrl,
    FormPostDetail,
    FormPostPhoto,
    FormPostComments,
  },
  data() {
    return {};
  },
  watch: {
    async changeStatusPost() {
      if (this.changeStatusPost && this.$route.params.id) {
        this.isListRound
          ? await this.$store.dispatch(
              "survey/getRoundSurvey",
              this.$route.params.id
            )
          : await this.$store.dispatch("survey/getPost", this.$route.params.id);
      }
    },
    $route() {
      const id = this.$route.params.id;
      id &&
        this.$route.name === "詳細情報を投稿する" &&
        this.fetchingComment(id);
    },
  },
  mounted() {
    const id = this.$route.params.id;
    id && this.fetchingComment(id);
  },
  methods: {
    fetchingComment(surveyId) {
      const body = {
        id: surveyId,
        page: 0,
      };
      if (this.isListRound) {
        this.$store.dispatch("survey/getRoundComments", body);
      } else {
        this.$store.dispatch("survey/getSurveyComments", body);
      }
    },
    exportCSV() {
      this.isListRound
        ? this.$store.dispatch(
            "adminPosts/exportRoundSurvey",
            this.$route.params.id
          )
        : this.$store.dispatch(
            "adminPosts/exportSurvey",
            this.$route.params.id
          );
    },
    changeStatus() {
      this.isListRound
        ? this.$store.dispatch(
            "adminPosts/deleteRoundPost",
            this.$route.params.id
          )
        : this.$store.dispatch("adminPosts/deletePost", this.$route.params.id);
    },
  },
  computed: {
    ...mapGetters(["dataSurvey", "changeStatusPost"]),
    isListRound() {
      return Number(this.$route.query.type) === 2;
    },
  },
};
</script>

<style lang="scss" scoped>
.admin-post {
  &_title {
    font-size: 20px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  ::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > td,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
    font-size: 0.75rem;
  }
  &_images {
    max-width: 50vw;
    padding: 16px 0;
  }
}
</style>
>
