<template>
  <v-container class="survey-content">
    <item-survey
      :data="listVersion"
      :value="listVersion[index]"
      :type="'select'"
      nameData="survey_version"
      valueData="id"
      :callbackSelect="selectVersion"
      :handleClickComment="clickComment"
      :totalComment="totalCommentsCount"
      :showComment="!isCreateNew"
    ></item-survey>
    <v-form ref="form" lazy-validation v-model="valid" :key="componentKey">
      <v-card color="transparent" elevation="0" class="pr-3 pl-3 pt-2 mb-3">
        <p class="primary--text">{{ $t("survey.inspectInfo") }}</p>
        <div v-for="(info, i) in arrInspection" :key="i">
          <item-survey
            :title="$t(info.title)"
            :data="info.data"
            :value="info.defaultValue"
            :type="info.type"
            :column="info.column"
            :unit="info.unit"
            :rules="info.rules"
            :nameData="(item) => $t(item.name)"
            valueData="id"
            :callbackSelect="selectData"
            :disabled="!isSelf || (!isCreateNew && info.column === 'survey_type')"
          ></item-survey>
        </div>
        <v-tabs
          center-active
          centered
          class="survey-content_tabs"
          color="dark"
          background-color="bgGeneral"
          grow
          hide-slider
          flat
          height="40"
          v-if="!roundFormat"
          v-model="tab"
        >
          <v-tab class="sh-urbane">{{ $t("survey.manholeCover") }}</v-tab>
          <v-tab class="sh-urbane">{{ $t("survey.insideManhole") }}</v-tab>
          <v-tab-item>
            <v-card color="transparent" elevation="0" class="">
              <p class="primary--text">
                {{ $t("survey.manhole_lid_inspection_details") }}
              </p>
              <p class="secondary--text">
                {{ $t("survey.installation_standard_conformity") }}
              </p>
              <div
                v-for="(info, i) in arrManholeLid"
                :key="`arrManholeLid1_${i}`"
              >
                <item-survey
                  :title="$t(info.title)"
                  :data="info.data"
                  :value="info.defaultValue"
                  :type="info.type"
                  :column="info.column"
                  :unit="info.unit"
                  :rules="info.rules"
                  :nameData="(item) => $t(item.name)"
                  valueData="id"
                  :disabled="!isSelf"
                ></item-survey>
              </div>
              <p class="secondary--text">
                {{ $t("survey.functional_problem") }}
              </p>
              <div
                v-for="(info, i) in arrManholeLid2"
                :key="`arrManholeLid2_${i}`"
              >
                <item-survey
                  :title="$t(info.title)"
                  :data="info.data"
                  :value="info.defaultValue"
                  :type="info.type"
                  :column="info.column"
                  :unit="info.unit"
                  :rules="info.rules"
                  :nameData="(item) => $t(item.name)"
                  valueData="id"
                  :disabled="!isSelf"
                ></item-survey>
              </div>
              <p class="secondary--text">
                {{ $t("survey.performance_degradation") }}
              </p>
              <div
                v-for="(info, i) in arrManholeLid3"
                :key="`arrManholeLid3_${i}`"
              >
                <item-survey
                  :title="$t(info.title)"
                  :data="info.data"
                  :value="info.defaultValue"
                  :type="info.type"
                  :column="info.column"
                  :unit="info.unit"
                  :rules="info.rules"
                  :nameData="(item) => $t(item.name)"
                  valueData="id"
                  :disabled="!isSelf"
                ></item-survey>
              </div>
              <surface-wear :arrSurface="arrSurface" :disabled="!isSelf"></surface-wear>
              <div
                v-for="(info, i) in arrManholeLid4"
                :key="`arrManholeLid4_${i}`"
              >
                <item-survey
                  :title="$t(info.title)"
                  :data="info.data"
                  :value="info.defaultValue"
                  :type="info.type"
                  :column="info.column"
                  :unit="info.unit"
                  :rules="info.rules"
                  :nameData="(item) => $t(item.name) || item.name"
                  valueData="id"
                  :placeholder="$t(info.placeholder)"
                  :disabled="!isSelf"
                ></item-survey>
              </div>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card color="transparent" elevation="0" class="">
              <p class="primary--text">
                {{ $t("survey.inside_the_manhole_inspection_details") }}
              </p>
              <div v-for="(info, i) in arrInside1" :key="`arr1_${i}`">
                <item-survey
                  :title="$t(info.title)"
                  :data="info.data"
                  :value="info.defaultValue"
                  :type="info.type"
                  :column="info.column"
                  :unit="info.unit"
                  :rules="info.rules"
                  :nameData="(item) => $t(item.name)"
                  :placeholder="$t(info.placeholder)"
                  valueData="id"
                  :disabled="!isSelf"
                ></item-survey>
              </div>
              <div class="d-block mb-5">
                <v-img
                  :src="require('@/assets/GL-img.png')"
                  contain
                  width="320"
                  class="survey-content_img"
                />
              </div>
              <div v-for="(info, i) in arrInside2" :key="`arr2_${i}`">
                <item-survey
                  :title="$t(info.title)"
                  :data="info.data"
                  :value="info.defaultValue"
                  :type="info.type"
                  :column="info.column"
                  :unit="info.unit"
                  :rules="info.rules"
                  :nameData="(item) => $t(item.name)"
                  valueData="id"
                  :placeholder="$t(info.placeholder)"
                  :disabled="!isSelf"
                ></item-survey>
              </div>
              <div class="d-flex align-center mb-3" style="height: 40px">
                {{ $t("survey.judgment_result") }}
              </div>
              <div v-for="(info, i) in arrInside3" :key="`arr3_${i}`">
                <item-survey
                  :title="$t(info.title)"
                  :data="info.data"
                  :value="info.defaultValue"
                  :type="info.type"
                  :column="info.column"
                  :unit="info.unit"
                  :rules="info.rules"
                  :nameData="(item) => $t(item.name)"
                  valueData="id"
                  :placeholder="$t(info.placeholder)"
                  :disabled="!isSelf"
                ></item-survey>
              </div>
            </v-card>
          </v-tab-item>
        </v-tabs>
        <v-card v-else color="transparent" elevation="0" class="">
          <p class="primary--text mt-11 mb-9">
            {{ $t("survey.manhole_lid_inspection_details") }}
          </p>
          <div class="d-flex align-center mb-3" style="height: 40px">
            {{ $t("survey.manhole_cover_type") }}
          </div>
          <div v-for="(info, i) in arrNewFormat1" :key="`arrNewFormat1_${i}`">
            <item-survey
              :title="$t(info.title)"
              :data="info.data"
              :value="info.defaultValue"
              :type="info.type"
              :column="info.column"
              :unit="info.unit"
              :rules="info.rules"
              :nameData="(item) => $t(item.name)"
              valueData="id"
            ></item-survey>
          </div>
          <div class="d-flex align-center mb-7 mt-9" style="height: 40px">
            {{ $t("survey.damage_manhole_cover") }}
          </div>
          <div v-for="(info, i) in arrNewFormat2" :key="`arrNewFormat2_${i}`">
            <item-survey
              :title="$t(info.title)"
              :data="info.data"
              :value="info.defaultValue"
              :type="info.type"
              :column="info.column"
              :unit="info.unit"
              :rules="info.rules"
              :nameData="(item) => $t(item.name)"
              valueData="id"
            ></item-survey>
          </div>
          <div class="d-flex align-center mb-7 mt-9" style="height: 40px">
            {{ $t("survey.surrounding_pavement") }}
          </div>
          <div v-for="(info, i) in arrNewFormat3" :key="`arrNewFormat3_${i}`">
            <item-survey
              :title="$t(info.title)"
              :data="info.data"
              :value="info.defaultValue"
              :type="info.type"
              :column="info.column"
              :unit="info.unit"
              :rules="info.rules"
              :nameData="(item) => $t(item.name)"
              valueData="id"
            ></item-survey>
          </div>
          <p class="primary--text mb-9 mt-11">
            {{ $t("survey.above_ground") }}
          </p>
          <div class="d-flex align-center mb-7 mt-9" style="height: 40px">
            {{ $t("survey.judgment_result") }}
          </div>
          <div v-for="(info, i) in arrNewFormat4" :key="`arrNewFormat4_${i}`">
            <item-survey
              :title="$t(info.title)"
              :data="info.data"
              :value="info.defaultValue"
              :type="info.type"
              :column="info.column"
              :unit="info.unit"
              :rules="info.rules"
              :nameData="(item) => $t(item.name) || item.name"
              valueData="id"
              :placeholder="$t(info.placeholder)"
            ></item-survey>
          </div>
        </v-card>
      </v-card>
      <div class="d-flex align-end justify-end survey-content_fixed-btn">
        <v-btn
          :disabled="disabledBtn"
          @click="submit"
          height="40"
          depressed
          color="primary dark--text btn-keep"
          class="mr-3 mb-9 mt-3"
          >{{ $t("profile.keep") }}</v-btn
        >
      </div>
    </v-form>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import ItemSurvey from "../../components/ItemSurvey.vue";
import {
  inspectInfo,
  manholeLidInfo,
  manholeLidInfo2,
  manholeLidInfo3,
  manholeLidInfo4,
  insideManhole,
  surfaceWear,
  newFormat,
} from "./defaultData/dataSurveyContents";
import SurfaceWear from "./SurfaceWear.vue";
import moment from "moment";
import WS from "../../services/ws";

export default {
  components: { ItemSurvey, SurfaceWear },
  data: () => {
    return {
      valid: true,
      listDropdown: [],
      arrInspection: [],
      arrManholeLid: [],
      arrManholeLid2: [],
      arrManholeLid3: [],
      arrManholeLid4: [],
      submitData: [],
      arrInside1: [],
      arrInside2: [],
      arrInside3: [],
      arrSurface: [],
      verSelected: null,
      componentKey: 0,
      index: 0,
      roundFormat: false,
      arrNewFormat1: [],
      arrNewFormat2: [],
      arrNewFormat3: [],
      arrNewFormat4: [],
      surveyType: 2,
      isSelf: false,
      tab: 0,
    };
  },
  async mounted() {
    await this.$store.dispatch("survey/getVersion", this.$route.params.id);
    if (this.$route?.query?.idSurvey) {
      this.verSelected = this.$route?.query?.idSurvey;
      switch (this.$route?.query?.typeSurvey) {
        case "1":
          await this.$store.dispatch("survey/getSurvey", this.verSelected);
          break;
        case "2":
          await this.$store.dispatch("survey/getRoundSurvey", this.verSelected);
          break;
        default:
          this.$router.push({ path: "/*" });
          break;
      }

      this.index = this.listVersion.findIndex(
        (version) => version.id == this.$route?.query?.idSurvey
      );
      this.index < 0 && this.$router.push({ path: "/*" });
    } else {
      this.verSelected = this.listVersion[0].id;
    }
    this.arrInspection = inspectInfo;
    this.arrManholeLid = manholeLidInfo;
    this.arrManholeLid2 = manholeLidInfo2;
    this.arrManholeLid3 = manholeLidInfo3;
    this.arrManholeLid4 = manholeLidInfo4;
    this.arrInside1 = insideManhole.slice(0, 5);
    this.arrInside2 = insideManhole.slice(5, 8);
    this.arrInside3 = insideManhole.slice(8);
    this.arrSurface = surfaceWear;
    this.arrNewFormat1 = newFormat.slice(0, 3);
    this.arrNewFormat2 = newFormat.slice(3, 7);
    this.arrNewFormat3 = newFormat.slice(7, 9);
    this.arrNewFormat4 = newFormat.slice(9);
    this.fillAllData();
    !this.isCreateNew && this.fetchingComment(this.verSelected);
    this.isSelf = this.currentUser.id === this.dataSurvey.user_id;
  },
  created: function () {
    const _this = this;

    WS.onmessage = function (event) {
      const surveyId =
        JSON.parse(event?.data).surveyId ||
        JSON.parse(event?.data).roundInspectionSurveyId;
      if (surveyId && surveyId === _this.verSelected)
        _this.fetchingComment(surveyId);
    };
  },
  watch: {
    listVersion() {
      this.verSelected = this.listVersion[0]?.id || "create";
    },
  },
  methods: {
    forceRerender() {
      this.componentKey += 1;
    },
    fetchingComment(surveyId) {
      if (this.surveyType === 2) {
        this.$store.dispatch("survey/getRoundComments", {
          id: surveyId,
          page: 0,
        });
      } else {
        this.$store.dispatch("survey/getSurveyComments", {
          id: surveyId,
          page: 0,
        });
      }
    },
    clickComment() {
      this.$router.push(
        `/survey/target/${this.$route.params.id}/type/${
          this.surveyType
        }/survey/${this.verSelected || this.listVersion[0].id}/comments`
      );
    },
    fillData(arrData) {
      arrData.map((itemDefault) => {
        const bindingData = this.dataSurvey;
        this.surveyType = bindingData.survey_type;
        if (bindingData.survey_type === 2) {
          this.roundFormat = true;
        } else this.roundFormat = false;
        switch (itemDefault.type) {
          case "input":
          case "input-unit":
          case "area":
            itemDefault.defaultValue = bindingData[itemDefault.column] || "";
            break;
          case "select":
            itemDefault.defaultValue.id = bindingData[itemDefault.column] || "";
            break;
          case "date":
            itemDefault.defaultValue.id = bindingData[itemDefault.column]
              ? moment(bindingData[itemDefault.column])
                  .tz("Asia/Tokyo")
                  .format("YYYY-MM-DD HH:mm")
              : "";
            break;
          default:
            itemDefault.defaultValue = bindingData[itemDefault.column] || "";
            return itemDefault;
        }
        if (itemDefault.column === "investigator" && this.isCreateNew) {
          itemDefault.defaultValue = this.currentUser.nickname;
        }
        return itemDefault;
      });
    },
    fillAllData() {
      this.fillData(this.arrInspection);
      this.fillData(this.arrManholeLid);
      this.fillData(this.arrManholeLid2);
      this.fillData(this.arrManholeLid3);
      this.fillData(this.arrManholeLid4);
      this.fillData(this.arrInside1);
      this.fillData(this.arrInside2);
      this.fillData(this.arrInside3);
      this.fillData(this.arrSurface);
      this.fillData(this.arrNewFormat1);
      this.fillData(this.arrNewFormat2);
      this.fillData(this.arrNewFormat3);
      this.fillData(this.arrNewFormat4);
    },
    async selectVersion(e) {
      this.verSelected = e;

      this.$route.fullPath !== `/survey/contents/${this.$route.params.id}` &&
        this.$router.push({
          path: `/survey/contents/${this.$route.params.id}`,
        });

      if (this.isCreateNew) {
        this.$store.dispatch("survey/createNew");
        this.surveyType = 2;
        this.isSelf = true;
      } else {
        const selected = this.listVersion.filter((item) => item.id === e)[0];
        this.surveyType = selected.survey_type;
        if (selected.survey_type === 2) {
          await this.$store.dispatch("survey/getRoundSurvey", e);
        } else {
          await this.$store.dispatch("survey/getSurvey", e);
        }
        this.isSelf = this.currentUser.id === this.dataSurvey.user_id;
        this.fetchingComment(e);
      }
      this.fillAllData();
      this.forceRerender();
    },
    selectData(e, column) {
      if (column === "survey_type") {
        this.surveyType = e;
        if (e === 2) {
          this.roundFormat = true;
        } else this.roundFormat = false;
      }
    },
    submit() {
      this.$store.dispatch("survey/changeForm", {
        column: "investigator",
        value: this.currentUser.nickname,
      });
      const objFinal = this.isCreateNew
        ? { ...this.defaultForm, ...this.form }
        : this.form;
      if (this.isCreateNew) {
        if (this.surveyType === 2) {
          const req = {
            data: {
              target_id: this.$route.params.id,
              ...objFinal,
            },
          };
          this.$store.dispatch("survey/createRoundSurvey", req);
        } else {
          const req = {
            onCreate: true,
            targetId: this.$route.params.id,
            surveyData: objFinal,
          };
          this.$store.dispatch("survey/updateSurvey", req);
        }
      } else {
        if (this.surveyType === 2) {
          const req = {
            id: this.verSelected,
            data: objFinal,
            targetId: this.$route.params.id,
          };
          this.$store.dispatch("survey/updateRoundSurvey", req);
        } else {
          const req = {
            onCreate: false,
            surveyId: this.verSelected,
            surveyData: objFinal,
            targetId: this.$route.params.id,
          };
          this.$store.dispatch("survey/updateSurvey", req);
        }
      }
      this.tab = 1;
      window.scrollTo({top: 0});
    },
  },
  computed: {
    ...mapGetters([
      "dataSurvey",
      "listVersion",
      "target",
      "form",
      "defaultForm",
      "enableSurveyBtn",
      "currentUser",
      "totalCommentsCount",
    ]),
    isCreateNew() {
      return this.verSelected === "create";
    },
    disabledBtn() {
      return !this.valid || (!this.isCreateNew && !this.enableSurveyBtn);
    },
  },
};
</script>
<style lang="scss" scoped>
.survey-content {
  position: relative;
  p {
    font-size: 16px;
  }
  &_dropdown {
    ::v-deep .v-input__slot {
      min-height: 40px !important;
      margin-right: 0;
      margin-left: auto;
    }

    ::v-deep .v-messages {
      text-align: right;

      &__message {
        line-height: 1.2;
      }
    }

    ::v-deep .v-text-field__details {
      padding-right: 0;
    }
  }

  &_tabs {
    ::v-deep .v-tabs-bar {
      border-radius: 16px;
      box-shadow: 0px 10px 20px #081539;
      .v-tab {
        font-size: 16px !important;
        color: white !important;
        &::before {
          border-radius: 16px;
        }
      }
      .v-tab--active {
        border-radius: 16px;
        color: inherit !important;
        box-shadow: 0px 10px 20px #081539;
      }
      &:hover {
        border-radius: 16px;
      }
    }
    ::v-deep .v-tabs-items {
      background-color: transparent;
      margin-top: 32px;
      font-size: 16px;
    }
  }

  &_img {
    border-radius: 0 !important;
    margin: auto;
  }

  &_fixed-btn {
    position: sticky;
    bottom: 2.5rem;
    right: 0;
    width: 100%;
    background-color: #182750;
  }

  ::v-deep .v-select__selections input {
    display: none;
  }
  ::v-deep .v-text-field.v-text-field--solo .v-input__control {
    min-height: 40px;
    font-size: 16px;
  }

  ::v-deep .v-select__selections input {
    display: none;
  }

  ::v-deep .v-select__selection {
    max-width: 100%;
  }
}
</style>
