<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="4">
        <v-card flat color="dark content">
          <v-card-text class="pb-2 text-center">
            <router-link to="/">
              <v-card-text class="pb-0 text-center">
                <v-img
                  alt="user avatar"
                  class="shrink ma-auto"
                  contain
                  :src="require('../assets/logo_pro.png')"
                  transition="scale-transition"
                  width="250"
                  height="100%"
                />
              </v-card-text>
            </router-link>
          </v-card-text>

          <v-card-title
            class="pb-4 primary--text subtitle-2 text-uppercase font-weight-black justify-center"
          >
            {{ $t('signUp.forgotPassword') }}
          </v-card-title>
          <v-card-text class="pb-0">
            {{ $t('signUp.forgotPasswordContent') }}
          </v-card-text>
          <v-card-text class="pb-0">
            <v-form ref="form" lazy-validation>
              <v-text-field
                label="Email"
                outlined
                color="primary"
                primary
                dense
                class="mb-4 sh-textfield"
                v-model="email"
                autocomplete="off"
                hide-details="auto"
                :rules="emailRules"
              >
              </v-text-field>
              <v-btn
                block
                depressed
                color="primary dark--text"
                class="mb-2"
                @click="forgotPassword"
                :disabled="!isNotValid"
              >
                {{ $t('signUp.forgotPasswordButton') }}
              </v-btn>
              <v-btn block depressed text color="black--text" class="mb-2" to="/accounts/signin">
                {{ $t('signUp.signIn') }}
              </v-btn>
              <!-- <v-btn
                block
                depressed
                color="primary black--text"
                class="mb-2"
                to="/accounts/signup"
              >
                Sign Up
              </v-btn> -->
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      email: "",
    };
  },
  watch: {
    language: {
      deep: true,
      handler() {
        this.$refs.form.reset()
      }
    }
  },
  computed: {
    ...mapGetters(["language"]),
    emailRules() {
      return [
        (v) => !!v || this.$t('signIn.emailAddressRequired'),
        (v) =>
          /.+@.+\..+/.test(v) || this.$t('signIn.validEmailAddress'),
      ];
    },
    isNotValid() {
      return this.email && /.+@.+\..+/.test(this.email);
    },
  },
  methods: {
    forgotPassword() {
      this.$store.dispatch("authen/forgotPassword", {email: this.email})
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  padding-bottom: 0;
}
.v-card__text {
  padding-bottom: 0;
  text-align: center;
}
.v-text-field--outlined.v-input--has-state ::v-deep fieldset{
  border-color: inherit;
}
.v-text-field--outlined ::v-deep fieldset{
  border-color: #81FFFF;
}
.content{
  margin-top: 1.5rem;
  @media (max-width: 576px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

</style>
