<template>
  <div>
    <v-app-bar-nav-icon
      class="side-bar"
      @click.stop="toggleSideBar"
    >
      <v-img
        v-if="toggleMenu"
        contain
        :src="require('../../assets/menu-close.svg')"
      />
      <v-img v-else contain :src="require('../../assets/menu-open.svg')" wid />
    </v-app-bar-nav-icon>
    <div v-if="status_message_bar" class="below_profile">
      <v-container fluid class="d-flex" justify="center">
        <v-row>
          <v-col md="4" class="d-flex align-center mobileJustify">
            <v-badge
              bottom
              right
              color="primary dark--text"
              class="sh-urbane"
              :content="status_message_bar"
              offset-x="82"
              offset-y="45"
            >
              <!-- <v-img :src="require('../../assets/koban.svg')" /> -->
            </v-badge>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div v-on:keyup.q="quit" v-on:keyup.t="debugbugbug">
      <div class="absolute__top__Left">
        <v-container fluid class="d-flex" justify="center">
          <v-row>
            <v-col cols="12" md="4" class="d-flex align-center">
              <router-link to="/user/profile/">
                <v-badge
                  bordered
                  bottom
                  right
                  color="primary dark--text"
                  class="sh-urbane custom-badget"
                  :content="nickname"
                  offset-x="82"
                  offset-y="16"
                >
                  <v-avatar color="dark" size="80" class="mr-2">
                    <v-img
                      alt="user avatar"
                      class="p-2"
                      contain
                      :src="this.avatar_url"
                      width="72"
                    />
                  </v-avatar>
                </v-badge>
              </router-link>
            </v-col>
          </v-row>
        </v-container>
      </div>

      <mapview :key="index" ref="mapv" />
      <loader v-model="displayLoader" />
      <welcome-dialog v-model="displayWelcome" />
    </div>
    <confirm-dialog
      :contents="dialogContents"
      :title="dialogTitle"
      :closeBtn="true"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import mapview from "../../components/LeafLet.vue";
import game from "@/game";
import loader from "../../components/user/Loader.vue";
import WelcomeDialog from "../../components/WelcomeDialog.vue";
import { eventBus } from "../../common/eventBus";
import ConfirmDialog from "../../components/modal/ConfirmDialog.vue";

export default {
  components: {
    mapview,
    loader,
    WelcomeDialog,
    ConfirmDialog,
  },
  props: {},
  data: () => ({
    dialogContents: [],
    dialogTitle: "",

    avatar_url: "",
    displayLoader: false,
    displayWelcome: false,
    level: 0,
    nickname: "nickname",
    progress: 0,
    gp: "",
    drawer: false,
    geolocationErrorNoticeDisplayed: false,
    status_message_bar: "",
    index: 1,
    avatarDefault: require("@/assets/guadians-thumb.jpg"),
  }),

  computed: {
    ...mapGetters(["currentUser", "toggleMenu", "enableCamera"]),
  },

  async mounted() {
    const _this = this;
    await _this.$store.dispatch("user/getStatus")
    
    if (localStorage.displayedWelcome) {
      this.displayedWelcome = localStorage.displayedWelcome;
      this.displayWelcome = false;
    } else{
      this.displayWelcome = true;
    }

    this.setPlayerStatusDefault()
    game.setGeolocationErrorCallBack(function (isErrorStatus) {
      if (isErrorStatus) {
        eventBus.$emit("showNotification", {
          active: true,
          type: "error",
          message: _this.$t("messageNotification.failedGpsLocation"),
          timeout: -1,
        });
      } else if (!isErrorStatus) {
        eventBus.$emit("showNotification", { active: false });
      }
    });

    game.playView = this;
    game.setPlayView(this); // TODO: こっちに統一
    if(!this.displayWelcome){
      document.body.classList.add("show-chat");
    }
    
    await game.setupGPS();

    const status = this.$route.query?.status;
    if (status === "success") {
      this.dialogTitle = this.$t("checkout.success");
      this.dialogContents = [this.$t("checkout.successMessage")];
      eventBus.$emit("showConfirmDialog");
    } else if (status === "fail") {
      this.dialogTitle = this.$t("checkout.fail");
      this.dialogContents = [this.$t("checkout.failMessage")];
      eventBus.$emit("showConfirmDialog");
    }
  },

  destroyed() {
    if(!this.displayWelcome){
      document.body.classList.remove("show-chat");
    }
  },

  watch: {
    displayWelcome() {
      if(!this.displayWelcome){
        document.body.classList.add("show-chat");
      }
    }
  },

  methods: {
    quit() {
      console.log("Quiting game");
      const _this = this;
      game.signout(function (res) {
        console.log("quit in Play:", res);
        _this.$router.push({ path: "/" });
      });
    },
    debugbugbug() {
      console.log("debugbugbug", game.getUserId());
    },
    onMapTypeClicked() {
      if (game.getMapType() == "roadmap") game.setMapType("satellite");
      else game.setMapType("roadmap");
      this.index++;
      game.setNextAutoPanTo(game.getMapCenter());
      game.setNextAutoZoom(game.getMapZoom());
    },
    toggleAddTargetMode() {
      game.toggleAddTargetMode();
    },
    toggleSideBar() {
      this.$store.dispatch("global/toggleMenuAction", !this.toggleMenu);
    },
    setPlayerStatusDefault() {
      const name = this.currentUser.nickname.length > 10 ? `${this.currentUser.nickname.substring(0,10)}...` :  this.currentUser.nickname
      this.nickname = name;
      this.avatar_url = this.avatarDefault;
    },
  },
};
</script>
<style lang="scss" scoped>
.absolute__top__Left {
  position: fixed;
  top: 20px;
  left: 0;
  z-index: 100;
  .container {
    padding-bottom: 0;
  }
}
.below_profile {
  width: 70%;
  position: fixed;
  top: 160px;
  left: 150px;
  z-index: 100;
  @media (max-width: 576px) {
    left: 0;
    width: 100%;
  }
  .container {
    padding-bottom: 0;
  }
  .mobileJustify {
    @media (max-width: 576px) {
      margin-left: 150px;
    }
  }
}
.v-avatar {
  border-width: 8px;
  border-style: solid;
}
.side-bar {
  position: fixed;
  top: 32px;
  right: 12px;
  z-index: 1021;
  width: auto;
  height: auto;
}
.custom-badget {
  position: relative;
  ::v-deep {
    .v-badge__badge {
      line-height: 0.9;
      &::after {
        border-color: #182750 !important;
        transform: scale(1.05);
      }
    }
  }
}
.outline-text {
  text-shadow: 1px 0 0 #fff, -1px 0 0 #fff, 0 1px 0 #fff, 0 -1px 0 #fff,
    1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff;
}
.progress-bar-small {
  border: 1px solid #fff;
}
::v-deep .leaflet-geosearch-bar{
  position: fixed;
  top: 32px;
 
  margin: 0;
  @media (min-width: 481px) {
    left: 50%;
    transform: translateX(-50%);
  }
  @media (max-width: 480px) {
    right: 60px;
    max-width: 215px;
  }
  @media (max-width: 360px) {
    max-width: 170px;
  }
  form {
    padding: 0;
    background: transparent;
    border: none;
    .glass {
      font-family: inherit;
      color: #182750;
      height: 40px;
      font-size: 0.85rem;
      font-weight: 600;
      letter-spacing: 0.0625rem;
      padding-right: 40px;
      padding-left: 10px;
      background-image: url('../../assets/search.svg');
      background-position: right 0.6rem center;
      background-size: 25px;
      border-radius: 20px;
      background-color: #fff;
      &::placeholder{
        color: #182750;
      }
      @media (max-width: 480px) {
        padding-left: 5px;
      }
    }
    .reset{
      display: none;
    }
    .results{
      color: #182750;
      font-size: 0.75rem;
      font-weight: 600;
      font-family: inherit;
      overflow: hidden;
      div{
        border: none;
      }
    }
    .results.active{
      border-radius: 0 0 15px 15px;
    }
  }
  form.open{
    .glass{
      border-radius: 15px 15px 0 0;
    }
  }
}
</style>
