<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
    <side-menu ref="sidemenu"/>
    <snack-bar />
  </v-app>
</template>

<script>
import SideMenu from './components/SideMenu.vue';
import SnackBar from './components/snackbar/SnackBar.vue';
import WS from "./services/ws";
import { eventBus } from "./common/eventBus";
import { mapGetters } from "vuex";

export default {
  components: { SnackBar, SideMenu },
  name: 'App',

  data: () => ({
    informationJobImage: {},
    isOpenSocket: false,
    isSentMessage: false,
  }),

  created() {
    const _this = this
    !this.currentUser?.id && _this.$store.dispatch("user/getStatus")
    _this.isOpenSocket = false
    _this.isSentMessage = false
    if(_this.$route?.name !== "Accounts") {
      WS.onmessage = function (event) {
        _this.informationJobImage = JSON.parse(event?.data)
        if(_this.informationJobImage.type === "notification_lambda") {
          console.log("informatiton", _this.informationJobImage)
          eventBus.$emit('showNotification', {
            active: true,
            type: "success",
            message: `マンホールID${_this.informationJobImage.targetId}と点検ID${_this.informationJobImage.surveyId} を更新しました`,
            idDetail: _this.informationJobImage.targetId,
            timeout: 10000,
          })
        }
      };

      WS.onopen = function (event) {
        console.log(event);
        _this.isOpenSocket = true
      };
    }
  },

  watch: {
    currentUser(newState, oldState) {
      console.log(oldState)
      if(this.isOpenSocket && newState?.id && !this.isSentMessage){
        WS.send(JSON.stringify({userId: newState.id, type: "user_connect"}));
        this.isSentMessage = true
      }
    }
  },

  computed: {
    ...mapGetters(["currentUser"]),
  },
};
</script>
<style>
.position-relative {
  position: relative;
}
.justify-content-center {
  justify-content: center;
}
.v-messages__message {
  hyphens: none !important;;
}
.cursor-pointer {
  cursor: pointer;
}
.full-mobile {
  padding: 0;
}
</style>