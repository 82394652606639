<template>
  <div class="box-tutorial" v-if="isShowNote">
      <div class="content">{{$t('homePage.noteTutorial')}}</div>
      <v-btn
        class="mt-3"
        depressed
        color="primary black--text"
        light
        minWidth="120"
        @click="closeNote"
      >
        {{$t('homePage.close')}}
      </v-btn>
  </div>
</template>

<script>
export default {
  name: 'TutorialNote',
  data: () => ({
    isOpen: true
  }),
  mounted: function () {
    localStorage.setItem('isReadTutorial', 'true');
  },
  methods: {
    closeNote() {
      this.isOpen = false
    }
  },
  computed: {
    isShowNote() {
      return this.isOpen && !JSON.parse(localStorage.getItem("isReadTutorial"));
    },
  },
}
</script>

<style lang="scss" scoped>
.box-tutorial{
  padding: 12px 15px;
  text-align: center;
  position:relative;
  background-color:#fff;
  display:inline-block;
  color: #000;
}
.box-tutorial:after{
  content:"";
  border-width:18px 12px 0;
  border-style:solid;
  border-color:#fff transparent;
  position:absolute;
  top:100%;
  left:20px
}

</style>
