const getters = {
  /* app module */
  device: (state) => state.app.device,
  status: (state) => state.global.status,
  isHideCamera: (state) => state.global.isHideCamera,
  notifications: (state) => state.global.notifications,
  isLoadingNotifications: (state) => state.global.isLoadingNotifications,
  hasLoadMoreNotifications: (state) => state.global.hasLoadMoreNotifications,
  currentUser: (state) => state.user.currentUser,
  enableCamera: (state) => state.user.enableCamera,
  changeUserSettingSuccess: (state) => state.user.changeUserSettingSuccess,
  language: (state) => state.global.language,
  toggleMenu: (state) => state.global.toggleMenu,
  responseSignup: (state) => state.authen.responseSignup,
  responseValidToken: (state) => state.authen.responseValidToken,
  responseActiveEmail: (state) => state.authen.responseActiveEmail,
  totalNotificationsNotRead: (state) => state.global.totalNotificationsNotRead,
  currentLongLat: (state) => state.instantPost.currentLongLat,
  manholesUser: (state) => state.instantPost.manholesUser,
  isLoadingManholes: (state) => state.instantPost.isLoadingManholes,
  hasLoadMoreManholes: (state) => state.instantPost.hasLoadMoreManholes,

  // Admins
  navbarDense: (state) => state.admin.settings.navbar.dense,
  navbarShow: (state) => state.admin.settings.navbar.show,
  toolbarDense: (state) => state.admin.settings.dense,

  // Admin-Users
  users: (state) => state.adminUsers.users,
  user: (state) => state.adminUsers.user,
  resetFormCreateUser: (state) => state.adminUsers.resetFormCreateUser,
  prefectures: (state) => state.adminUsers.prefectures,
  cities: (state) => state.adminUsers.cities,

  // Admin-governments
  governments: (state) => state.adminGovernments.governments,
  government: (state) => state.adminGovernments.government,
  changePasswordSuccess: (state) =>
    state.adminGovernments.changePasswordSuccess,
  resetFormCreateGovernment: (state) =>
    state.adminGovernments.resetFormCreateGovernment,

  // Survey post
  isShowPostDetail: (state) => state.instantPost.isShowPostDetail,
  toggleDeleteDialog: (state) => state.instantPost.toggleDeleteDialog,
  target: (state) => state.instantPost.target,
  targets: (state) => state.instantPost.targets,
  isShowDialogUploadImage: (state) => state.instantPost.isShowDialogUploadImage,
  isLoadingImage: (state) => state.instantPost.isLoadingImage,
  dataSurvey: (state) => state.survey.dataSurvey,
  listVersion: (state) => state.survey.listVersion,
  form: (state) => state.survey.form,
  defaultForm: (state) => state.survey.defaultForm,
  surface: (state) => state.survey.surface,
  defaultFormInstantPost: (state) => state.instantPost.defaultFormInstantPost,
  formInstantPost: (state) => state.instantPost.formInstantPost,
  enableSubmitButton: (state) => state.instantPost.enableSubmitButton,
  enableSurveyBtn: (state) => state.survey.enableSurveyBtn,
  comments: (state) => state.survey.comments,
  addCommentSuccess: (state) => state.survey.addCommentSuccess,
  isLoadingComments: (state) => state.survey.isLoadingComments,
  hasLoadMoreComments: (state) => state.survey.hasLoadMoreComments,
  totalCommentsCount: (state) => state.survey.totalCommentsCount,
  post: (state) => state.survey.post,
  showChooseTypeImage: (state) => state.survey.showChooseTypeImage,

  //Admin posts
  posts: (state) => state.adminPosts.posts,
  roundPosts: (state) => state.adminPosts.roundPosts,
  adminTargets: (state) => state.adminPosts.adminTargets,
  listTargets: (state) => state.adminPosts.listTargets,
  changeStatusPost: (state) => state.adminPosts.changeStatusPost,
  linkIDmanhole: (state) => state.adminPosts.linkIDmanhole,
};
export default getters;
