import game from "@/game";
import router from "../../router";
import store from "@/store";
import { eventBus } from "../../common/eventBus.js";

const state = {
  target: {
    lat: 35.6598003,
    lon: 139.7023894,
  },
  isShowPostDetail: false,
  targets: {},
  inforLatLngAddTarget: {},
  toggleDeleteDialog: false,
  formInstantPost: {},
  defaultFormInstantPost: {
    adjustment_ring_dimension: "",
    area_characteristic: "",
    distance_upper_gl: null,
    downstream_number: null,
    drawing_mesh: null,
    drawing_name: null,
    drawing_page: null,
    ledger_manhole_no: "",
    lid_diameter: null,
    lid_type: null,
    load_bearing_type: null,
    location: null,
    location_driveway: null,
    manhole_depth: null,
    manhole_number: null,
    manhole_type: null,
    manufacture_year: null,
    manufacturer: null,
    material: null,
    pavement_type: null,
    pillar_structure: null,
    pipeline_classification: null,
    processing_division: null,
    road_alignment: null,
    road_type: null,
    road_width: null,
    rubbing: null,
    secondary_pipe: null,
    step_drop: null,
    lid_completion_year: null,
    lid_completion_date: null,
    lid_count: null,
    insulation: null,
  },
  enableSubmitButton: false,
  isShowDialogUploadImage: false,
  isLoadingImage: false,
  currentLongLat: {},
  manholesUser: [],
  isLoadingManholes: false,
  hasLoadMoreManholes: false,
  totalManholes: 0,
};

const mutations = {
  POST_ADD_TARGET: (state, payload) => {
    state.target = payload;
  },
  TOGGLE_SHOW_UPLOAD_IMAGE: (state) => {
    state.isShowDialogUploadImage = !state.isShowDialogUploadImage;
  },
  POST_MOVE_TARGET: (state, payload) => {
    console.log(state, payload);
  },
  GET_TARGET_BY_ID: (state, payload) => {
    state.target = payload.data.target;
    state.formInstantPost = {};
    state.enableSubmitButton = false;
  },
  GET_LIST_TARGETS: (state, payload) => {
    state.targets = payload.data;
  },
  SET_TARGET_DETAIL: (state, payload) => {
    console.log(state, payload);
  },
  DELETE_TARGET_SUCCESS: (state) => {
    state.toggleDeleteDialog = false;
    state.target = {};
  },
  SET_LONG_LAT_ADD_TARGET: (state, payload) => {
    (state.inforLatLngAddTarget = payload), (state.target = {});
  },
  TOGGLE_DELETE_DIALOG: (state) => {
    state.toggleDeleteDialog = !state.toggleDeleteDialog;
  },
  CHANGE_FORM: (state, payload) => {
    state.formInstantPost[payload.column] = payload.value;
    let stateButton = false;
    for (const property in state.formInstantPost) {
      const targetFormat =
        property === "area_characteristic"
          ? state.target[property]?.replace(/\s/g, "")?.toString()
          : state.target[property]?.toString();
      const formInstanFormat =
        state.formInstantPost[property] === null
          ? ""
          : state.formInstantPost[property].toString();

      if (targetFormat !== formInstanFormat) {
        stateButton = true;
      }
    }
    state.enableSubmitButton = stateButton;
  },
  RESET_FORM: (state) => {
    state.formInstantPost = {};
  },
  LOADING_IMAGE: (state, payload) => {
    state.isLoadingImage = payload;
  },
  CURRENT_LONG_LAT: (state, payload) => {
    state.currentLongLat = payload;
  },
  GET_SUCCESS_MANHOLES_USER: (state, payload) => {
    state.hasLoadMoreManholes = payload.currentPage + 1 < payload.totalPages;
    if (payload.currentPage === 0) {
      state.manholesUser = payload.resources;
    } else {
      state.manholesUser = [...state.manholesUser, ...payload.resources];
    }
    state.totalManholes = payload.totalItems;
  },
};

const actions = {
  postAddTarget({ commit, state }, payload) {
    commit("LOADING_IMAGE", true);
    game.postAddTarget(payload, function (res) {
      if (res.code) {
        commit("LOADING_IMAGE", false);
      }
      switch (res.code) {
        case 200:
          store.dispatch("instantPost/getListTargets", {
            lng: payload.lng,
            lat: payload.lat,
          });
          commit("POST_ADD_TARGET", res.data.target.target);
          if (!state.isLoadingImage) {
            commit("TOGGLE_SHOW_UPLOAD_IMAGE");
          }
          break;
        case 400:
          eventBus.$emit("showNotification", {
            active: true,
            type: "error",
            message: "You don't have permission to manage this area.",
          });
          break;
        default:
          break;
      }
    });
  },

  editTarget({ commit }, payload) {
    game.editTarget(payload.target_id, payload.data, function (res) {
      switch (res.code) {
        case 200:
          commit("POST_ADD_TARGET", res.data.target);
          if (router.history.current.name === "マンホール詳細" || router.history.current.name === "User Manhole") {
            router.go(0);
          } else {
            router
              .push(`/survey/contents/${res.data.target.id}`)
              .catch(() => {});
          }
          break;
        case 400:
          router.push("/*").catch(() => {});
          break;
        default:
          break;
      }
    });
  },

  getTargetById({ commit }, payload) {
    return game.getTargetById(payload, function (res) {
      eventBus.$emit("showNotification", {
        active: false,
      });
      switch (res.code) {
        case 200:
          commit("GET_TARGET_BY_ID", res);
          break;
        default:
          router.push("/*").catch(() => {});
          break;
      }
    });
  },
  getListTargets({ commit }, payload) {
    commit("CURRENT_LONG_LAT", payload);
    game.getListTargets(payload.lng, payload.lat, function (res) {
      if (res.code === 200) {
        commit("GET_LIST_TARGETS", res);
      }
    });
  },
  findNearestTarget({ commit }, payload) {
    commit("SET_TARGET_DETAIL", true);
    state.target = game.findNearestTarget(payload, state.targets);
  },
  deleteTarget({ commit }, payload) {
    game.postDeleteTarget(payload, function (res) {
      if (res.code === 201) {
        commit("DELETE_TARGET_SUCCESS", res.data);
        router.push(`/user/play`).catch(() => {});
      }
    });
  },
  getListManholesUser({ commit }, payload) {
    return game.getListManholesUser(payload, function (res) {
      if (res.code === 200) {
        commit("GET_SUCCESS_MANHOLES_USER", res.data);
      }
    });
  },
  longLatAddTarget({ commit }, payload) {
    commit("SET_LONG_LAT_ADD_TARGET", payload);
  },
  toggleDeleteDialog({ commit }) {
    commit("TOGGLE_DELETE_DIALOG");
  },
  changeForm({ commit }, obj) {
    commit("CHANGE_FORM", obj);
  },
  resetFormInstant({ commit }) {
    commit("RESET_FORM");
  },
  toggleShowUploadImage({ commit }) {
    commit("TOGGLE_SHOW_UPLOAD_IMAGE");
  },
  loadingImage({ commit }, payload) {
    commit("LOADING_IMAGE", payload);
  },
  resetCurrentLocation({ commit }) {
    commit("CURRENT_LONG_LAT", {});
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
