export const defaultTypeImage = [
  {
    text: "survey.above_ground_part",
    value: 1,
  },
  {
    text: "survey.front_of_manhole_cover",
    value: 2,
  },
  {
    text: "survey.back_cover",
    value: 3,
  },
  // {
  //   text: "survey.surface_ware_measurement",
  //   value: 4,
  // },
  {
    text: "survey.inner_manhole",
    value: 5,
  },
  {
    text: "survey.damaged",
    value: 7,
  },
  {
    text: "survey.other",
    value: 8,
  },
]