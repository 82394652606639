<template>
  <div class="text-center">
    <v-dialog v-model="isOpen" width="290" persistent>
      <v-card light class="card-custom">
        <v-card-title class="justify-content-center mb-3">
          {{ title }}
          <v-btn v-if="showCloseBtn" fab dark small depressed color="primary" class="absolute" @click="clickCancel(isOpen)">
            <v-icon color="dark">
              mdi-window-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <div
          class="flex-column d-flex"
        >
          <v-btn
            class="ml-0 mb-2"
            depressed
            block
            @click="clickCancel(isOpen)"
          >
            {{ textCancel }}
          </v-btn>
          <v-btn
            color="dark white--text"
            class="mb-2"
            depressed
            block
            @click="clickAccept"
          >
            {{ textBtn }}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, required: false },
    contents: { type: Array, required: false },
    isOpen: { type: Boolean, default: false, required: true },
    clickCancel: {
      type: Function,
      required: false,
      default: function(){
        this.isOpen = false
      }
    },
    clickAccept: { type: Function, required: true, default: function() {} },
    textBtn: { type: String, required: false, default: "OK" },
    textCancel: { type: String, required: false, default: "キャンセル" },
    showCloseBtn: {type: Boolean, required: false, default: false}
  },

  data() {
    return {
      dialog: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.v-dialog__content{
  bottom: 0;
  align-items: flex-end;
  padding-bottom: 55px;
}
.card-custom {
  padding: 19px;
  text-align: center;
  .justify-content-center {
    justify-content: center;
    .custom-btn {
      min-width: 35%;
      margin: 0 8px;
      padding: 20px 0;
      border-radius: 18px;
      &.cancel {
        box-shadow: 0px 0px 1px 1px black;
      }
    }
    .button-camera{
      min-width: 50%;
      margin: 0 8px;
      padding: 20px 0;
      border-radius: 18px;
    }
    .normal-button {
      width: fit-content;
    }
  }
}
</style>
