<template>
  <div>
    <v-app-bar app color="dark darken-1" dark flat bottom>
      <v-tabs
        center-active
        centered
        class="sh-urbane"
        color="dark"
        dark
        grow
        hide-slider
        icons-and-text
        mobile-breakpoint
      >
        <v-tab to="/user/play" @click="clickShowCamera">
          <span>{{ $t('bottomNavigation.home') }}</span>
          <v-icon>mdi-map</v-icon>
        </v-tab>
        <!-- <v-tab to="/user/rewards">
          <span>Rewards</span>
          <v-icon>mdi-star-circle</v-icon>
        </v-tab> -->
        <!-- <v-tab to="/user/activity">
                Activity
                <v-icon>mdi-earth</v-icon>
            </v-tab> -->
<!--            
        <v-tab to="/user/ranking">
          <span>{{ $t('bottomNavigation.ranking') }}</span>
          <v-icon>mdi-crown</v-icon>
        </v-tab>
-->        
        <!-- <v-tab to="/user/review" @click="clickShowCamera">
          <span>{{ $t('bottomNavigation.review') }}</span>
          <v-icon>mdi-thumb-up</v-icon>
        </v-tab> -->
        <!-- pinは廃止された
        <v-tab @click="clickPin" to="/user/pin">
          <span>{{ $t('bottomNavigation.pin') }}</span>
          <v-icon>mdi-pin</v-icon>
        </v-tab>
        -->
        <v-tab to="/user/manholes">
          <span>{{ $t('bottomNavigation.manholes') }}</span>
          <v-icon>mdi-lightning-bolt-circle</v-icon>
        </v-tab>
        <!--
        <v-tab to="/group/feed">
          <span>Chat</span>
          <v-icon>mdi-chat</v-icon>
        </v-tab>
        -->
        <!-- <v-tab to="/user/chat">
                <v-icon>mdi-chat</v-icon>
            </v-tab> -->
        <!-- <v-tab to="/user/rewiew" v-if="enableReview">
          <span>Labeling</span>
          <v-icon>mdi-thumb-up</v-icon>
        </v-tab> -->
        <v-tab to="/user/notifications" active-class="notification-active">
          <span>{{ $t('bottomNavigation.notifications') }}</span>
          <div class="box-icon">
            <span class="box-icon_number" v-if="totalNotificationsNotRead > 0">
              {{renderTotalNotiNotRead}}
            </span>
            <v-icon>mdi-bell-ring</v-icon>
          </div>
        </v-tab>
      </v-tabs>
    </v-app-bar>
  </div>
</template>
<script>
import game from "@/game"
import { mapGetters } from 'vuex'

export default {
  components: {},
  data: () => ({
    enableReview: true, // user review experiment
  }),
  async mounted(){
    if(this.$route.name === 'Pin') {
      this.clickPin()
    }
		await this.$store.dispatch("global/getNotificationNotRead")
  },
  computed: {
    ...mapGetters([
      "totalNotificationsNotRead",
    ]),
    renderTotalNotiNotRead() {
      return this.totalNotificationsNotRead > 99 ? "99+" : this.totalNotificationsNotRead
    }
  },
  methods: {
    clickPin() {
      const dp = game.getPlayView().$refs.plotmanhole;
      dp.displayPlot = true;
      this.$store.dispatch("global/toggleHideCamera", true);
    },
    clickShowCamera() {
      this.$store.dispatch("global/toggleHideCamera", false);
    },
  },
};
</script>
<style lang="scss" scopped>
.v-container {
  padding: 0;
}
.v-slide-group__next,
.v-slide-group__prev {
  display: none !important;
}
.notification-active{
  .v-icon{
    color: #182750 !important;
  }
}
.v-tab {
  font-size: 0.625rem !important;
  font-weight: 600 !important;
  letter-spacing: 0.0625rem !important;
  text-transform: inherit !important;
  &--active {
    background-color: #81ffff;
  }
  
  .box-icon{
    position: relative;
    &_number{
      position: absolute;
      padding: 4px 1px;
      color: white;
      background-color: red;
      border-radius: 100px;
      z-index: 10;
      right: -8px;
      top: -4px;
      font-size: 7px;
      width: 19px;
      height: 19px;
    }
    .v-icon{
      color: rgba(255, 255, 255, 0.6);
    }
  }
}
</style>
