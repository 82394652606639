<template>
  <div>
    <!-- div id="mapview"></div -->
    <div id="map-app">
      <v-map :zoom=20 :center="initialLocation" ref="mymap" @ready="initMap" @click="clickOnMap" @moveend="moveEnd">
        <v-tilelayer-googlemutant :apikey="apikey" :options="options"></v-tilelayer-googlemutant>
        <template v-if="layerLoaded">
          <v-geojson v-for="(pipeline, i) in $options.layer" :key="i" :geojson="pipeline.data" :options="{'color': pipeline.color}"></v-geojson>
        </template>
      </v-map>
      <div class="box-confirm tutorial-note">
        <tutorial-note/>
      </div>
      <bottom-dialog
        :isOpen="isOpenUploadImage"
        :title="$t('instantPost.titleLoadImage')"
        :contents="[contentDialog]"
        :clickAccept="clickCloseUploadImage"
        :clickCancel="addTarget"
        :textCancel="$t('instantPost.textCancelUploadImage')"
        :textBtn="$t('instantPost.textAcceptUploadImage')"
        customClass="flex-column d-flex"
      />
      <selection-dialog
        :isOpen="isOpenConfirmFinal"
        :title="$t('instantPost.titleConfirmFinal')"
        :contents="[contentDialog]"
        :clickAccept="clickConfirmFinal"
        :clickCancel="clickCloseDialog"
        :isCameraTargetDialog="true"
        :textCancel="$t('instantPost.cancelConfirmFianl')"
        :textBtn="$t('instantPost.textAcceptConfirmFinal')"
      />
      <dialog-upload-image
        :isOpen="isShowDialogUploadImage"
        :title="$t('instantPost.takePhotoFrontCover')"
        :clickCancel="clickCloseDialog"
        :clickManhole="clickManhole"
        :listVersion="[]"
        :uploadInMap="true"
      />
      <div v-if="isShowConfirmPosition" class="note-position box-confirm">
        <div class="box-note">
          <span>{{$t('instantPost.notePosition')}}</span>
        </div>
      </div>
      <div v-if="isShowConfirmPosition" class="button-position box-confirm">
        <v-btn
          color="dark white--text"
          class="mb-2"
          depressed
          minWidth="120"
          @click="clickButtonConfirmPosition"
        >
          {{$t('instantPost.textButtonAcceptPosition')}}
        </v-btn>
      </div>
    </div>
    <loading 
      :showLoading="isLoadingImage"
      backgroundColor="transparent"
      textColor="transparent"
      progressColor="#182750"
    >
    </loading>
  </div>
</template>
<script>

import L from 'leaflet';
import "leaflet/dist/leaflet.css";
import game from "@/game"
import { GoogleProvider } from 'leaflet-geosearch';
import * as GeoSearch from 'leaflet-geosearch';
import { latLng } from 'leaflet'
import { LMap, LGeoJson } from 'vue2-leaflet'
import Vue2LeafletGoogleMutant from './Vue2LeafletGoogleMutant.vue'
import SelectionDialog from "../components/modal/SelectionDialog.vue";
import TutorialNote from "./TutorialNote.vue";
import { mapGetters } from "vuex";
import BottomDialog from "../components/modal/BottomDialog.vue";
import Loading from "../components/Loading/Loading.vue";
import DialogUploadImage from './modal/DialogUploadImage.vue';

//Google Maps のスタイル指定


var styles = [
  {
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "administrative",
    "elementType": "geometry",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "administrative.country",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "administrative.locality",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "administrative.neighborhood",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "landscape.natural",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#a7ee6d"
      }
    ]
  },
  {
    "featureType": "poi",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi.attraction",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi.attraction",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "simplified"
      }
    ]
  },
  {
    "featureType": "poi.business",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi.government",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi.medical",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text",
    "stylers": [
      {
        "visibility": "simplified"
      },
      {
        "weight": 1
      }
    ]
  },
  {
    "featureType": "poi.place_of_worship",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi.place_of_worship",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "simplified"
      }
    ]
  },
  {
    "featureType": "poi.school",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi.school",
    "elementType": "labels.text",
    "stylers": [
      {
        "visibility": "simplified"
      }
    ]
  },
  {
    "featureType": "poi.sports_complex",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#ffffff"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#ffffff"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#ffffff"
      }
    ]
  },
  {
    "featureType": "transit.station",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "transit.station",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "simplified"
      },
      {
        "weight": 1
      }
    ]
  },
  {
    "featureType": "transit.station.airport",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "transit.station.bus",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "transit.station.rail",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  }
];




export default{
  name: "vmap",
  components: {
    'v-map': LMap,
    'v-geojson': LGeoJson,
    'v-tilelayer-googlemutant': Vue2LeafletGoogleMutant,
    SelectionDialog,
    TutorialNote,
    BottomDialog,
    Loading,
    DialogUploadImage,
  },
  layer: {},
  props: {
    manholeMap: Boolean,
  },
  async mounted(){
    this.map = this.$refs.mymap.mapObject; //     L.map("mapview", {dragging: true, zoom:18, minZoom: 10, maxZoom:22});
    this.map.zoomControl.setPosition('bottomright');
    const search = new GeoSearch.GeoSearchControl({
      provider: new GeoSearch.GoogleProvider({
        params: {
          key: this.apikey,
        },
      }),
      style: 'bar',
      showMarker: false,
      autoClose: true,
    });
    !this.manholeMap && this.map.addControl(search);
    game.setLeafletMap(this.map,L);
   
    this.map.on("click", this.clickOnMap);
    this.map.on("moveend", this.moveEnd);
    await this.setupControls();
    const autopan=game.getNextAutoPanTo();
    if(autopan) {
      this.goto(autopan.lat,autopan.lng,game.getNextAutoZoom());
      game.setNextAutoPanTo(null);
      game.setNextAutoZoom(null);
    }
    let latlng = []
    let sz = 20;
    let manhole_icon = L.icon({
        iconUrl: require("@/assets/manhole_under_review.svg"),
        iconSize:     [sz, sz],
        iconAnchor:   [sz/2, sz/2],
    });
    let manhole_ellipse_icon = L.icon({
        iconUrl: require("@/assets/ellipse_manhole2.svg"),
        iconSize:     [36, 36],
        iconAnchor:   [18, 18],
    });

    if(this.manholeMap){
      switch(this.$route.params.id){
        case "add-target":
          latlng = [this.$route.query.lat, this.$route.query.lng]
          break;
        default:
          await this.$store.dispatch("instantPost/getTargetById", this.$route.params.id);
          latlng = [this.target.lat,this.target.lon];
          break;
      }
      this.map.setView(latlng,19);
      this.$route.params.id === "add-target" && L.marker(latlng, {icon:manhole_icon}).addTo(this.map);
      L.marker(latlng, {icon:manhole_ellipse_icon}).addTo(this.map);

    }
    if(this.currentLongLat?.lat && this.currentLongLat?.lng){
      this.$store.dispatch("instantPost/getListTargets", this.currentLongLat)
    }
  },
  data: function(){
    let options = {
      maxZoom: 22,
      type: game.getMapType(),//'satellite',
      styles:styles, //スタイルを指定
    }
    let apikey = 'AIzaSyD01Q9Lc50xGdzj3tTwrhHyuRT7MUfgZ3w'
    return{    
      latlng: [35.56972432866644, 139.93689452948513], // 渋谷にしていたがやめた。東京湾の上にする
      scale: 18,
      apikey,
      options,
      initialLocation: latLng(35.6598003, 139.7023894), // 渋谷だとこんらんする 35.6598003, 139.7023894),
      isOpenUploadImage: false,
      isOpenConfirmFinal: false,
      contentDialog: "",
      titleDialogAddTarget: "",
      isShowConfirmPosition: false,
      showCamera: false,
      disableCamera: false,
      geosearchOptions: { // Important part Here
        style: 'button',
        provider: new GoogleProvider({
          params: {
            key: apikey,
          },
        }),
      },
      layerLoaded: false,
    }
  },
  watch: {
    isLoadingImage(newState, oldState) {
      if(oldState && !newState){
        this.disableCamera = false
        this?.map?.camera_targets_layer_btn && this.enableCamera()
      }
    }
  },

  async created() {
    var self = this;

    // Draw a layer only when current user is admin or in nishinomiya.
    const CITY_ID_NISHINOMIYA = 1295;
    await this.$store.dispatch("user/getStatus");
    if (this.currentUser?.city?.id === CITY_ID_NISHINOMIYA || this.currentUser?.role === "admin") {
      await game.getLayer(function (res) {
        console.log({ layer: res.data });
        self.$options.layer = res.data;
      });
    }

    this.layerLoaded = this.$options.layer && Object.keys(this.$options.layer).length > 0;
  },

  methods: {
    async initMap() {
      this.map = this.$refs.mymap.mapObject; //     L.map("mapview", {dragging: true, zoom:18, minZoom: 10, maxZoom:22});
      game.setLeafletMap(this.map,L);
      await this.setupControls();
      this.map.zoomControl.setPosition('bottomright');
      const autopan=game.getNextAutoPanTo();
      const latLngCurrent = game.returnCurrentLocation()
      if(latLngCurrent.lat !== 0 && latLngCurrent.lng !== 0 && !this.manholeMap){
        this.goto(latLngCurrent.lat,latLngCurrent.lng, 19)
      }
      if(autopan) {
        this.goto(autopan.lat,autopan.lng,game.getNextAutoZoom());
        game.setNextAutoPanTo(null);
        game.setNextAutoZoom(null);
      }
    },
    goto(lat,lng,zoom) {
      if(!zoom) zoom=20;
      this.latlng = [lat,lng];
      this.map.setView(this.latlng,zoom);
    },
    moveEnd() { 
      const center=game.getMapCenter();
      const includesLat = center.lat > this.currentLongLat?.lat - 0.003 && center.lat < this.currentLongLat.lat + 0.003
      const includesLng = center.lng > this.currentLongLat?.lng - 0.003 && center.lng < this.currentLongLat.lng + 0.003
      switch(true) {
        case game.getMapZoom() < 17:
          if(this.currentLongLat?.lat){
            this.$store.dispatch("instantPost/getListTargets", center)
            this.$store.dispatch("instantPost/resetCurrentLocation")
          }
          break;
        default:
          if(!this.currentLongLat?.lat){
            this.$store.dispatch("instantPost/getListTargets", center)
          } else{
            (!includesLat || !includesLng) && this.$store.dispatch("instantPost/getListTargets", center)
          }
          break;

      }
    },
    async setupControls() {
      this.setupMapTypeControl();
      this.setupCenterSyncControl();
      const CITY_ID_NISHINOMIYA = 1295;
      await this.$store.dispatch("user/getStatus");
      if (this.currentUser?.city?.id === CITY_ID_NISHINOMIYA) {
        this.setupHomeButton();
      }
      !this.manholeMap && this.setupCameraTargetsLayerButton();
    },
    setupCenterSyncControl() {
      const map=this.map;
      const _this = this;
      if(map.center_sync_btn) return;
      L.Control.CenterSync = L.Control.extend({  
        onAdd: function(map) {
          const img = L.DomUtil.create('img');
          img.src = require("@/assets/center_sync.png"),
          img.style.width = '40px';
          img.addEventListener("click", function(e) { e.preventDefault();e.stopPropagation(); },false);
          img.addEventListener('pointerdown', function(e) {  
            e.stopPropagation();
            e.preventDefault();
            const pcloc=game.getPlayerLocation();
            map.panTo(pcloc);
            _this.goto(pcloc.lat, pcloc.lng, 19)
          });
          return img;
        }
      });
      L.control.centerSync = function(opts) { return new L.Control.CenterSync(opts); }
      map.center_sync_btn = L.control.centerSync({ position: 'bottomright' }).addTo(map); 
    },
    setupMapTypeControl() {      
      const map=this.map;
      if(map.map_type_btn) return;
      L.Control.MapType = L.Control.extend({  
        onAdd: function(/*map*/) {
          const img = L.DomUtil.create('img');
          const suffix=game.getMapTypeButtonEnabled() ? "" : "_dark";
          if(game.getMapType()=="roadmap") img.src=require("@/assets/satellite_map_button"+suffix+".png"); 
          else img.src=require("@/assets/road_map_button"+suffix+".png");          
          img.style.width = '40px';
          img.addEventListener("click", function(e) { e.preventDefault();e.stopPropagation(); },false);
          img.addEventListener('pointerdown', function(e) {  
            e.stopPropagation();
            e.preventDefault();
            if(game.playView && game.getMapTypeButtonEnabled() && !game.isAddTargetMode()) game.playView.onMapTypeClicked();
          });
          return img;
        }
      });

      L.control.mapType = function(opts) { return new L.Control.MapType(opts); }
      map.map_type_btn = L.control.mapType({ position: 'bottomright' }).addTo(map); 
    },
  
    selectMapLayer(layername) {
      game.selectMapLayer(layername);
    },
    enableCamera() {
      const img=this.map?.camera_targets_layer_btn?.img;
      if(img){
        img.src=require('@/assets/camera_targets.png');
      }
    },
    setupCameraTargetsLayerButton() {
      const map=this.map;
      if(this.showCamera) return;
      const _this = this;
      const img = L.DomUtil.create('img');
      img.src = _this.disableCamera ? require('@/assets/camera_targets_dark.png') : require('@/assets/camera_targets.png');
      game.selectMapLayer("global_targets"); // TODO: こんなところでやるのはおかしい。レイヤー表示の仕様を整理してから直せ
      
      img.style.width = '64px';
      img.style.cursor = 'pointer'
      img.addEventListener('click', function(e) {
        e.stopPropagation();
        e.preventDefault();
        if(!_this.disableCamera){
          _this.isOpenUploadImage = true
        }  
        const pcloc= game.getPlayerLocation();
        if(pcloc) _this.map.panTo(pcloc);
        _this.disableCamera = true
        // img.src = require('@/assets/camera_targets_dark.png')

      }, false);
      L.Control.CameraTargetsLayerButton = L.Control.extend({
        onAdd: function(/*map*/) {
          return img;
        }
      });
      L.control.cameraTargetsLayerButton = function(opts) { return new L.Control.CameraTargetsLayerButton(opts); }
      map.camera_targets_layer_btn = L.control.cameraTargetsLayerButton({ position: 'bottomleft' });
      map.camera_targets_layer_btn.addTo(map);
      map.camera_targets_layer_btn.img=img;
      this.showCamera = true
    },
    setupHomeButton() {
      const _this = this;
      if (_this.map.home_btn) {
        return;
      }
      L.Control.HomeButton = L.Control.extend({
        onAdd: function (/*map*/) {
          const img = L.DomUtil.create('img');
          img.src = require('@/assets/home.png');
          img.style.width = '40px';
          img.style.cursor = 'pointer'
          img.addEventListener('click', function(e) {
            e.stopPropagation();
            e.preventDefault();
            _this.goto(34.73798986786637, 135.34277147942322, 19);
          });
          return img;
        }
      })
      _this.map.home_btn = new L.Control.HomeButton({position: 'bottomright'});
      _this.map.home_btn.addTo(this.map);
    },

    addTarget(){      
      this.isOpenUploadImage = false
      this.isShowConfirmPosition = true;
      game.toggleAddTargetMode();
      const img=this.map.camera_targets_layer_btn.img;
      img.src=require('@/assets/camera_targets_dark.png');
      if(game.isAddTargetMode()) {
        const center=game.getMapCenter();
        game.setAddTargetCrossLocation(center.lat,center.lng);
      } else {
        game.setAddTargetCrossLocation(0,0);
      }
    },
    clickConfirmFinal(){
      // 位置が確定した最終
      const latlng=game.getAddTargetCrossLocation();
      this.$store.dispatch("instantPost/longLatAddTarget", latlng)
      this.isOpenConfirmFinal = false;
      game.setAddTargetCrossLocation(0,0);
      this.showCamera=false;
      this.disableCamera = false
      this.$store.dispatch("instantPost/postAddTarget", {...this.defaultFormInstantPost, lat: latlng.lat, lng: latlng.lng});
    },
    clickCloseDialog(){
      this.isOpenConfirmFinal = false
      this.showCamera = false
      this.disableCamera = false
      this.isShowDialogUploadImage && this.$store.dispatch("instantPost/toggleShowUploadImage")
      if(this.manholeMap){
        this.$router.push({path: `/user/play`})
      }
      this.enableCamera()
      game.setAddTargetCrossLocation(0,0);
      
    },
    clickManhole(){
      this.isShowDialogUploadImage && this.$store.dispatch("instantPost/toggleShowUploadImage")
      this.$router.push({path: `/user/manhole/${this.target.id}`}).catch(()=>{});
    },
    clickCloseUploadImage(){
      this.isOpenUploadImage = false
      this.disableCamera = false
      this.enableCamera()
    },
    clickButtonConfirmPosition(){
      this.isOpenUploadImage = false
      this.isShowConfirmPosition = false
      this.isOpenConfirmFinal = true
      game.toggleAddTargetMode();
    },
    clickOnMap(e) {
      const nt=new Date().getTime();
      if(this.last_click_at && this.last_click_at>nt-200) return;
      this.last_click_at=nt;
      this.add_pin_popup=null;
      if(game.isAddTargetMode()) { 
        game.setAddTargetCrossLocation(e.latlng.lat, e.latlng.lng);
      } else {
        if(this.$route.path.includes("/user/play") ){
          if(this.map.getZoom()>=16) { 
            this.clickOnTarget(e);
          } 
        }
      }
    },
    async clickOnTarget(e) {
      await this.$store.dispatch("instantPost/findNearestTarget", e.latlng)
      
      if(this.target?.survey_count < 1 && this.target?.id){
        this.$store.dispatch("instantPost/toggleShowUploadImage")
      } else if(this.target?.id){
        this.$router.push({path: `/survey/manhole-info/${this.target.id}`}).catch(()=>{});
      }

    },
  
    move(direction){
      this.offset = 0.005
      switch(direction){
        case "left":
        this.latlng = [this.latlng[0], this.latlng[1] - this.offset]
        this.map.panTo(this.latlng)
        break;
        case "up":
        this.latlng = [this.latlng[0] + this.offset, this.latlng[1]]
        this.map.panTo(this.latlng)
        break;
        case "right":
        this.latlng = [this.latlng[0], this.latlng[1] + this.offset]
        this.map.panTo(this.latlng)
        break;
        case "down":
        this.latlng = [this.latlng[0] - this.offset, this.latlng[1]]
        this.map.panTo(this.latlng)
        break;
      }
    },
     addmarker() {
      var twpicicon = L.icon( {                                                      
        iconUrl: "https://pbs.twimg.com/profile_images/1240779409948151808/HNBXZptk_normal.png",
        iconSize: [30,30],                                                         
        iconAnchor: [0,0]                                                          
      });                                                                            
      var latlng = [ this.latlng[0] + Math.random()*0.002, this.latlng[1]+Math.random()*0.002 ];
      L.marker(latlng,{icon:twpicicon}).addTo(this.map);
      
    }

  },
  computed: {
    ...mapGetters([
      "currentUser",
      "target", 
      "listVersion", 
      "defaultFormInstantPost", 
      "isShowDialogUploadImage",
      "isLoadingImage",
      "currentLongLat",
    ])
  },
  
}
</script>
<style lang="scss" scoped>
  #mapview {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0; right: 0; bottom: 0;  left: 0;
    z-index: 1;
  }
  #map-app{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0; right: 0; bottom: 0;  left: 0;
    z-index: 2;
  }
  ::v-deep .geosearch{
    position: fixed;
    top: 20%;
    left: 0;
    background: gray;
  }
  .box-confirm{
    position: absolute;
    width: 100%;
    z-index: 1000;
    display: flex;
    justify-content: center;
    .box-note{
      background-color: white;
      padding: 15px 30px;
      width: fit-content;
      text-align: center;
      color: #000;
      border-radius: 8px;
    }
  }
  .note-position{
    top: 155px;
    width: fit-content;
    left: calc(50% - 157px);
  }
  .button-position{
    bottom: 75px;
    width: fit-content;
    left: calc(50% - 60px);
  }
  .tutorial-note{
    bottom: 160px;
    padding: 0 13px;
    justify-content: left;
  }
  ::v-deep .leaflet-left{
    img{
      position: relative;
      z-index: 9999999;
    }
  }
  ::v-deep .leaflet-bottom{
    margin-bottom: 60px;
  }
  
</style>