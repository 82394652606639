<template>
  <v-card flat class="item-manhole dark lighten-2" @click="clickDetailManhole">
    <v-list-item-icon>
      <v-img
        alt="user avatar"
        class="shrink rounded"
        :src="item.frontCover || require('@/assets/manholeGray.svg')"
        transition="fade-transition"
        width="114"
        height="114"
      />
    </v-list-item-icon>

    <v-list-item-content class="pl-2 py-3">
      <v-list-item-title
        class="sh-urbane font-weight-bold text-uppercase"
        v-if="renderPrefCity(item, 'pref') || renderPrefCity(item, 'pref')"
      >
        <small>{{ "場所" }}</small>
      </v-list-item-title>
      <v-list-item-subtitle class="sh-urbane font-weight-bold" color="black">
        {{ renderPrefCity(item, "pref") }} {{ renderPrefCity(item, "city") }}
      </v-list-item-subtitle>

      <div>
        <v-list-item-title class="sh-urbane font-weight-bold text-uppercase">
          <small>{{ "マンホールID" }}</small>
        </v-list-item-title>
        <v-list-item-subtitle class="sh-urbane font-weight-bold" color="black">
          {{ item.id }}
        </v-list-item-subtitle>
      </div>

      <div>
        <v-list-item-title class="sh-urbane font-weight-bold text-uppercase">
          <small>{{ $t('survey.ledgerManholeNo') }}</small>
        </v-list-item-title>
        <v-list-item-subtitle class="sh-urbane font-weight-bold" color="black">
          {{ item.ledger_manhole_no }}
        </v-list-item-subtitle>
      </div>

      <v-list-item-title
        class="sh-urbane font-weight-bold text-uppercase"
        v-if="renderNickname(item)"
      >
        <small>{{ "作成者名" }}</small>
      </v-list-item-title>
      <v-list-item-subtitle class="sh-urbane font-weight-bold">
        {{ renderNickname(item) }}
      </v-list-item-subtitle>

      <v-list-item-title class="sh-urbane font-weight-bold text-uppercase">
        <small>{{ "作成日" }}</small>
      </v-list-item-title>
      <v-list-item-subtitle class="sh-urbane font-weight-bold">
        {{ moment(item.createdAt).tz('Asia/Tokyo').format('YYYY-MM-DD') }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-card>
</template>
<script>
import moment from "moment";

export default {
  props: {
    item: Object,
  },
  data: () => ({}),
  methods: {
    moment(item) {
      return moment(item);
    },
    renderPrefCity(item, type) {
      return type === "pref" ? item?.pref?.name : item?.city?.cityname;
    },
    renderNickname(item) {
      return item?.user?.nickname;
    },
    clickDetailManhole() {
      this.$router.push({ path: `/survey/manhole-info/${this.item.id}` });
    },
  },
};
</script>

<style lang="scss" scoped>
.item-manhole {
  // padding: 9px;
  display: flex;
  margin: 10px 0;
  .v-list-item__subtitle {
    color: rgba(255, 255, 255, 0.7);
    line-height: 1.5;
  }
  .v-list-item__icon {
    margin: 10px;
  }
}
</style>
