<template>
  <v-card dark class="list-post">
    <v-card>
      <v-card-title class="primary--text">
        <div class="list-post_groupButton search-filter mt-3 mb-3">
          <item-survey
            :data="listFilter"
            :value="searchBy"
            :type="'select'"
            :callbackSelect="selectFilter"
            nameData="name"
            valueData="value"
            lightTheme
            :disabled="linkIDmanhole ? true : false"
          ></item-survey>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="検索"
            single-line
            hide-details
            class="mt-0"
            :disabled="linkIDmanhole ? true : false"
          ></v-text-field>
        </div>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <div class="list-post_groupButton">
          <v-btn
            class="mr-2 mt-3 mb-3"
            color="primary dark--text"
            @click="() => $router.push('/admin/create/post')"
            v-if="listTargets.totalItems > 0 && !linkIDmanhole"
            >新規作成</v-btn
          >
          <v-btn
            @click="offHyper"
            v-if="linkIDmanhole"
            color="primary dark--text"
            class="mr-2 mt-3 mb-3"
            >すべて見る</v-btn
          >
          <v-btn
            class="ml-2 mt-3 mb-3"
            color="primary"
            outlined
            @click="exportList()"
            :disabled="selected.length === 0"
            >一覧出力</v-btn
          >
          <v-btn
            class="ml-2 mt-3 mb-3"
            color="primary"
            outlined
            @click="exportList('Shift_JIS')"
            :disabled="selected.length === 0"
            >一覧出力(SJIS)</v-btn
          >
        </div>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :search="search"
        :page="posts.currentPage + 1"
        :pageCount="posts.totalPages"
        :server-items-length="posts.totalItems"
        :items="posts.resources"
        :items-per-page="10"
        :footer-props="{
          'disable-items-per-page': true,
          'disable-pagination': posts.loading,
          'items-per-page-text': '１ページあたりの行数',
          'page-text': '{2}中の{0}-{1}',
        }"
        :options.sync="options"
        :loading="posts.loading"
        show-select
        v-model="selected"
        item-key="survey_id"
        loading-text="読込中です..."
        no-data-text="データがありません"
      >
        <template v-slot:top>
          <v-dialog light v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h6" justify-center
                >本項目を{{
                  itemSwitch.active ? "効無効" : "有効"
                }}にしたいですか？</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >キャンセル</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>

        <template v-slot:[`item.created_at`]="{ item }">
          <span>{{
            moment(item.created_at).tz("Asia/Tokyo").format("YYYY-MM-DD")
          }}</span>
        </template>

        <template v-slot:[`item.updated_at`]="{ item }">
          <span>{{
            moment(item.updated_at).tz("Asia/Tokyo").format("YYYY-MM-DD")
          }}</span>
        </template>

        <template v-slot:[`item.active`]="{ item }">
          <v-switch readonly v-model="item.active" @click="handleChange(item)">
          </v-switch>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon class="mr-2" @click="editItem(item)" title="編集">
            mdi-pencil</v-icon
          >
          <v-icon @click="exportCSV(item.survey_id)" title="エクスポート">
            mdi-file-export
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </v-card>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import _ from "lodash";
import ItemSurvey from "../../components/ItemSurvey.vue";
import { eventBus } from "../../common/eventBus";

export default {
  components: { ItemSurvey },
  data() {
    return {
      search: "",
      options: {},
      itemSwitch: {},
      selected: [],
      headers: [
        {
          text: "マンホールID",
          value: "target_id",
          align: "start",
        },
        {
          text: this.$t('survey.ledgerManholeNo'),
          value: "ledger_manhole_no",
          align: "start",
        },
        {
          text: "投稿ID",
          value: "survey_id",
          align: "start",
        },
        {
          text: this.$t('survey.investigator'),
          value: "investigator",
          align: "start",
        },
        {
          text: "点検バージョン",
          align: "start",
          value: "survey_version",
        },
        {
          text: "場所",
          value: "location",
          align: "start",
          sortable: false,
        },
        {
          text: "作成日",
          value: "created_at",
          align: "start",
        },
        {
          text: "最終更新日",
          value: "updated_at",
          align: "start",
        },
        {
          text: "地図で見る",
          value: "active",
          sortable: false,
        },
        {
          text: "操作",
          value: "actions",
          sortable: false,
          cellClass: "action-column",
        },
      ],
      dialogDelete: false,
      listFilter: [
        {
          name: "投稿ID",
          value: "survey_id",
        },
        {
          name: "マンホールID",
          value: "target_id",
        },
        {
          name: this.$t('survey.ledgerManholeNo'),
          value: "ledger_manhole_no",
        },
        {
          name: "点検バージョン",
          value: "survey_version",
        },
      ],
      searchBy: "survey_id",
    };
  },
  watch: {
    $route() {
      this.selected = [];
      this.options = {
        ...this.options,
        page: 1,
        sortBy: [],
        sortType: [],
        sortDesc: [],
      };
      this.searchBy = "survey_id";
      this.search = "";
    },
    linkIDmanhole() {
      this.viewManholeSurvey();
    },
    options: {
      handler() {
        if (this.isListingPage) this.fetchData();
      },
    },
    search: _.debounce(function () {
      if (this.isListingPage) this.fetchData();
    }, 500),
    deep: true,
    changeStatusPost() {
      if (this.changeStatusPost && this.isListingPage) this.fetchData();
    },
    selected(val, oldVal) {
      if (val.length > 300) {
        this.$nextTick(() => {
          this.selected = oldVal;
        });
        eventBus.$emit("showNotification", {
          active: true,
          type: "error",
          message: "最大300アイテムを選択してください",
        });
      }
    },
  },
  async mounted() {
    this.viewManholeSurvey();
    this.fetchData();
    await this.$store.dispatch("adminPosts/fetchTargets", {
      page: 0,
      sortBy: "id",
      sortType: "DESC",
      searchBy: "id",
    });
  },
  methods: {
    editItem(item) {
      this.$router.push({
        path: `/admin/post/${item.survey_id}/info?type=${item.survey_type}`,
      });
    },

    viewManholeSurvey() {
      if (this.linkIDmanhole) {
        this.searchBy = "target_id";
        this.search = this.linkIDmanhole.toString();
      } else {
        this.searchBy = "survey_id";
        this.search = "";
      }
    },

    offHyper() {
      this.$store.dispatch("adminPosts/nullManhole");
    },

    handleChange(item) {
      this.dialogDelete = true;
      this.itemSwitch = item;
    },

    deleteItemConfirm() {
      this.isListRound
        ? this.$store.dispatch(
            "adminPosts/deleteRoundPost",
            this.itemSwitch.survey_id
          )
        : this.$store.dispatch(
            "adminPosts/deletePost",
            this.itemSwitch.survey_id
          );
      this.closeDelete();
    },

    closeDelete() {
      this.dialogDelete = false;
    },
    moment(item) {
      return moment(item);
    },
    fetchData() {
      const { page, sortBy, sortDesc } = this.options;
      let sortByColumn = sortBy[0] || "id";
      if (sortBy[0]) {
        switch (sortBy[0]) {
          case "survey_id":
            sortByColumn = "id";
            break;
          case "created_at":
            sortByColumn = "createdAt";
            break;
          case "updated_at":
            sortByColumn = "updatedAt";
            break;
          default:
            sortByColumn = sortBy[0];
            break;
        }
      }

      const sortType = !sortDesc[0] ? "DESC" : "ASC";
      let configs = {
        page: page - 1,
        keyword: this.search,
        sortBy: sortByColumn,
        sortType: sortType,
        searchBy: this.searchBy,
      };

      this.isListRound
        ? this.$store.dispatch("adminPosts/fetchRoundPosts", configs)
        : this.$store.dispatch("adminPosts/fetchPosts", configs);
    },
    exportCSV(id) {
      this.isListRound
        ? this.$store.dispatch("adminPosts/exportRoundSurvey", id)
        : this.$store.dispatch("adminPosts/exportSurvey", id);
    },
    exportList(encoding = 'utf-8') {
      const arrSelect = this.selected.map((item) => item.survey_id);
      this.isListRound
        ? this.$store.dispatch("adminPosts/exportRoundSurveys", { ids: arrSelect, encoding: encoding })
        : this.$store.dispatch("adminPosts/exportSurveys", { ids: arrSelect, encoding: encoding });
      this.selected = [];
    },
    selectFilter(e) {
      this.searchBy = e;
      this.fetchData();
    },
  },

  computed: {
    ...mapGetters([
      "posts",
      "roundPosts",
      "changeStatusPost",
      "linkIDmanhole",
      "listTargets",
    ]),
    isListRound() {
      return this.$route.path === "/admin/round";
    },
    isListingPage() {
      return (
        this.$route.path === "/admin/posts" ||
        this.$route.path === "/admin/round"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.list-post {
  ::v-deep .v-data-footer__select {
    .v-text-field {
      input {
        padding: 10px;
      }
    }
    .v-input__append-inner {
      display: none !important;
    }
    .v-input__icon--append {
      display: none !important;
    }
    .v-input__slot::before {
      display: none !important;
    }
  }
  &_groupButton {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    @media (max-width: 414px) {
      width: 100%;
    }
    @media (max-width: 810px) {
      &.search-filter {
        width: 100%;
      }
    }
    ::v-deep .v-text-field {
      input {
        padding: 10px 10px 10px 0;
      }
    }
  }
  ::v-deep .item-box {
    margin-bottom: 0rem;
    --box-shadow: 0px 0px 0px transparent !important;
    margin-right: 1rem;
  }
}
</style>
