<template>
  <div class="item-box d-flex" v-bind:class="classStyle" :style="cssProps">
    <span class="item-box_title align-center pr-5" v-show="title">{{
      title
    }}</span>
    <v-col cols="auto" class="pr-0 pt-0 pb-0" v-if="type === 'array-select'">
      <v-checkbox
        class="item-box_array-select"
        v-for="(checkbox, i) in data"
        :key="i"
        v-model="value.id"
        :label="$t(checkbox.name)"
        :value="checkbox.id"
        @change="selectData"
        hide-details
        color="#27F8F9"
        :rules="checkRules"
      ></v-checkbox>
    </v-col>
    <v-col
      cols="auto"
      class="d-flex pr-0 pt-0 pb-0"
      v-bind:class="!title && 'pl-0'"
      v-if="type === 'select'"
    >
      <v-select
        :items="data"
        :item-text="nameData"
        :item-value="valueData"
        class="item-box_select"
        solo
        :value="(typeof value === 'object' && valueData && valueData in value) ? value[valueData] : value"
        :background-color="lightTheme ? 'transparent' : 'bgGeneral'"
        flat
        height="40"
        hide-details
        @input="selectData"
        outlined
        :disabled="disabled"
      ></v-select>
      <div
        class="item-box_comment"
        v-show="!title && showComment"
        @click="handleClickComment"
      >
        <v-img
          contain
          height="44"
          width="46"
          class=""
          :src="require('@/assets/comment.png')"
        />
        <span class="item-box_comment-quantity" v-show="totalComment > 0">{{
          totalComment > 99 ? "99+" : totalComment
        }}</span>
      </div>
    </v-col>
    <v-col cols="auto" class="pr-0 pb-0 pl-0" v-if="type === 'area'">
      <v-textarea
        dense
        class="item-box_input-area"
        :value="value"
        hide-details="auto"
        solo
        :background-color="lightTheme ? 'transparent' : 'bgGeneral'"
        @input="selectData"
        outlined
        :rules="checkRules"
        :placeholder="placeholder ? placeholder : ''"
        rows="3"
        :disabled="disabled"
      >
      </v-textarea>
    </v-col>
    <v-col cols="auto" class="pr-0 pt-0 pb-0" v-if="type === 'input'">
      <v-text-field
        dense
        class="item-box_input"
        :value="value"
        hide-details="auto"
        solo
        :background-color="lightTheme ? 'transparent' : 'bgGeneral'"
        @input="selectData"
        outlined
        :rules="checkRules"
        :disabled="column === 'investigator' || column === 'disabled'"
      >
      </v-text-field>
    </v-col>
    <v-col
      cols="auto"
      class="pr-0 pt-0 pb-0 position-relative"
      v-if="type === 'input-unit'"
    >
      <v-text-field
        dense
        outlined
        class="item-box_input-unit"
        :value="value"
        hide-details="auto"
        solo
        :background-color="lightTheme ? 'transparent' : 'bgGeneral'"
        @input="selectData"
        :rules="checkRules"
        :suffix="unit"
        :disabled="disabled"
      >
      </v-text-field>
    </v-col>
    <v-row class="item-box_array" v-if="type === 'array'">
      <v-col
        cols="6"
        class="pl-0 position-relative"
        v-for="(item, i) in value"
        :key="i"
      >
        <v-text-field
          dense
          outlined
          class="item-box_input-unit"
          v-model="value[i]"
          hide-details="auto"
          solo
          :background-color="lightTheme ? 'transparent' : 'bgGeneral'"
          @input="selectData"
          :rules="checkRules"
          :suffix="i === 1 || i === 3 ? $t('survey.numberUnit') : 'cm'"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-col
      v-if="type === 'date'"
      class="pr-0 pt-0 pb-0 d-flex flex-wrap justify-end align-end"
    >
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-left="162"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :value="dateFormat"
            readonly
            v-bind="attrs"
            v-on="on"
            hide-details="auto"
            class="item-box_date"
            dense
            outlined
            solo
            :background-color="lightTheme ? 'transparent' : 'bgGeneral'"
            :disabled="disabled"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="value.id"
          @input="selectData"
          color="bgGeneral lighten-1"
          :locale="language"
          :max="
            new Date(
              Date.now() - new Date().getTimezoneOffset() * 60000
            ).toISOString()
          "
        ></v-date-picker>
      </v-menu>
      <v-menu
        ref="menu"
        v-model="menu2"
        :close-on-content-click="false"
        :return-value.sync="time"
        :nudge-left="228"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-show="column === 'water_depth_time'"
            :value="time"
            readonly
            v-bind="attrs"
            v-on="on"
            hide-details="auto"
            class="item-box_time ml-3"
            dense
            outlined
            solo
            :background-color="lightTheme ? 'transparent' : 'bgGeneral'"
            :disabled="disabled"
          ></v-text-field>
        </template>
        <v-time-picker
          v-model="time"
          @input="selectData"
          color="bgGeneral lighten-1"
          :locale="language"
          @click:minute="$refs.menu.save(time)"
        ></v-time-picker>
      </v-menu>
      <v-btn
        v-if="column === 'water_depth_time'"
        class="ml-3 mt-1 mb-1"
        color="primary"
        outlined
        @click="setCurrentTime"
        :disabled="disabled"
      >現在日時をセット</v-btn>
      <v-btn
        v-if="column === 'water_depth_time'"
        class="ml-3 mt-1 mb-1"
        color="primary"
        outlined
        @click="resetTime"
        :disabled="disabled"
      >リセット</v-btn>
    </v-col>
  </div>
</template>

<script>
import i18n from "../i18n";
import moment from "moment";
import momentTZ from "moment-timezone";

export default {
  props: {
    title: String,
    type: String,
    data: Array,
    value: null,
    nameData: null,
    valueData: String,
    column: String,
    callbackSelect: Function,
    unit: String,
    rules: null,
    placeholder: String,
    handleClickComment: {
      type: Function,
      default: () => {},
    },
    totalComment: Number,
    showComment: Boolean,
    lightTheme: Boolean,
    disabled: Boolean
  },
  data() {
    return {
      menu: false,
      menu2: false,
      language: "",
      time: "00:00",
    };
  },
  mounted() {
    this.language = i18n.locale;
    if (this.column === "water_depth_time") {
      this.time = this.value.id ? moment(this.value.id).format("HH:mm") : null;
    }
  },
  methods: {
    selectData(e) {
      if (this.callbackSelect) {
        this.callbackSelect(e, this.column, this.value);
      }
      this.menu = false;
      let versionName;
      if (this.column === "inspection_date") {
        versionName = {
          column: "survey_version",
          value: `点検日 ${moment(e).locale("ja").format("ll")}`,
        };
        this.$store.dispatch("survey/changeForm", versionName);
      }
      if (this.isManholeInfo) {
        this.type === "array"
          ? this.$store.dispatch("instantPost/changeForm", {
              column: this.column,
              value: this.value.toString(),
            })
          : this.$store.dispatch("instantPost/changeForm", {
              column: this.column,
              value:
                this.type === "array-select"
                  ? e.toString()
                  : e === ""
                  ? null
                  : e,
            });
      } else {
        this.column &&
          this.$store.dispatch("survey/changeForm", {
            column: this.column,
            value:
              this.column === "water_depth_time"
                ? momentTZ
                    .tz(
                      `${moment(this.value.id).format("YYYY-MM-DD")} ${
                        this.time
                      }`,
                      "Asia/Tokyo"
                    )
                    .toISOString()
                : e,
          });
      }
    },
    range(min, max) {
      return [
        (v) =>
          v?.length === 0 ||
          (/^[0-9]*$/.test(v) && v <= max && v >= min) ||
          this.$t("survey.rangeMinMax", { max: max, min: min }),
      ];
    },
    text(max) {
      return [
        (v) => v?.length <= max || this.$t("survey.textMax", { max: max }),
      ];
    },
    float(scale = null) {
      return (+scale === 0)
        ? [(v) => /^[0-9,.]*$/.test(v) || "Only float are allowed."]
        : [(v) => (new RegExp(`^[0-9]*([.][0-9]{0,${+scale}})?$`)).test(v)
           || this.$t('survey.textFloatScale', { scale: +scale} )];
    },
    setCurrentTime(e) {
      const currentDatetime = moment();
      this.value.id = currentDatetime.format('YYYY-MM-DD HH:mm');
      this.time = currentDatetime.format('HH:mm');
      this.selectData(e);
    },
    resetTime(e) {
      this.value.id = null;
      this.time = null;
      this.selectData(e);
    },
  },
  computed: {
    checkRules() {
      const range0to10 = this.range(0, 10);
      const range1900to2022 = this.range(1900, 2022);
      const range1950to2050 = this.range(1950, 2050);
      const range0to10000 = this.range(0, 10000);
      const text20 = this.text(20);
      const text50 = this.text(50);
      const text200 = this.text(200);
      let finalRule = [];
      switch (this.rules) {
        case "interger":
          finalRule = this.interger;
          break;
        case "float":
          finalRule = this.float();
          break;
        case "float1":
          finalRule = this.float(1);
          break;
        case "float2":
          finalRule = this.float(2);
          break;
        case "range0to10":
          finalRule = range0to10;
          break;
        case "range1900to2022":
          finalRule = range1900to2022;
          break;
        case "range1950to2050":
          finalRule = range1950to2050;
          break;
        case "range0to10000":
          finalRule = range0to10000;
          break;
        case "text20":
          finalRule = text20;
          break;
        case "text50":
          finalRule = text50;
          break;
        case "text200":
          finalRule = text200;
          break;
        default:
          finalRule = [];
      }
      return finalRule;
    },
    interger() {
      return [(v) => /^[0-9]*$/.test(v) || this.$t('survey.interger')];
    },
    dateFormat() {
      return this.value.id
        ? moment(this.value.id).locale(this.language).format("ll")
        : "";
    },
    timeFormat() {
      return this.value.id
        ? moment(this.value.id).locale(this.language).format("hh:ss")
        : "";
    },
    isManholeInfo() {
      return this.$route.name === "Manhole Info" || this.$route.name === "マンホール詳細" || this.$route.name === "User Manhole";
    },
    classStyle() {
      let style;
      switch (this.type) {
        case "area":
          style = "flex-column align-start";
          break;
        case "array":
        case "array-select":
          style = "justify-space-between align-start";
          break;
        case "select":
          style = this.title
            ? "justify-space-between align-center"
            : "justify-center align-center";
          break;
        default:
          style = "justify-space-between align-center";
          break;
      }
      return style;
    },
    cssProps() {
      return {
        "--border": "#27f8f9",
        "--box-shadow": this.lightTheme
          ? "0px 10px 20px black"
          : "0px 10px 20px #081539",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.item-box {
  margin-bottom: 20px;
  font-size: 13px;

  &_title {
    display: flex;
    font-size: 16px;
    min-height: 40px;
  }

  &_select,
  &_input,
  &_input-unit,
  &_date,
  &_time {
    ::v-deep .v-input__slot {
      min-height: 40px !important;
      margin-right: 0;
      margin-left: auto;
    }

    ::v-deep .v-messages {
      text-align: right;
      max-width: 60vw;
      &__message {
        line-height: 1.3;
      }
    }

    ::v-deep .v-text-field__details {
      padding-right: 0;
    }
  }

  &_comment {
    position: relative;
    margin-left: 14px;
    cursor: pointer;
    &-quantity {
      background-color: #d5482f;
      position: absolute;
      padding: 1px 4px;
      text-align: center;
      font-size: 8px;
      border-radius: 100%;
      top: 2px;
      right: -4px;
    }
  }

  &_input-area {
    width: 100vw;
    ::v-deep .v-messages {
      text-align: right;
      max-width: 100vw;
      &__message {
        line-height: 1.3;
      }
    }
    ::v-deep .v-text-field__details {
      padding-right: 0;
    }
    ::-webkit-scrollbar {
      display: none;
    }
  }

  &_select {
    max-width: 45vw;
  }

  &_date {
    max-width: 160px;
  }

  &_time {
    max-width: 70px;
  }

  &_input {
    ::v-deep .v-input__slot {
      max-width: 160px;
    }
  }

  &_input-unit {
    ::v-deep .v-input__slot {
      max-width: 100px;
    }
  }

  &_array {
    max-width: 185px;
    &-select {
      margin-right: 20px;
      ::v-deep .v-label {
        font-size: 13px;
      }
    }
  }
}

::v-deep .v-text-field.v-text-field--solo .v-input__control {
  min-height: 40px;
  font-size: 16px;
}

::v-deep .v-select__selections input {
  display: none;
}

::v-deep .v-select__selection {
  max-width: 100%;
}

.v-text-field--outlined {
  ::v-deep fieldset {
    border-color: var(--border);
    box-shadow: var(--box-shadow);
  }
}

::v-deep .v-text-field__slot input {
  text-align: right;
}

::v-deep .mdi-checkbox-blank-outline::before {
  color: #27f8f9;
}

::v-deep .v-input--selection-controls {
  margin-top: 8px;
}

.sample {
  height: 10px;
  width: 100px;
  background-color: red;
  float: right;
  position: relative;
}
</style>
