import game from "@/game"
import { eventBus } from '../../common/eventBus.js';
import router from '../../router'

const state = {
  posts: {
    loading: false,
    currentPage: 0,
    resources: [],
    totalItems: 0,
    totalPages: 0
  },
  roundPosts: {
    loading: false,
    currentPage: 0,
    resources: [],
    totalItems: 0,
    totalPages: 0
  },
  adminTargets: {
    loading: true,
    currentPage: 0,
    resources: [],
    totalItems: 0,
    totalPages: 0
  },
  listTargets: {
    loading: true,
    currentPage: 0,
    resources: [],
    totalItems: 0,
    totalPages: 0
  },
  post: {},
  changeStatusPost: false,
  linkIDmanhole: null
};

const mutations = {
  FETCH_POSTS: (state, payload) => {
    state.posts = {
      ...state.posts,
      ...payload,
      loading: false
    }
    state.changeStatusPost = false
  },

  FETCH_ROUND: (state, payload) => {
    state.roundPosts = {
      ...state.roundPosts,
      ...payload,
      loading: false
    }
    state.posts = {
      ...state.posts,
      ...payload,
      loading: false
    }
    state.changeStatusPost = false
  },

  FETCH_LOADING: (state) => {
    state.posts = {
      ...state.posts,
      loading: true
    }
    state.roundPosts = {
      ...state.roundPosts,
      loading: true
    }
    state.adminTargets = {
      ...state.adminTargets,
      loading: true
    }
    state.listTargets = {
      ...state.listTargets,
      loading: true
    }
  },

  FETCH_STATUS: (state) => {
    state.changeStatusPost = false
  },

  DELETE_POSTS: (state) => {
    state.changeStatusPost = true
  },

  FETCH_TARGETS: (state, payload) => {
    const addLabel = (arr) => {
      arr.map(item => {
        item.label = `${item.id}`
        return item
      })
      return arr
    }
    if (payload.listManhole) {
      state.adminTargets = {
        ...state.adminTargets,
        ...payload,
        loading: false,
      }
    } else {
      state.listTargets = {
        ...state.listTargets,
        ...payload,
        loading: false,
        resources: addLabel([...state.listTargets.resources, ...payload.resources])
      }
    }
  },

  LINK_MANHOLE: (state, payload) => {
    state.linkIDmanhole = payload
    router.push(`/admin/posts`)
  },

  NULL_MANHOLE: (state) => {
    state.linkIDmanhole = null
  }
};

const actions = {
  fetchPosts({ commit }, configs) {
    commit("FETCH_LOADING");
    game.getAdminSurveys(configs, function (res) {
      if (res.code === 200 && res.data) {
        commit("FETCH_POSTS", res.data);
      }
    })
  },

  fetchRoundPosts({ commit }, configs) {
    commit("FETCH_LOADING");
    game.getAdminRoundSurveys(configs, function (res) {
      if (res.code === 200 && res.data) {
        commit("FETCH_ROUND", res.data);
      }
    })
  },

  exportSurvey(_, id) {
    game.exportSurvey(id, function (res) {
      if (res) {
        eventBus.$emit("showNotification", {
          active: true,
          type: "error",
          message: 'This survey post is null.',
        });
      }
    })
  },

  exportRoundSurvey(_, id) {
    game.exportRoundSurvey(id, function (res) {
      if (res) {
        eventBus.$emit("showNotification", {
          active: true,
          type: "error",
          message: 'This survey post is null.',
        });
      }
    })
  },

  exportSurveys(_, req) {
    const { encoding, ...idList } = req;
    game.exportSurveys(idList, encoding || 'utf-8', function (res) {
      if (res) {
        eventBus.$emit("showNotification", {
          active: true,
          type: "error",
          message: 'Failed to export survey list.',
        });
      }
    })
  },

  exportRoundSurveys(_, req) {
    const { encoding, ...idList } = req;
    game.exportRoundSurveys(idList, encoding || 'utf-8', function (res) {
      if (res) {
        eventBus.$emit("showNotification", {
          active: true,
          type: "error",
          message: 'Failed to export round survey list.',
        });
      }
    })
  },

  deletePost({ commit }, id) {
    commit("FETCH_STATUS");
    return game.deleteAdminSurvey(id, function (res) {
      if (res.code === 200 && res.data) {
        commit("DELETE_POSTS", res);
      }
    })
  },

  deleteRoundPost({ commit }, id) {
    commit("FETCH_STATUS");
    return game.deleteRoundSurvey(id, function (res) {
      if (res.code === 200 && res.data) {
        commit("DELETE_POSTS", res);
      }
    })
  },

  fetchTargets({ commit }, configs) {
    commit("FETCH_LOADING");
    game.getAllTargetsAdmin(configs, function (res) {
      if (res.code === 200 && res.data) {
        commit("FETCH_TARGETS", { ...res.data, ...configs });
      }
    })
  },

  linkManhole({commit}, id) {
    commit("LINK_MANHOLE", id);
  },

  nullManhole({commit}) {
    commit("NULL_MANHOLE");
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
