<template>
  <v-container class="full-mobile manhole">
    <Header title="マンホール詳細" backHome />
    <v-card-text class="d-flex justify-center align-center pb-0 pt-6">
      <span class="">{{$t('survey.manholeID')}}</span>
      <span disabled class="primary--text ml-3" height="40">{{ target.id }}</span>
    </v-card-text>
    <v-card-text class="d-flex justify-center align-center pb-5 pt-0">
      <span class="">{{$t('survey.ledgerManholeNo')}}</span>
      <span disabled class="primary--text ml-3" height="40">{{ target.ledger_manhole_no }}</span>
    </v-card-text>
    <manhole-info :skipSurveyCountCheck="true" />
  </v-container>
</template>
<script>
import Header from "../../components/Header.vue";
import ManholeInfo from "../survey/ManholeInfo.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    Header,
    ManholeInfo,
  },
  async mounted() {
    await this.$store.dispatch("instantPost/getTargetById", this.$route.params.id);
  },
  computed: {
    ...mapGetters(["target"]),
  },
};
</script>
