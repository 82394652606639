import Vue from 'vue';
import VueI18n from 'vue-i18n'
import en from "./locales/en/translation.json"
import ja from "./locales/ja/translation.json"

Vue.use(VueI18n);

const messages = {
  en,
  ja
};

const language = 'ja'

const i18n = new VueI18n({
  locale: language,
  messages,
  silentTranslationWarn: true
});

export default i18n;
