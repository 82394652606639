<template>
  <div class="item-box d-flex" :style="cssProps">
    <span class="item-box_title align-center pr-5" v-show="title">{{
      title
    }}</span>
    <v-col cols="auto" class="pr-0 pt-0 pb-0" v-if="type === 'input'">
      <v-text-field
        dense
        class="item-box_input"
        :value="value"
        hide-details="auto"
        solo
        background-color="transparent"
        outlined
        :placeholder="placeholder"
        :rules="rules"
        flat
        @input="changeData"
      >
      </v-text-field>
    </v-col>
     <v-col
      cols="auto"
      class="d-flex pr-0 pt-0 pb-0"
      v-bind:class="!title && 'pl-0'"
      v-if="type === 'select'"
    >
      <v-select
        :items="data"
        :item-text="nameData"
        :item-value="valueData"
        class="item-box_select"
        solo
        :value="value"
        background-color="transparent"
        flat
        height="40"
        hide-details
        @input="selectData"
        outlined
      >
        <template v-slot:append-item>
          <div v-intersect="endIntersect" />
        </template>
      </v-select>
    </v-col>
    <v-col cols="auto" class="pr-0 pt-0 pb-0" v-if="type === 'password'">
      <v-text-field
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="rules"
        :type="showPassword ? 'text' : 'password'"
        name="input-10-1"
        flat
        solo
        outlined
        @click:append="showPassword = !showPassword"
        dense
        class="item-box_input"
        :value="value"
        hide-details="auto"
        background-color="transparent"
        :placeholder="placeholder"
        @input="changeData"
      ></v-text-field>
    </v-col>
  </div>
  
</template>

<script>

export default {
  props: {
    title: String,
    value: null,
    rules: null,
    placeholder: String,
    type: String,
    data: Array,
    nameData: null,
    valueData: String,
    callbackSelect: Function,
    changeData: Function,
    endIntersect: Function
},
  components: { 
  },
  data() {
    return {
      showPassword: false
    };
  },
  mounted() {},
  methods: {
    selectData(e) {
      if (this.callbackSelect) {
        this.callbackSelect(e, this.column, this.value);
      }
    },
  },
  computed: {
    cssProps() {
      return {
        "--border": "#27f8f9",
        "--box-shadow": "0px 10px 20px black"
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.item-box {
  margin-bottom: 20px;
  font-size: 13px;
  justify-content: space-between !important;
  &_title {
    display: flex;
    font-size: 12px;
    min-height: 40px;
  }
  &_select,
  &_input{
    ::v-deep .v-input__slot {
      min-height: 40px !important;
      margin-right: 0;
      margin-left: auto;
    }

    ::v-deep .v-messages {
      text-align: right;
      max-width: 60vw;
      &__message {
        line-height: 1.3;
      }
    }

    ::v-deep .v-text-field__details {
      padding-right: 0;
    }
  }

  &_input {
    ::v-deep .v-input__slot {
      max-width: 98px;
      button::before{
        font-size: 1.2rem;
      }
    }
  }

  &_select {
    max-width: 45vw;
  }
}

::v-deep .v-text-field.v-text-field--solo .v-input__control {
  min-height: 40px;
  font-size: 13px;
}

::v-deep .v-select__selections input {
  display: none;
}

::v-deep .v-select__selection {
  max-width: 100%;
}

.v-text-field--outlined {
  ::v-deep fieldset {
    border-color: var(--border);
    box-shadow: var(--box-shadow);
  }
}

::v-deep .v-text-field__slot input {
  text-align: right;
}

</style>

