<template>
  <v-navigation-drawer
    v-model="toggleMenu"
    absolute
    temporary
    class="drawer pt-16"
    width="220"
    @input="setDrawer"
  >
    <div class="app-logo mt-2 mb-3 ml-6">
      <v-avatar color="primary" size="56">
        <v-img
          alt="user avatar"
          class="shrink"
          contain
          :src="this.currentUser.avatar_url || avatarDefault"
          transition="scale-transition"
          width="48"
          height="48"
        />
      </v-avatar>
      <h5 class="pl-5 mt-3 mb-3">{{currentUser.nickname || '田中大智'}}</h5>
    </div>
    <v-list
      dense
      nav
      class="pl-3"
      v-if="currentUser.group && currentUser.group.id ? true : false"
    >
      <v-list-item
        link
        :to="`/group/feed/${currentUser.group && currentUser.group.id}`"
      >
        <v-list-item-icon class="mt-2 mb-2 mr-0 gift">
          <v-icon size="16" color="gray">mdi-gift-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="pt-3 pb-3 text-uppercase">
          <v-list-item-title>{{
            currentUser.group && currentUser.group.name
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-list dense nav class="mt-auto mb-5 pl-3">
      <!-- <v-list-item>
        <v-list-item-icon class="mt-2 mb-2 mr-0 gift">
          <v-icon size="16" color="gray">mdi-gift-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content
          class="pt-3 pb-3 text-uppercase"
          @click="changeLanguage"
        >
          <v-list-item-title>{{ $t("sideMenu.language") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->
      <v-list-item link to="/user/profile">
        <v-list-item-icon class="mt-2 mb-2 mr-0 gift">
          <v-icon size="16" color="gray">mdi-gift-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="pt-3 pb-3 text-uppercase">
          <v-list-item-title>{{
            $t("sideMenu.userSetting")
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="logout">
        <v-list-item-icon class="mt-2 mb-2 mr-0 gift">
          <v-icon size="16" color="gray">mdi-gift-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="pt-3 pb-3 text-uppercase">
          <v-list-item-title>{{ $t("sideMenu.logOut") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <language-option v-model="displayChangeLanguage"></language-option>

  </v-navigation-drawer>
</template>

<script>
import LanguageOption from "./LanguageOption.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    LanguageOption,
  },
  data: () => {
    return {
      drawer: false,
      avatarDefault: require("@/assets/guadians-thumb.jpg"),
      displayChangeLanguage: false,
    };
  },
  computed: {
    ...mapGetters(["currentUser", "toggleMenu"]),
    toggleMenu: {
      get: function () {
        return this.$store.state.global.toggleMenu
      },
      set: function () {
      }
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("authen/signOut");
    },
    changeLanguage() {
      this.displayChangeLanguage = true;
      this.$store.dispatch("global/toggleMenuAction", false);
    },
    disableGroup() {
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i].link.includes("/groups")) {
          this.items[i].disabled = true;
        }
      }
    },
    setDrawer(e) {
      this.$store.dispatch("global/toggleMenuAction", e);
    }
  },
  watch: {
  },

  created() {
    this.$store.dispatch("global/toggleMenuAction", false);
  },
};
</script>

<style lang="scss">
.menu-heading {
  font-size: 13px;
  font-weight: bold;
  color: #fff;
  opacity: 0.5;
}
.app-logo {
  display: flex;
  align-items: center;
}
.drawer.v-navigation-drawer--temporary {
  position: fixed;
  height: auto;
  top: 0;
  bottom: 0;
}
.v-overlay__scrim {
  position: fixed !important;
}
.drawer {
  .v-navigation-drawer__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
.drawer.v-navigation-drawer--temporary {
  z-index: 1021;
}
.v-list-item {
  cursor: pointer;
}
.v-list-item__icon.gift {
  height: 18px !important;
}
.v-avatar {
  .v-image__image {
    background-size: cover;
  }
}
</style>
