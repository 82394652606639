<template>
    <v-dialog
        v-model="displayLoader"
        persistent
        width="300"
    >
        <v-card
            color="dark"
            dark
        >
            <v-card-text class="pt-3">
                {{ $t('loader.sending') }}
                <v-progress-linear
                    indeterminate
                    color="primary"
                    class="mb-0"
                ></v-progress-linear>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    props: {
        value: Boolean,
    },
    data: () => ({     }),
    computed: {
        displayLoader: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            }
        }
    }
}
</script>
<style lang="scss" scoped></style>