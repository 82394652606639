<template>
  <h1>Setting</h1>
</template>
<script>

export default {
  components: {},
  data: () => ({
   
  })
};
</script>
<style lang="scss" scopped></style>