import game from "@/game"
import _ from 'lodash';
import store from "@/store";
import router from "../../router";
import { eventBus } from '../../common/eventBus.js';
import i18n from '../../i18n.js';

const state = {
  users: {
    loading: true,
    currentPage: 1,
    resources: [],
    totalItems: 0,
    totalPages: 0
  },
  user: {},
  resetFormCreateUser: false, 
  prefectures: {
    currentPage: 0,
    resources: [],
    totalItems: 0,
    totalPages: 0,
  },
  cities: {
    currentPage: 0,
    resources: [],
    totalItems: 0,
    totalPages: 0,
    prefId: '',
    
  }
};

const mutations = {
  FETCH_USERS: (state, payload) => {
    state.users = {...payload, currentPage: payload.currentPage + 1}
  },

  CHANGE_ACTIVE_SUCCESS: (state, payload) => {
    state.users.resources = _.map(state.users.resources, (user) => {
      return user.id === payload.id ? {...user, active: payload.active} : user
    })
  },

  CHANGE_DETAIL_ACTIVE_SUCCESS: (state, payload) => {
    state.user = {...state.user, active: payload.active}
  },

  GET_USER_SUCCESS: (state, payload) => {
    state.user = payload
  },

  CREATE_USER_SUCCESS: () => {
    state.resetFormCreateUser = true
  },

  TOGGLE_RESET_FORM: (state, payload) => {
    state.resetFormCreateUser = payload
  },

  GET_PREFECTURES: (state, payload) => {
    state.prefectures = {
      ...payload,
      resources: state.prefectures.resources.concat(payload.resources)
    }
  },

  GET_CITIES: (state, payload) => {
    state.cities = {
      ...payload,
      resources: payload.prefId === state.cities.prefId ? state.cities.resources.concat(payload.resources) : payload.resources,
    }
  },

  REFRESH_DATA: (state) => {
    state.users = {
      loading: true,
      currentPage: 1,
      resources: [],
      totalItems: 0,
      totalPages: 0
    }
  },

  ADD_PREF_CITY: (state, payload) => {
    const dataPref = {
      pref: payload.pref.id,
      name: payload.pref.name
    }
    const dataCity = {
      id: payload?.city?.id || "",
      name: payload?.city?.cityname || ""
    }
    state.prefectures.resources.push(dataPref)
    state.cities.resources.push(dataCity)
    state.cities = {...state.cities, prefId: payload.pref.id}
  }
};

const actions = {
  fetchUsers({ commit }, configs) {
    game.getUsers(configs, function(res) {
      if(res.code === 200 && res.data) {
        commit("FETCH_USERS", res.data);
      }
    })
  },

  changeUserActive({ commit }, id) {
    game.changeActiveUser(id, function(res) {
      if(res.code === 200 && res.data) {
        commit("CHANGE_ACTIVE_SUCCESS", res.data?.user);
      }
    })
  },

  changeUserDetailActive({ commit }, id) {
    game.changeActiveUser(id, function(res) {
      if(res.code === 200 && res.data) {
        commit("CHANGE_DETAIL_ACTIVE_SUCCESS", res.data?.user);
      }
    })
  },

  getUser({ commit }, id) {
    return game.getUserDetail(id, function(res) {
      switch(res.message_key){
        case 'ok':
          commit("GET_USER_SUCCESS", res.data?.user);
          break;
        case 'not_found':
          eventBus.$emit("showNotification", {
            active: true,
            type: "error",
            message: "Not found",
          });
          router.push('/*').catch(()=>{});
          break;
        default:
          break;
      }
    })
  },

  createNewUser({ commit }, user) {
    let configs = {
      page: 0,
      keyword: null,
      limit: 10,
      sortType: 'ASC' 
    } 
    game.createUser(user, function(res) {
      switch(res.message_key){
        case 'ok':
          commit("CREATE_USER_SUCCESS", res.data?.user);
          if(res?.data?.user) router.push({path: `user/${res?.data?.user?.id}`})
          store.dispatch("adminUsers/fetchUsers", configs)
          break;
        case 'already_exist':
          eventBus.$emit("showNotification", {
            active: true,
            type: "error",
            message: i18n.t('signUp.emailRegistered'),
          });
          commit("TOGGLE_RESET_FORM", false);
          break;
        case 'waiting_confirm':
          eventBus.$emit("showNotification", {
            active: true,
            type: "error",
            message: i18n.t('signUp.emailRegistered'),
          });
          commit("TOGGLE_RESET_FORM", false);
          break;
        default: 
          commit("TOGGLE_RESET_FORM", false);
          break;
      }
    })
  },

  toggleResetForm({commit}, value) {
    commit("TOGGLE_RESET_FORM", value);
  },

  getPrefectures({commit}, page) {
    return game.getPrefectures(page, function(res) {
      switch(res.code){
        case 200:
          commit("GET_PREFECTURES", res.data)
          break;
        default: 
          break;
      }
    })
  },

  getCities({commit}, data) {
    return game.getCities(data, function(res) {
      switch(res.code){
        case 200:
          commit("GET_CITIES", {
            ...res.data,
            prefId: data.id
          })
          break;
        default: 
          break;
      }
    })
  },

  refreshDataUsers({commit}) {
    commit("REFRESH_DATA");
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};