import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import VueCookie from 'vue-cookie'
import VueGtag from "vue-gtag"
import VueGtm from '@gtm-support/vue2-gtm';
import i18n from './i18n.js'
import store from "./store";
import fullHeightElm from './common/fullHeightElm'
import moment from 'moment'
import axios from 'axios';
import { eventBus } from "./common/eventBus";
import VueSocialSharing from 'vue-social-sharing'

Vue.use(VueCookie);
Vue.use(VueGtag, {
  config: { id: "G-7MTWH8RMDK" }
});

Vue.use(VueGtm, {
  id: 'GTM-TFQ8SFP',
});

import '/src/sass/custom.scss';
Vue.prototype.moment = moment
Vue.config.productionTip = false
Vue.use(VueSocialSharing);

const showNotification = (responseData) => {
  // Do nothing if object doesn't have following keys.
  if (
    !Object.prototype.hasOwnProperty.call(responseData, 'message_key') ||
    !Object.prototype.hasOwnProperty.call(responseData, 'code') ||
    !Object.prototype.hasOwnProperty.call(responseData, 'snack')
  ) {
    return;
  }
  // Do nothing if snack key is false.
  if (!responseData.snack) {
    return;
  }
  // Add conditions here if you don't want to display particular notification
  // in particular pages.
  if ((router.currentRoute.path === '/accounts/signin' && responseData.message_key === 'not_authenticate')
  || router.currentRoute.path.includes('/survey/contents') || 
  (router.currentRoute.path.includes('/survey/photos') && (responseData.code === 404 || responseData.message_key === 'cannot_read_properties_of_undefined_reading_id_'))
  || (router.currentRoute.path.includes('/*')) || router.currentRoute.path.includes('/admin/create/post')
  ) {
    // Don't display 'No login' notification in sign in page.
    return;
  }
  // Change snackbar's type according to the code key (same as http status code).
  const snackType = responseData.code === 200 ? 'success' : 'error';
  const message = i18n.t(`messageNotification.${responseData.message_key}`);
  eventBus.$emit('showNotification', {
    active: true,
    type: snackType,
    message: message,
  });
};

axios.interceptors.response.use(
  response => {
    // showNotification(response.data);
    return response;
  },
  error => {
    switch(error.response.data.error) {
      case "not_authenticate": 
        router.push({ path: '/accounts/signin' }).catch(()=>{});
        break;
      case "access_denied": 
        router.push('/*').catch(()=>{});
        break;
      default: 
    }
    showNotification(error.response.data);
    return Promise.reject(error);
  }
);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  fullHeightElm,
  render: h => h(App)
}).$mount('#app')
