<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="12" md="4">
                <v-card flat color="dark">
                    
                    <v-card-text class="pb-0 text-center">
                        <v-img
                            alt="user avatar"
                            class="shrink ma-auto"
                            contain
                            :src="require('../assets/logo_pro.png')"
                            transition="scale-transition"
                            width="250"
                            height="100%"
                        />
                    </v-card-text>

                    <v-card-text class="pb-0 content">
                        <v-card-title class="pb-4 primary--text subtitle-2 text-uppercase font-weight-black justify-center d-block text-center">
                          {{ $t('signIn.signInButton')}}
                        </v-card-title>

                        <v-form ref="form" lazy-validation>
                            <v-text-field
                                :persistent-placeholder="autofillFix"
                                :rules= emailRules
                                autocomplete="off"
                                background-color="#3d4a6c"
                                class="mb-2 sh-textfield" 
                                dense
                                hide-details="auto"
                                :label="emailText" 
                                outlined
                                v-model="email"
                                @focus="autofillFix = false"
                            >
                            </v-text-field>
                            <v-text-field 
                                :persistent-placeholder="autofillFix"
                                :label="passwordText" 
                                type="password"
                                outlined
                                dense 
                                background-color="#3d4a6c"
                                info
                                class="mb-4 sh-textfield pb-1"
                                v-model="password"
                                autocomplete="off"
                                hide-details= "auto"
                                :rules= passwordRules
                                @focus="autofillFix = false"
                            >
                            </v-text-field>
                            <v-btn
                                block
                                depressed
                                color="primary black--text"
                                v-on:click="onSigninButton()"
                                :disabled="!isNotValid"
                            >
                                {{ $t('signIn.signInButton')}}
                            </v-btn>
                            <!-- <v-btn block depressed color="primary black--text" to="/accounts/signup">
                                {{ $t('signIn.createAccount')}}
                            </v-btn> -->
                            <v-btn block depressed text color="black--text" to="/accounts/resetpassword">
                                {{ $t('signIn.resetPassword')}}
                            </v-btn>
                          </v-form>
                      </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  watch: {
    language: {
      deep: true,
      handler() {
        this.$refs.form.reset()
      }
    }
  },
methods: {
    onSigninButton(){
        this.$store.dispatch("authen/signIn", {email: this.email, password: this.password})
    },
    onTwitter() {
        this.$router.push({path:"/twsg"});        
    },
    onLine() {
        this.$router.push({path:"/linesg"});
    },
    onWEE() { 
        this.$router.push({path:"/weesg"});
    },
    autoLoginCheckingInterface() {
        // each 100ms we check if the issue was produced
        let intervalDetectAutofill = setInterval(() => {
        if (
            // we target at least one of the stuff that will be affected by autofill
            // to do our checking
            document.querySelectorAll('input[type="password"]:-webkit-autofill')
            .length > 0
        ) {
            // and we inform the system about the issue if it is produced
            this.autofillFix = true

            // we stop to check if issue was produced
            clearInterval(intervalDetectAutofill)
        }
        }, 100)

        // if after 3s nothing appear, means no autofill was made
        setTimeout(() => {
            if (intervalDetectAutofill) {
                clearInterval(intervalDetectAutofill)
                intervalDetectAutofill = null
            }
        }, 3000)
    },
},
mounted: function() {
    this.$store.dispatch("authen/checkCookieSignIn")
    this.autoLoginCheckingInterface()
},
data: function(){                                                             
    return{                                                                     
        email: "",
        password: "",
        autofillFix: null
    }                                                                           
},
    computed: {
      ...mapGetters(["language"]),
        emailRules() {
            return [
                v => !!v || this.$t('signIn.emailAddressRequired'),
                v => /.+@.+\..+/.test(v) || this.$t('signIn.validEmailAddress'),
            ]
        },
        passwordRules() {
            return [
                v => !!v || this.$t('signIn.passwordRequired'),
            ]
        },
        isNotValid() {
            return (
                this.email,
                this.password
            )
        },
        emailText() {
            return this.$t('signIn.email')
        },
        passwordText() {
            return this.$t('signIn.password')
        },
    }
}
</script>
<style lang="scss" scoped>
.container { padding-bottom: 0; }
.v-card__text { padding-bottom: 0; }
.v-btn{
  margin-bottom: 2px;
}
.v-text-field--outlined ::v-deep fieldset{
  border-color: #81ffff;
}
.content{
  margin-top: 1.5rem;
  @media (max-width: 576px) {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
</style>