<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="4">
        <v-card flat color="dark">
          <v-card-text class="pb-2 text-center">
            <router-link to="/">
              <v-avatar color="dark" size="160" tile>
                <v-img
                  alt="user avatar"
                  class="shrink"
                  contain
                  :src="require('../assets/shibuya-brand.svg')"
                  transition="scale-transition"
                  width="160"
                  height="140"
                />
              </v-avatar>
            </router-link>
          </v-card-text>
          <v-card-text v-if="checkSignup">
            <v-card-title
              class="pb-4 primary--text subtitle-2 text-uppercase font-weight-black justify-center"
            >
              {{ $t('waitingConfirm.checkSignup.title') }}
            </v-card-title>

            <v-card-text class="pb-0">
              {{ $t('waitingConfirm.checkSignup.content1') }}
            </v-card-text>

            <v-card-text class="pb-0 white-space-wrap" v-html="$t('waitingConfirm.checkSignup.content2')">
            </v-card-text>
          </v-card-text>
          <v-card-text v-if="checkVerifyEmail">
            <v-card-title
              class="pb-4 primary--text subtitle-2 text-uppercase font-weight-black justify-center"
            >
              {{ $t('waitingConfirm.checkVerifyEmail.title') }}
            </v-card-title>

            <v-card-text class="pb-0">
              {{ $t('waitingConfirm.checkVerifyEmail.content1') }}
            </v-card-text>

            <v-card-text class="pb-0 white-space-wrap" v-html="$t('waitingConfirm.checkVerifyEmail.content2')">
            </v-card-text>
          </v-card-text>
          <v-card-text v-if="checkResetEmail">
            <v-card-title
              class="pb-4 primary--text subtitle-2 text-uppercase font-weight-black justify-center"
            >
              {{ $t('waitingConfirm.checkResetEmail.title') }}
            </v-card-title>

            <v-card-text class="pb-0">
              {{ $t('waitingConfirm.checkResetEmail.content1') }}
            </v-card-text>

            <v-card-text class="pb-0 white-space-wrap" v-html="$t('waitingConfirm.checkResetEmail.content2')">
            </v-card-text>
          </v-card-text>
          <v-card-text>
            <v-btn
              block
              depressed
              color="primary black--text"
              class="mt-3"
              to="/accounts/signin"
            >
              {{ $t('waitingConfirm.goToSignIn') }}
            </v-btn>
          </v-card-text>
          <v-card-text v-if="!checkSignup">
            <v-btn
              block
              depressed
              color="white black--text"
              class="mb-2 custom-text"
              @click="forgotPassword"
              :disabled="isDisabled"
            >
              {{ buttonResend }}
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import game from "@/game";
export default {
  data: () => ({
    count: 60,
    isCounting: false,
    interval: null,
  }),
  methods: {
    forgotPassword() {
      let email = localStorage.getItem("emailForgot");
      var isVerifyEmailConfirm = this.$route.name === "VerifyEmailConfirmation";
      if (isVerifyEmailConfirm) {
        game.resendConfirmationMailer(email, function(res) {
          console.log(res);
        });
      } else {
        game.forgotPassword(email, function(res) {
          console.log(res);
        });
      }
      this.count = 60;
      this.interval = setInterval(this.decrementTime, 1000);
      this.isCounting = !this.isCounting;
    },
    decrementTime() {
      this.count -= 1;
      if (this.count === 0) {
        clearInterval(this.interval);
        this.isCounting = false;
      }
    },
  },
  computed: {
    checkSignup() {
      return this.$route.name === "SignupWaitingConfirmation";
    },
    checkVerifyEmail() {
      return this.$route.name === "VerifyEmailConfirmation";
    },
    checkResetEmail() {
      return this.$route.name === "ResetPasswordConfirmation";
    },
    buttonResend() {
      if (this.isCounting) {
        return this.$t('waitingConfirm.resendIn', {number: this.count});
      } else return this.$t('waitingConfirm.resend');
    },
    isDisabled() {
      return this.isCounting && this.count > 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.container { padding-bottom: 0; }
.v-card__text {
  padding-bottom: 0;
  text-align: center;
}
.white-space-wrap {
  white-space: pre-wrap
}
</style>
