<template>
  <v-navigation-drawer
    dark
    fixed
    app
    :value="navbarShow"
    width="250"
    @input="stateNavbarShow"
  >
    <div class="app-logo mt-10 mb-5 ml-6">
      <v-avatar color="primary" size="56">
        <v-img
          alt="user avatar"
          class="shrink"
          contain
          :src="this.currentUser.avatar_url || avatarDefault"
          transition="scale-transition"
          width="48"
          height="48"
        />
      </v-avatar>
      <h5 class="pl-5 mt-3 mb-3">{{ currentUser.nickname || "田中大智" }}</h5>
    </div>
    <the-layout-drawer-list :dense="navbarDense" icon-show />
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import TheLayoutDrawerList from "./TheLayoutDrawerList.vue";

export default {
  name: "TheLayoutDrawer",
  components: {
    TheLayoutDrawerList,
  },
  data: () => ({
    avatarDefault: require("@/assets/guadians-thumb.jpg"),
  }),
  computed: {
    ...mapGetters(["navbarDense", "navbarShow", "toolbarDense", "currentUser"]),
  },
  methods: {
    stateNavbarShow(state) {
      this.$store.dispatch("admin/navbarState", { state });
    },
  },
};
</script>
