
const inspection_date = {
  title: 'survey.inspection_date',
  type: "date",
  column: "inspection_date",
  defaultValue: { id: '' },
};

const investigator = {
  title: 'survey.investigator',
  type: 'input',
  column: 'investigator',
  defaultValue: '',
  rules: 'text50'
};

const survey_type = {
  title: 'survey.survey_type',
  type: 'select',
  column: 'survey_type',
  data: [
    {
      name: 'survey.inspection_during_survey',
      id: 1,
    },
    {
      name: 'survey.routine_inspection',
      id: 2,
    },
  ],
  defaultValue: {
    id: 2,
  },
}

const weather = {
  title: 'survey.weather',
  type: 'select',
  column: 'weather',
  data: [
    {
      name: 'survey.sunny',
      id: 1,
    },
    {
      name: 'survey.cloudy',
      id: 2,
    },
    {
      name: 'survey.light_rain',
      id: 3,
    },
    {
      name: 'survey.heavy_rain',
      id: 4,
    },
  ],
  defaultValue: {
    id: 1,
  },
}


export const inspectInfo = [inspection_date, investigator, weather, survey_type]

const installation_status_by_roadway = {
  title: 'survey.installation_status_by_roadway',
  column: 'installation_status_by_roadway',
  type: 'select',
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: "survey.a_roadway_t-8",
      id: 1
    },
    {
      name: "survey.b_roadway_t-14",
      id: 2
    },
    {
      name: "survey.b_no-large-vehicle_t-8",
      id: 9
    },
    {
      name: "survey.c_roadway_t-20",
      id: 3
    },
    {
      name: "survey.e_roadway_t-25",
      id: 6
    },
    {
      name: "survey.e_no-large-vehicle_t-14",
      id: 4
    },
    {
      name: "survey.e_no-large-vehicle_t-20",
      id: 5
    },
    {
      name: "survey.e_no-large-vehicle_t-25",
      id: 10
    },
    {
      name: "survey.e_walkway_t-8",
      id: 11
    },
    {
      name: "survey.e_walkway_t-14",
      id: 12
    },
    {
      name: "survey.e_walkway_t-20",
      id: 13
    },
    {
      name: "survey.e_walkway_t-25",
      id: 14
    },
    {
      name: 'survey.other',
      id: 7,
    },
    {
      name: 'survey.unknown',
      id: 8,
    },
  ],
  defaultValue: {
    id: ''
  }
}

const anti_floating_and_scattering_function = {
  title: 'survey.anti_floating_and_scattering_function',
  column: 'anti_floating_and_scattering_function',
  type: 'select',
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: 'survey.efunction',
      id: 1
    },
    {
      name: 'survey.afunction',
      id: 2
    }
  ],
  defaultValue: {
    id: ''
  }
}

const fall_and_drop_prevention = {
  title: 'survey.fall_and_drop_prevention',
  column: 'fall_and_drop_prevention',
  type: 'select',
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: 'survey.efunction',
      id: 1
    },
    {
      name: 'survey.afunction',
      id: 2
    }
  ],
  defaultValue: {
    id: ''
  }
}

export const manholeLidInfo = [installation_status_by_roadway, anti_floating_and_scattering_function, fall_and_drop_prevention]

const anti_floating_and_scattering_status = {
  title: 'survey.anti_floating_and_scattering_status',
  column: 'anti_floating_and_scattering_status',
  type: 'select',
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: 'survey.working_properly',
      id: 2
    },
    {
      name: 'survey.not_working_including_dropout',
      id: 1
    },
  ],
  defaultValue: {
    id: ''
  }
}

const open_close_by_tools = {
  title: 'survey.open_close_by_tools',
  column: 'open_close_by_tools',
  type: 'select',
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: 'survey.working_properly_not_open_easily',
      id: 2
    },
    {
      name: 'survey.open_easily',
      id: 1
    },
  ],
  defaultValue: {
    id: ''
  }
}

const fall_and_drop_prevention_status = {
  title: 'survey.fall_and_drop_prevention_status',
  column: 'fall_and_drop_prevention_status',
  type: 'select',
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: 'survey.working_properly',
      id: 2
    },
    {
      name: 'survey.not_working',
      id: 1
    },
  ],
  defaultValue: {
    id: ''
  }
}

const open_close_status = {
  title: 'survey.open_close_status',
  column: 'open_close_status',
  type: 'select',
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: 'survey.working_properly',
      id: 4
    },
    {
      name: 'survey.unmanageable_by_human_power',
      id: 1
    },
    {
      name: 'survey.impossible_due_to_corrosion',
      id: 2
    },
    {
      name: 'survey.impossible_due_to_erosion',
      id: 3
    },
  ],
  defaultValue: {
    id: ''
  }
}

export const manholeLidInfo2 = [anti_floating_and_scattering_status, open_close_by_tools, fall_and_drop_prevention_status, open_close_status]

const crasks_and_chips = {
  title: 'survey.crasks_and_chips',
  column: 'crasks_and_chips',
  type: 'select',
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: 'survey.eno',
      id: 2
    },
    {
      name: 'survey.ayes',
      id: 1
    },
  ],
  defaultValue: {
    id: ''
  }
}

const rattle_by_vehicle_and_step = {
  title: 'survey.rattle_by_vehicle_and_step',
  column: 'rattle_by_vehicle_and_step',
  type: 'select',
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: 'survey.eno',
      id: 2
    },
    {
      name: 'survey.ayes',
      id: 1
    },
  ],
  defaultValue: {
    id: ''
  }
}

const surface_wear_status = {
  title: 'survey.surface_wear_status',
  column: 'surface_wear_status',
  type: 'select',
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: 'survey.e3mm_roadway',
      id: 4
    },
    {
      name: 'survey.e3mm_walkway',
      id: 5
    },
    {
      name: 'survey.d3mm_roadway',
      id: 3
    },
    {
      name: 'survey.d2to3mm_walkway',
      id: 6
    },
    {
      name: 'survey.c2to3mm_roadway',
      id: 2
    },
    {
      name: 'survey.a2mm_roadway',
      id: 1
    },
    {
      name: 'survey.a2mm_walkway',
      id: 7
    },
  ],
  defaultValue: {
    id: ''
  }
}

export const manholeLidInfo3 = [crasks_and_chips, rattle_by_vehicle_and_step, surface_wear_status]

const surface_wear_status_1 = {
  title: " ",
  column: "surface_wear_status_1",
  type: 'input-unit',
  unit: "mm",
  defaultValue: "",
  rules: "float1"
}

const surface_wear_status_2 = {
  title: " ",
  column: "surface_wear_status_2",
  type: 'input-unit',
  unit: "mm",
  defaultValue: "",
  rules: "float1"
}

const surface_wear_status_3 = {
  title: " ",
  column: "surface_wear_status_3",
  type: 'input-unit',
  unit: "mm",
  defaultValue: "",
  rules: "float1"
}

const surface_wear_status_4 = {
  title: " ",
  column: "surface_wear_status_4",
  type: 'input-unit',
  unit: "mm",
  defaultValue: "",
  rules: "float1"
}

const surface_wear_status_5 = {
  title: " ",
  column: "surface_wear_status_5",
  type: 'input-unit',
  unit: "mm",
  defaultValue: "",
  rules: "float1"
}

export const surfaceWear = [surface_wear_status_1, surface_wear_status_2, surface_wear_status_3, surface_wear_status_4, surface_wear_status_5]

const surface_wear_condition = {
  title: 'survey.surface_wear_condition',
  column: 'surface_wear_condition',
  type: 'select',
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: 'survey.none',
      id: 3
    },
    {
      name: 'survey.a_little_rusting',
      id: 2
    },
    {
      name: 'survey.rusting_to_the_point_of_invisibility',
      id: 1
    },
  ],
  defaultValue: {
    id: ''
  }
}

const lift_prevention_condition = {
  title: 'survey.lift_prevention_condition',
  column: 'lift_prevention_condition',
  type: 'select',
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: 'survey.working_properly',
      id: 2
    },
    {
      name: 'survey.condition_not_working',
      id: 1
    },
  ],
  defaultValue: {
    id: ''
  }
}

const lock_structures_condition = {
  title: 'survey.lock_structures_condition',
  column: 'lock_structures_condition',
  type: 'select',
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: 'survey.working_properly',
      id: 2
    },
    {
      name: 'survey.not_working',
      id: 1
    },
  ],
  defaultValue: {
    id: ''
  }
}

const fall_prevention_condition = {
  title: 'survey.fall_prevention_condition',
  column: 'fall_prevention_condition',
  type: 'select',
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: 'survey.working_properly',
      id: 2
    },
    {
      name: 'survey.not_working',
      id: 1
    },
  ],
  defaultValue: {
    id: ''
  }
}

const lid_frame_step_difference = {
  title: 'survey.lid_frame_step_difference',
  column: 'lid_frame_step_difference',
  type: 'select',
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: 'E 急勾配 蓋の沈み <2mm',
      id: 3
    },
    {
      name: 'E 急勾配 蓋の浮き <10mm',
      id: 2
    },
    {
      name: 'E 平受・緩勾配 <10mm',
      id: 4
    },
    {
      name: 'A 急勾配 蓋の沈み ≧2mm',
      id: 5
    },
    {
      name: 'A 急勾配 蓋の浮き ≧10mm',
      id: 1
    },
    {
      name: 'A 平受・緩勾配 ≧10mm',
      id: 6
    },
  ],
  defaultValue: {
    id: ''
  }
}

const height_adjust_damage = {
  title: 'survey.height_adjust_damage',
  column: 'height_adjust_damage',
  type: 'select',
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: 'survey.eno',
      id: 2
    },
    {
      name: 'survey.ayes2',
      id: 1
    },
  ],
  defaultValue: {
    id: ''
  }
}

const surrounding_damage = {
  title: 'survey.surrounding_damage',
  column: 'surrounding_damage',
  type: 'select',
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: 'E 穴・クラックともになし',
      id: 2
    },
    {
      name: 'C 受け枠と路面の間に隙間',
      id: 3
    },
    {
      name: 'B クラックあるが穴はない',
      id: 4
    },
    {
      name: 'A 穴・クラックともにあり',
      id: 1
    },
  ],
  defaultValue: {
    id: ''
  }
}

const lid_surrounding_step_difference = {
  title: 'survey.lid_surrounding_step_difference',
  column: 'lid_surrounding_step_difference',
  type: 'select',
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: 'survey.e20',
      id: 2
    },
    {
      name: 'survey.a20',
      id: 1
    },
  ],
  defaultValue: {
    id: ''
  }
}

const lid_continued_abnormality = {
  title: 'survey.lid_continued_abnormality',
  column: 'lid_continued_abnormality',
  type: 'select',
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: 'survey.abnormality_no',
      id: 2
    },
    {
      name: 'survey.abnormality_yes',
      id: 1
    },
  ],
  defaultValue: {
    id: ''
  }
}

const lid_need_for_renovation = {
  title: 'survey.lid_need_for_renovation',
  column: 'lid_need_for_renovation',
  type: 'select',
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: 'survey.renovation_no',
      id: 2
    },
    {
      name: 'survey.renovation_yes',
      id: 1
    },
  ],
  defaultValue: {
    id: ''
  }
}

const lid_first_aid_ability = {
  title: 'survey.lid_first_aid_ability',
  column: 'lid_first_aid_ability',
  type: 'select',
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: 'survey.no',
      id: 2
    },
    {
      name: 'survey.yes',
      id: 1
    },
  ],
  defaultValue: {
    id: ''
  }
}


const lid_measures_details = {
  title: 'survey.lid_measures_details',
  column: 'lid_measures_details',
  type: 'select',
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: 'survey.nothing',
      id: 4
    },
    {
      name: 'survey.replacement',
      id: 3
    },
    {
      name: 'survey.wedge',
      id: 2
    },
    {
      name: 'survey.other',
      id: 1
    },
  ],
  defaultValue: {
    id: ''
  }
}

const lid_notes = {
  title: 'survey.lid_notes',
  type: 'area',
  column: 'lid_notes',
  defaultValue: '',
  rules: 'text200',
  placeholder: 'survey.max200'
};


export const manholeLidInfo4 = [surface_wear_condition, lift_prevention_condition, lock_structures_condition, fall_prevention_condition, lid_frame_step_difference, height_adjust_damage, surrounding_damage, lid_surrounding_step_difference, lid_continued_abnormality, lid_need_for_renovation, lid_first_aid_ability, lid_measures_details, lid_notes]

const foothold = {
  title: 'survey.foothold',
  column: 'foothold',
  type: 'select',
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: 'survey.no_abnormality',
      id: 1
    },
    {
      name: 'survey.foot_none',
      id: 2
    },
    {
      name: 'survey.shortage',
      id: 3
    },
    {
      name: 'survey.other_abnormality',
      id: 4
    },
  ],
  defaultValue: {
    id: ''
  }
}

const slant_wall = {
  title: 'survey.slant_wall',
  column: 'slant_wall',
  type: 'select',
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: 'survey.no_abnormality',
      id: 1
    },
    {
      name: 'survey.damage',
      id: 2
    },
    {
      name: 'survey.water_infiltration',
      id: 3
    },
    {
      name: 'survey.misalignment',
      id: 4
    },
    {
      name: 'survey.anomaly_complex',
      id: 5
    },
    {
      name: 'survey.other_abnormality',
      id: 6
    },
  ],
  defaultValue: {
    id: ''
  }
}

const linear_wall = {
  title: 'survey.linear_wall',
  column: 'linear_wall',
  type: 'select',
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: 'survey.no_abnormality',
      id: 1
    },
    {
      name: 'survey.damage',
      id: 2
    },
    {
      name: 'survey.water_infiltration',
      id: 3
    },
    {
      name: 'survey.misalignment',
      id: 4
    },
    {
      name: 'survey.anomaly_complex',
      id: 5
    },
    {
      name: 'survey.other_abnormality',
      id: 6
    },
  ],
  defaultValue: {
    id: ''
  }
}

const inferior_wall = {
  title: 'survey.inferior_wall',
  column: 'inferior_wall',
  type: 'select',
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: 'survey.no_abnormality',
      id: 1
    },
    {
      name: 'survey.damage',
      id: 2
    },
    {
      name: 'survey.water_infiltration',
      id: 3
    },
    {
      name: 'survey.misalignment',
      id: 4
    },
    {
      name: 'survey.anomaly_complex',
      id: 5
    },
    {
      name: 'survey.other_abnormality',
      id: 6
    },
  ],
  defaultValue: {
    id: ''
  }
}

const invert = {
  title: 'survey.invert',
  column: 'invert',
  type: 'select',
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: 'survey.no_abnormality',
      id: 1
    },
    {
      name: 'survey.damage',
      id: 2
    },
    {
      name: 'survey.water_infiltration',
      id: 3
    },
    {
      name: 'survey.anomaly_complex',
      id: 4
    },
    {
      name: 'survey.deposition',
      id: 6
    },
    {
      name: 'survey.other_abnormality',
      id: 5
    },
  ],
  defaultValue: {
    id: ''
  }
}

const water_depth_measure = {
  title: 'survey.water_depth_measure',
  column: 'water_depth_measure',
  type: 'input-unit',
  unit: 'cm',
  defaultValue: '',
  rules: 'interger'
}

const water_depth_time = {
  title: 'survey.water_depth_time',
  column: 'water_depth_time',
  type: 'date',
  defaultValue: { id: '' },
}

const corrosion_degree = {
  title: 'survey.corrosion_degree',
  column: 'corrosion_degree',
  type: 'select',
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: 'survey.no_abnormality',
      id: 1
    },
    {
      name: 'A 鉄筋露出',
      id: 2
    },
    {
      name: "B 骨材露出",
      id: 3
    },
    {
      name: "C 表面の荒れ",
      id: 4
    },
  ],
  defaultValue: {
    id: ''
  }
}

const inside_continued_abnormality = {
  title: 'survey.inside_continued_abnormality',
  column: 'inside_continued_abnormality',
  type: 'select',
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: 'survey.no',
      id: 2
    },
    {
      name: 'survey.yes',
      id: 1
    },
  ],
  defaultValue: {
    id: ''
  }
}

const inside_need_for_renovation = {
  title: 'survey.inside_need_for_renovation',
  column: 'inside_need_for_renovation',
  type: 'select',
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: 'survey.inside_no',
      id: 2
    },
    {
      name: 'survey.inside_yes',
      id: 1
    },
  ],
  defaultValue: {
    id: ''
  }
}

const inside_first_aid_ability = {
  title: 'survey.inside_first_aid_ability',
  column: 'inside_first_aid_ability',
  type: 'select',
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: 'survey.no',
      id: 2
    },
    {
      name: 'survey.yes',
      id: 1
    },
  ],
  defaultValue: {
    id: ''
  }
}


const inside_measures_details = {
  title: 'survey.inside_measures_details',
  column: 'inside_measures_details',
  type: 'select',
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: 'survey.nothing',
      id: 5
    },
    {
      name: 'survey.sealing',
      id: 4
    },
    {
      name: 'survey.renovation',
      id: 3
    },
    {
      name: 'survey.replacement',
      id: 2
    },
    {
      name: 'survey.other',
      id: 1
    },
  ],
  defaultValue: {
    id: ''
  }
}

const inside_notes = {
  title: 'survey.inside_notes',
  type: 'area',
  column: 'inside_notes',
  defaultValue: '',
  rules: 'text200',
  placeholder: 'survey.max200'
};


export const insideManhole = [foothold, slant_wall, linear_wall, inferior_wall, invert, water_depth_measure, water_depth_time, corrosion_degree, inside_continued_abnormality, inside_need_for_renovation, inside_first_aid_ability, inside_measures_details, inside_notes]

const load_resistance_type = {
  title: 'survey.load_resistance_type',
  type: "select",
  column: "load_resistance_type",
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: 'survey.need_not',
      id: 1,
    },
    {
      name: 'survey.need_to_inspect',
      id: 2,
    },
    {
      name: 'survey.need_to_replace',
      id: 3,
    }
  ],
  defaultValue: {
    id: '',
  },
};

const new_anti_floating_and_scattering_function = {
  title: 'survey.anti_floating_and_scattering_function',
  column: 'anti_floating_and_scattering_function',
  type: 'select',
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: 'survey.need_not',
      id: 1,
    },
    {
      name: 'survey.need_to_inspect',
      id: 2,
    },
    {
      name: 'survey.need_to_replace',
      id: 3,
    }
  ],
  defaultValue: {
    id: ''
  }
}

const new_fall_and_drop_prevention = {
  title: 'survey.fall_and_drop_prevention',
  column: 'fall_and_drop_prevention',
  type: 'select',
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: 'survey.need_not',
      id: 1,
    },
    {
      name: 'survey.need_to_inspect',
      id: 2,
    },
    {
      name: 'survey.need_to_replace',
      id: 3,
    }
  ],
  defaultValue: {
    id: ''
  }
}

const new_exterior = {
  title: 'survey.exterior',
  column: 'exterior',
  type: 'select',
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: 'survey.need_not',
      id: 1,
    },
    {
      name: 'survey.need_to_inspect',
      id: 2,
    },
    {
      name: 'survey.need_to_replace',
      id: 3,
    }
  ],
  defaultValue: {
    id: ''
  }
}

const new_rattling = {
  title: 'survey.rattling',
  column: 'rattling',
  type: 'select',
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: 'survey.need_not',
      id: 1,
    },
    {
      name: 'survey.need_to_inspect',
      id: 2,
    },
    {
      name: 'survey.need_to_replace',
      id: 3,
    }
  ],
  defaultValue: {
    id: ''
  }
}

const new_surface_wear = {
  title: 'survey.surface_wear',
  column: 'surface_wear',
  type: 'select',
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: 'survey.need_not',
      id: 1,
    },
    {
      name: 'survey.need_to_inspect',
      id: 2,
    },
    {
      name: 'survey.need_to_replace',
      id: 3,
    }
  ],
  defaultValue: {
    id: ''
  }
}

const new_lid_frame = {
  title: 'survey.lid_frame',
  column: 'step_between_lid_and_frame',
  type: 'select',
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: 'survey.need_not',
      id: 1,
    },
    {
      name: 'survey.need_to_inspect',
      id: 2,
    },
    {
      name: 'survey.need_to_replace',
      id: 3,
    }
  ],
  defaultValue: {
    id: ''
  }
}

const new_surrounding_damage = {
  title: 'survey.surrounding_damage',
  column: 'steps_in_the_surrounding_pavement',
  type: 'select',
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: 'survey.need_not',
      id: 1,
    },
    {
      name: 'survey.need_to_inspect',
      id: 2,
    },
    {
      name: 'survey.need_to_replace',
      id: 3,
    }
  ],
  defaultValue: {
    id: ''
  }
}

const new_lid_surrounding_step_difference = {
  title: 'survey.lid_surrounding_step_difference',
  column: 'steps_in_the_lid_and_surrounding_pavement',
  type: 'select',
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: 'survey.need_not',
      id: 1,
    },
    {
      name: 'survey.need_to_inspect',
      id: 2,
    },
    {
      name: 'survey.need_to_replace',
      id: 3,
    }
  ],
  defaultValue: {
    id: ''
  }
}

const new_crack = {
  title: 'survey.crack',
  column: 'crack',
  type: 'select',
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: 'survey.no',
      id: 1,
    },
    {
      name: 'survey.yes',
      id: 2,
    }
  ],
  defaultValue: {
    id: ''
  }
}
const new_subsidence = {
  title: 'survey.subsidence',
  column: 'subsidence',
  type: 'select',
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: 'survey.no',
      id: 1,
    },
    {
      name: 'survey.yes',
      id: 2,
    }
  ],
  defaultValue: {
    id: ''
  }
}
const new_depression = {
  title: 'survey.depression',
  column: 'depression',
  type: 'select',
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: 'survey.no',
      id: 1,
    },
    {
      name: 'survey.yes',
      id: 2,
    }
  ],
  defaultValue: {
    id: ''
  }
}
const new_overflow = {
  title: 'survey.overflow',
  column: 'overflow',
  type: 'select',
  data: [
    {
      name: 'common.empty',
      id: '',
    },
    {
      name: 'survey.no',
      id: 1,
    },
    {
      name: 'survey.yes',
      id: 2,
    }
  ],
  defaultValue: {
    id: ''
  }
}

export const newFormat = [load_resistance_type, new_anti_floating_and_scattering_function, new_fall_and_drop_prevention, new_exterior, new_rattling, new_surface_wear, new_lid_frame, new_surrounding_damage, new_lid_surrounding_step_difference, new_crack, new_subsidence, new_depression, new_overflow, lid_notes]