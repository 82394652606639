<template>
 <!-- <v-card >
  <v-container class="user-detail"> -->
  <v-card class="mx-auto user-detail" max-width="90%" outlined dark>
    <div class="user-detail_title">{{`ユーザー詳細 #${$route.params.id}`}}</div>
    <v-container class="user-detail_content mt-4">
      <v-form ref="form">
        <v-card color="transparent" flat>
          <div class="d-flex align-center mb-5 pb-5">
            <v-list-item-avatar
              tile
              size="100"
              :rounded="true"
              v-if="user.avatarUrl !== ''"
              class=""
            >
              <v-avatar size="100">
                <img
                  :src="user.avatarUrl"
                  :alt="user.nickname"
                >
              </v-avatar>
              
              
            </v-list-item-avatar>
          </div>
          <p class="user-detail_content__main-title">詳細情報</p>
          <div class="d-flex item">
            <span class="item_title">ユーザー名</span>
            <div class="item_information">
              <span>{{ user.nickname }}</span>
            </div>
          </div>

          <div class="d-flex item">
            <span class="item_title">ユーザーID</span>
            <div class="item_information">
              <span>{{ user.id }}</span>
            </div>
          </div>
          
          <div class="d-flex item">
            <span class="item_title">メールアドレス</span>
            <div class="item_information">
              <span>{{ user.email }}</span>
            </div>
          </div>

          <div class="d-flex item">
            <span class="item_title">都道府県</span>
            <div class="item_information">
              <span>{{ renderPrefecture }}</span>
            </div>
          </div>

          <div class="d-flex item">
            <span class="item_title">市区町村</span>
            <div class="item_information">
              <span>{{ renderCity }}</span>
            </div>
          </div>
          
          <v-divider></v-divider>

          <p class="user-detail_content__main-title">状態更新</p>

          <div class="d-flex align-center justify-content-between status">
            <span>状態</span>
            <input-select 
              :value="user"
              type="detail"
            >
            </input-select>
          </div>
    <!-- </v-container>
    </v-card> -->
        </v-card>
      </v-form>
      </v-container>
  </v-card>

</template>

<script>
import { mapGetters } from 'vuex';
import InputSelect from '../../components/admin/InputSelectStatus.vue';

export default {
  components: { 
    InputSelect,
  },
  data () {
    return {
      search: '',
      value: {}
    }
  },
  watch: {
    async $route() {
      if(this.$route.params.id && this.$route.path.includes('/admin/user')){
        await this.getUser();
      }
    },
  },
  computed: {
    ...mapGetters([
      "user",
    ]),
    renderPrefecture() {
      return this.user?.pref?.name
    },
    renderCity() {
      return this.user?.city?.cityname
    }
  },
  
  methods: {
    getUser() {
      const _this = this;
      const id = _this.$route.params.id
      _this.$store.dispatch("adminUsers/getUser", id)
    },
  },

  async mounted() {
    const id = this.$route.params.id
    await this.$store.dispatch("adminUsers/getUser", id)
  }
}
</script>

<style lang="scss">
.user-detail{
  font-size: 12px;
  &_title{
    color: #81ffff !important;
    font-size: 20px !important;
    line-height: 2rem;
    letter-spacing: 0.1666666667em !important;
    text-transform: uppercase !important;
    margin: 1rem;
  }
  &_content{
    &__avatar{
       color: #fff;
       font-size: 18px !important;
    }
    &__main-title{
      font-size: 1rem;
      margin: 1rem 0;
      color: #81ffff !important;
    }
  }
  .v-input__slot{
    fieldset{
      color: #fff !important;
    }
  }
  .status{
    justify-content: space-between;
    select{
      box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
      border-collapse: collapse;
      border-color: #27f8f9;
      border-style: solid;
      border-width: 2px;
      height: 40px;
      border-radius: 15px;
      padding: 0 10px;
    }
  }
  .item{
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    &_information{
      box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
      border-collapse: collapse;
      border-color: #27f8f9;
      border-style: solid;
      border-width: 2px;
      height: 40px;
      border-radius: 15px;
      padding: 7px 10px;
      width: fit-content;
      min-width: 98px;
      color: #bababa;
      font-size: 14px;
    }
  }
}
  

</style>