
import game from "@/game"
import store from "@/store";
import { eventBus } from '../../common/eventBus.js';
import i18n from '../../i18n.js';
import moment from "moment";
import _ from 'lodash';
import router from '../../router';

const state = {
  dataSurvey: {},
  listVersion: [],
  form: {},
  defaultForm: {
    inspection_date: (new Date()),
    survey_version: `点検日 ${moment((new Date())).locale("ja").format("ll")}`,
    investigator: '',
    survey_type: 2,
    weather: 1,
    installation_status_by_roadway: '',
    anti_floating_and_scattering_function: '',
    fall_and_drop_prevention: '',
    anti_floating_and_scattering_status: '',
    open_close_by_tools: '',
    fall_and_drop_prevention_status: '',
    open_close_status: '',
    crasks_and_chips: '',
    rattle_by_vehicle_and_step: '',
    surface_wear_condition: '',
    lift_prevention_condition: '',
    lock_structures_condition: '',
    fall_prevention_condition: '',
    lid_frame_step_difference: '',
    height_adjust_damage: '',
    surrounding_damage: '',
    lid_surrounding_step_difference: '',
    lid_continued_abnormality: '',
    lid_need_for_renovation: '',
    lid_first_aid_ability: '',
    lid_measures_details: '',
    lid_notes: '',
    foothold: '',
    slant_wall: '',
    linear_wall: '',
    inferior_wall: '',
    invert: '',
    water_depth_measure: null,
    water_depth_time: null,
    corrosion_degree: '',
    inside_continued_abnormality: '',
    inside_need_for_renovation: '',
    inside_first_aid_ability: '',
    inside_measures_details: '',
    inside_notes: '',
    surface_wear_status: '',
    surface_wear_status_1: null,
    surface_wear_status_2: null,
    surface_wear_status_3: null,
    surface_wear_status_4: null,
    surface_wear_status_5: null,
    crack: '',
    depression: '',
    exterior: '',
    load_resistance_type: '',
    overflow: '',
    rattling: '',
    step_between_lid_and_frame: '',
    steps_in_the_lid_and_surrounding_pavement: '',
    steps_in_the_surrounding_pavement: '',
    subsidence: '',
    surface_wear: ''
  },
  surface: {},
  enableSurveyBtn: false,
  comments: [],
  isLoadingComments: false,
  hasLoadMoreComments: true,
  addCommentSuccess: {},
  totalCommentsCount: 0,
  post: {},
  showChooseTypeImage: false,
};

const mutations = {
  GET_SURVEY: (state, payload) => {
    state.dataSurvey = payload.data.surveyData
    state.form = {}
    state.enableSurveyBtn = false
    state.surface = _.pick(state.dataSurvey, ['surface_wear_status_1', 'surface_wear_status_2', 'surface_wear_status_3', 'surface_wear_status_4', 'surface_wear_status_5']);
    if (payload.code !== 200) {
      state.dataSurvey = state.defaultForm
      state.surface = _.pick(state.defaultForm, ['surface_wear_status_1', 'surface_wear_status_2', 'surface_wear_status_3', 'surface_wear_status_4', 'surface_wear_status_5']);
    }
  },
  GET_VERSION: (state, payload) => {
    const createNew = {
      id: "create",
      survey_version: i18n.t('survey.createNew'),
    };
    state.enableSurveyBtn = false
    if (payload.code !== 200) {
      state.dataSurvey = state.defaultForm
      state.listVersion = [createNew]
      state.surface = _.pick(state.defaultForm, ['surface_wear_status_1', 'surface_wear_status_2', 'surface_wear_status_3', 'surface_wear_status_4', 'surface_wear_status_5']);
    } else {
      payload.data.surveyList.push(createNew)
      state.listVersion = payload.data.surveyList
      state.dataSurvey = payload.data.survey
      state.surface = _.pick(state.dataSurvey, ['surface_wear_status_1', 'surface_wear_status_2', 'surface_wear_status_3', 'surface_wear_status_4', 'surface_wear_status_5']);
    }
  },
  UPDATE_SURVEY: (state) => {
    state.form = {}
  },
  ROUND_SURVEY: (state, payload) => {
    state.dataSurvey = payload.data.data.roundInspectionSurvey || payload.data.roundInspectionSurvey
    state.form = {}
  },
  
  CHANGE_FORM: (state, payload) => {
    state.form[payload.column] = payload.value;
    if (payload?.column?.includes('surface_wear_status_')) {
      state.surface[payload.column] = payload.value;
    }
    if(state.dataSurvey) {
      for (const property in state.form) {
        if (property === 'inspection_date' || property === 'water_depth_time') {
          state.dataSurvey[property] = moment(state.dataSurvey[property]).format('YYYY-MM-DD')
        }
        if (state.form[property] === state.dataSurvey[property]) {
          state.enableSurveyBtn = false
        } else {
          state.enableSurveyBtn = true
        }
      }
    }
  },
  CREATE_NEW: (state) => {
    state.form = {}
    state.dataSurvey = state.defaultForm
    state.surface = _.pick(state.dataSurvey, ['surface_wear_status_1', 'surface_wear_status_2', 'surface_wear_status_3', 'surface_wear_status_4', 'surface_wear_status_5']);
  },

  UPLOAD_SUCCESS: (state, payload) => {
    const image = {
      id: payload.imgId,
      type: payload.imgType,
      url: payload.imgUrl
    }
    if (state.form?.images) {
      state.form.images.push(image)
    } else {
      state.form = { ...state.form, images: [image] }
    }
  },
  UPDATE_IMAGE_SUCCESS: (state, payload) => {
    state.form.images = _.map(state.form.images, (image) => {
      return image.id === payload.imgId
        ? {
          ...image,
          type: payload.type
        } : image
    })
  },
  REMOVE_IMAGE_SUCCESS: (state, payload) => {
    state.dataSurvey.images = _.reject(state.dataSurvey.images, (image) => {
      return image.id === payload
    })
  },
  ADD_SURVEY_COMMENT_SUCCESS: (state, payload) => {
    state.addCommentSuccess = payload
  },
  ADD_SURVEY_COMMENT_FAILED: (state, payload) => {
    state.addCommentSuccess = payload
  },
  GET_SURVEY_COMMENTS: (state, payload) => {
    state.hasLoadMoreComments = payload.currentPage < payload.totalPages
    if (payload.currentPage === 0) {
      state.comments = payload.resources.reverse()
    } else {
      state.comments = [...payload.resources.reverse(), ...state.comments]
    }
    state.totalCommentsCount = payload.totalItems
  },
  GET_POST_SUCCESS: (state, payload) => {
    state.post = payload
  },
};

const actions = {
  getSurvey({ commit }, surveyId) {
    return game.getSurvey(surveyId, function (res) {
      commit("GET_SURVEY", res);
    })
  },
  getRoundSurvey({ commit }, surveyId) {
    return game.getRoundSurvey(surveyId, function (res) {
      commit("GET_SURVEY", res);
    })
  },
  createNew({ commit }) {
    commit("CREATE_NEW")
  },
  getVersion({ commit }, targetId) {
    return game.getAllSurvey(targetId, function (res) {
      commit("GET_VERSION", res)
      if (res.code !== 200) {
        store.dispatch("instantPost/getTargetById", targetId);
      }
    })
  },
  updateSurvey({ commit }, req) {
    game.updateSurvey(req, function (res) {
      if (res.data.data.success) {
        commit("UPDATE_SURVEY", res)

        req.isAdminCreate ? router.push(`/admin/post/${res.data.data.surveyId}/info?type=1`) :
          req.targetId && (store.dispatch("survey/getVersion", req.targetId))

        router.history.current.path.includes('/survey/contents') && eventBus.$emit('showNotification', {
          active: true,
          type: "success",
          message: `${!req.onCreate ? '点検内容を更新しました' : '点検内容を投稿しました'}`
        })
      }
    })
  },

  createRoundSurvey({ commit }, req) {
    game.createRoundSurvey(req, function (res) {
      if (res.data.data.success) {
        commit("ROUND_SURVEY", res)

        req.data.isAdminCreate ? router.push(`/admin/post/${res.data.data.roundInspectionSurvey.id}/info?type=2`) :
          req.data.target_id && (store.dispatch("survey/getVersion", req.data.target_id))

        eventBus.$emit('showNotification', {
          active: true,
          type: "success",
          message: `点検内容を投稿しました`
        })

      }
    })
  },

  updateRoundSurvey({ commit }, req) {
    game.updateRoundSurvey(req, function (res) {
      if (res.data.data.success) {
        commit("ROUND_SURVEY", res)

        eventBus.$emit('showNotification', {
          active: true,
          type: "success",
          message: `点検内容を更新しました`
        })
        if(!req.isAdminCreate){
          req.targetId && (store.dispatch("survey/getVersion", req.targetId))
        }
      }
    })
  },

  changeForm({ commit }, obj) {
    commit("CHANGE_FORM", obj)
  },

  uploadImage({ commit }, data) {
    game.surveyUploadImage(data, function (res) {
      if (res?.data?.success) {
        commit("UPLOAD_SUCCESS", res?.data)
        commit("instantPost/TOGGLE_SHOW_UPLOAD_IMAGE", true, { root: true })

        if (data?.get('surveyData')) {
          store.dispatch("survey/getVersion", data?.get('targetId'))
        } 

        if(Number(res?.data.surveyType) === 1){
          store.dispatch("survey/getSurvey", res?.data.surveyId)
        } else {
          store.dispatch("survey/getRoundSurvey", res?.data.surveyId)
        }
      }
    })
  },

  uploadImageMap({ commit }, data) {
    commit("instantPost/LOADING_IMAGE", true, { root: true })
    game.surveyUploadImage(data, function (res) {
      if (res?.data?.success) {
        commit("UPLOAD_SUCCESS", res?.data)
        commit("instantPost/TOGGLE_SHOW_UPLOAD_IMAGE", true, { root: true })
        commit("instantPost/LOADING_IMAGE", false, { root: true })
        res?.data && router.push(`/survey/photos/${data?.get('targetId')}`)
        if (data?.get('surveyData')) {
          store.dispatch("survey/getVersion", data?.get('targetId'))
        } 
        store.dispatch("survey/getSurvey", res?.data.surveyId)
      }
    })
  },


  surveyUpdateImage({ commit }, data) {
    game.surveyUpdateImage(data, function (res) {
      if (res?.data?.data.success) {
        commit("UPDATE_IMAGE_SUCCESS", data)

        eventBus.$emit('showNotification', {
          active: true,
          type: "success",
          message: '写真タイプを更新しました'
        })
      }
    })
  },

  removeImage({ commit, rootState }, data) {
    game.surveyDeleteImage(data, function (res) {
      if (res?.data?.data.success) {
        commit("REMOVE_IMAGE_SUCCESS", data.imgId)

        if(Number(rootState.survey.dataSurvey.survey_type) !== 1) {
          store.dispatch("survey/getRoundSurvey", rootState.survey.dataSurvey.id)
        } 
        
        eventBus.$emit('showNotification', {
          active: true,
          type: "success",
          message: '写真を削除しました'
        })
      }

    })
  },

  addSurveyComment({ commit }, data) {
    game.addSurveyComment(data, function (res) {
      switch (res.code) {
        case 200:
          store.dispatch("survey/getSurveyComments", { id: data.id, page: 0 })
          commit("ADD_SURVEY_COMMENT_SUCCESS", res)

          break;
        case 404:
          commit("ADD_SURVEY_COMMENT_FAILED", res)
          router.push('/*').catch(() => { });
          break;
        default:
          commit("ADD_SURVEY_COMMENT_FAILED", res)
          break;
      }
    })
  },

  addRoundComment({ commit }, data) {
    game.addRoundComment(data, function (res) {
      switch (res.code) {
        case 200:
          store.dispatch("survey/getRoundComments", { id: data.id, page: 0 })
          commit("ADD_SURVEY_COMMENT_SUCCESS", res)

          break;
        case 404:
          commit("ADD_SURVEY_COMMENT_FAILED", res)
          router.push('/*').catch(() => { });
          break;
        default:
          commit("ADD_SURVEY_COMMENT_FAILED", res)
          break;
      }
    })
  },

  getSurveyComments({ commit, state }, params) {
    game.getSurveyComments(params, function (res) {
      switch (res.code) {
        case 200:
          commit("GET_SURVEY_COMMENTS", res.data)
          state.isLoadingComments = false
          break;
        case 404:
          eventBus.$emit("showNotification", {
            active: true,
            type: "error",
            message: "Not found",
          });
          router.push('/*').catch(() => { });
          break;
        default:
          state.isLoadingComments = true
          break;
      }
    })
  },

  getRoundComments({ commit, state }, params) {
    game.getRoundComments(params, function (res) {
      switch (res.code) {
        case 200:
          commit("GET_SURVEY_COMMENTS", res.data)
          state.isLoadingComments = false
          break;
        case 404:
          eventBus.$emit("showNotification", {
            active: true,
            type: "error",
            message: "Not found",
          });
          // router.push('/*').catch(() => { });
          break;
        default:
          state.isLoadingComments = true
          break;
      }
    })
  },

  getPost({ commit }, id) {
    return game.getPostDetail(id, function (res) {
      commit("GET_SURVEY", res);
    })
  },

  editAdminSurvey({ commit }, req) {
    game.editAdminSurvey(req, function (res) {
      if (res.data.data.success) {
        commit("UPDATE_SURVEY", res)
      }
    })
  },

  toggleChooseTypeImage({state}, payload) {
    state.showChooseTypeImage = payload
  }

};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
