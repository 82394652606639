<template>
    <div>
        <router-view></router-view>
        <toolbarCtrl ref="toolbar"></toolbarCtrl>
    </div>
</template>
<script>
import toolbarCtrl from '../../components/toolbarCtrl.vue'
export default {
    components: {
        toolbarCtrl
    }
}
</script>
<style lang="scss">
</style>