var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"list-user",attrs:{"dark":""}},[_c('v-card',[_c('v-card-title',{staticClass:"primary--text"},[_c('div',{staticClass:"list-user_groupButton search-filter mt-3 mb-3"},[_c('v-text-field',{staticClass:"mr-4",attrs:{"append-icon":"mdi-magnify","label":"検索","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('user-create',{attrs:{"type":_vm.isUsersAdminPage ? 'user' : 'government'}})],1),_c('v-data-table',{attrs:{"page":_vm.renderDataTable.currentPage,"pageCount":_vm.renderDataTable.currentPage + 1,"server-items-length":_vm.renderDataTable.totalItems,"items":_vm.renderDataTable.resources,"items-per-page":10,"footer-props":{
        'disable-items-per-page': true,
        'disable-pagination': _vm.renderDataTable.loading,
        'items-per-page-text': '１ページあたりの行数',
        'page-text': '{2}中の{0}-{1}'
      },"search":_vm.search,"headers":_vm.headers,"options":_vm.options,"loading":_vm.renderDataTable.loading,"loading-text":"読込中です...","no-data-text":"データがありません"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.active",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.active ? "有効" : "無効")+" ")]}},{key:"item.city",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.renderAreaName(item, "city"))+" ")]}},{key:"item.pref",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.renderAreaName(item, "pref"))+" ")]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2 item-view mt-5 mb-5",on:{"click":function($event){return _vm.handleView(item)}}},[_vm._v(" mdi-eye ")])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }