<template> 
  <v-container class="notifications">
		<Header title="Notifications" backHome/>
		<div class="mt-5 notifications_content">
			<infinity-scroll
				maxHeight="calc(100vh - 200px)"
				height="calc(100vh - 200px)"
				:isLoading="isLoadingNotifications"
				:loadData="loadDataNotifications"
				:hasLoadMore="hasLoadMoreNotifications"
				lightTheme
			>
				<div v-for="(item, i) in notifications" :key="i">
					<div v-if="renderDate(i)" class="notifications_date-title">
						{{ moment(item.createdAt).format("LL") }}
					</div>
					<notification-item :item="item"></notification-item>
				</div>
			</infinity-scroll>
		</div>
	</v-container>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import Header from "../../components/Header.vue";
import InfinityScroll from "../../components/InfinityScroll/InfinityScroll.vue";
import NotificationItem from '../../components/user/NotificationItem.vue';

export default {
	components: {
    Header,
		InfinityScroll,
		NotificationItem,
  },
	data: () => ({
		currentDate: "",
		isShowDate: true,
		page : 0
	}),
	async mounted() {
		this.$store.dispatch("global/getNotifications", {page: this.page})
		await this.$store.dispatch("global/getNotificationNotRead")
	},
	computed: {
    ...mapGetters([
			"notifications",
			"isLoadingNotifications",
			"hasLoadMoreNotifications",
    ]),
  },
	methods: {
		loadDataNotifications() {
			this.page++;
			this.$store.dispatch("global/getNotifications", { page: this.page });
		},
		renderDate(index) {
			if(index > 0) {
				return  moment(this.notifications[index-1].createdAt).format("LL") !==
				moment(this.notifications[index].createdAt).format("LL") 
			} else{
				return true
			}
		},
    moment(item) {
      return moment(item);
    },
	},
}
</script>

<style lang="scss" scoped>
.notifications{
	max-width: 800px;
	&_date-title{
		font-size: 12px;
		padding: 8px 0;
	}
}
</style>