<template>
  <v-app-bar
    app
    dark
    color="darken"
    :left="250"
    :dense="toolbarDense"
  >
    <v-app-bar-nav-icon @click.stop="toggleNavbar">
      <v-icon>{{ toggleNavbarIcon }}</v-icon>
    </v-app-bar-nav-icon>
    <p class="mb-0">{{ generateTitle() }}</p>
    <v-spacer />
    <profile />
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex';
import Profile from '@/components/widget/AppProfile.vue';

export default {
  name: 'TheLayoutToolbar',
  components: {
    Profile,
  },
  data: () => ({}),
  computed: {
    ...mapGetters([
      'toolbarDense',
      'navbarShow',
    ]),
    toggleNavbarIcon() {
      return this.navbarShow ? 'mdi-format-indent-decrease' : 'mdi-format-indent-increase';
    },
  },
  methods: {
    toggleNavbar() {
      this.$store.dispatch('admin/navbarToggle');
    },
    generateTitle() {
      const translatedTitle = this.$route.name
      return translatedTitle;
    }
  },
};
</script>
