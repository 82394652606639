<template>
  <v-container class="full-mobile">
    <v-card-text class="d-flex justify-center align-center pb-0 pt-6" v-if="!isAddTarget">
      <!-- Ledger manhole NO -->
      <span class="">{{$t('survey.manholeID')}}</span>
      <span disabled class="primary--text ml-3" height="40">{{renderIdTitle}}</span>
    </v-card-text>
    <v-card-text class="d-flex justify-center align-center pb-5 pt-0" v-if="!isAddTarget">
      <!-- Ledger manhole NO -->
      <span class="">{{$t('survey.ledgerManholeNo')}}</span>
      <span disabled class="primary--text ml-3" height="40">{{renderLedgerManholeNoTitle}}</span>
    </v-card-text>
    <v-tabs
      center-active
      centered
      class="sh-urbane"
      color="dark"
      background-color="transparent"
      grow
      hide-slider
      flat
      height="40"
      v-if="!isCommentPage"
    >
      <!-- manhole information -->
      <v-tab :to="`/survey/manhole-info/${renderLinkFirstTab}`">{{$t('survey.manholeInformation')}}</v-tab>
      <!-- survey contents -->
      <v-tab :to="`/survey/contents/${$route.params.id}`" v-if="!isAddTarget">{{$t('survey.inspectionItems')}}</v-tab>
      <v-tab @click="toggleDialogUnsave" v-else active-class="tab-disable">{{$t('survey.inspectionItems')}}</v-tab>
      <!-- survey photos -->
      <v-tab :to="`/survey/photos/${$route.params.id}`" v-if="!isAddTarget">{{$t('survey.inspectionImage')}}</v-tab>
      <v-tab @click="toggleDialogUnsave" v-else active-class="tab-disable">{{$t('survey.inspectionImage')}}</v-tab>
    </v-tabs>
    <selection-dialog
        :isOpen="isOpenWarningSave"
        :contents="[$t('instantPost.contentUnsaveFirst'), $t('instantPost.contentUnsaveSecond')]"
        :cancelBtn="false"
        :clickAccept="toggleDialogUnsave"
      />
  </v-container>
</template>

<script>
// import game from "@/game";
import { mapGetters } from "vuex";
import SelectionDialog from "../../components/modal/SelectionDialog.vue";

export default {
  components: {
    SelectionDialog
  },
  data: () => ({
    isOpenWarningSave: false
  }),

  async mounted() {
    this.isCommentPage
    if(this.$route.params.idSurvey){
      if(Number(this.$route.params.idType) === 2) {
        await this.$store.dispatch("survey/getRoundSurvey", this.$route.params.idSurvey) 
      } else {
        await this.$store.dispatch("survey/getSurvey", this.$route.params.idSurvey) 
      }
    }

    const isTarget = Number(this.dataSurvey.target_id) !== Number(this.$route.params.idTarget)
    if(this.dataSurvey.target_id && this.$route.params.idTarget && isTarget){
      this.$router.push({ path: "/*" })
    }

    if(!this.isAddTarget && this.targetId){
      await this.$store.dispatch("instantPost/getTargetById", this.targetId);
    }
  },
  methods: {
    toggleDialogUnsave() {
      this.isOpenWarningSave = !this.isOpenWarningSave
    }
  },
  computed: {
    ...mapGetters(["target", "dataSurvey"]),
    isAddTarget() {
      return this.$route.params.id === "add-target";
    },
    renderLinkFirstTab() {
      return this.$route.params.id === "add-target" ? `add-target?lat=${this.$route.query.lat}&lng=${this.$route.query.lng}` : this.$route.params.id;
    },
    isCommentPage() {
      return this.$route.name === "Survey Comment"
    },
    targetId() {
      return this.isCommentPage ? this.$route.params.idTarget : this.$route.params.id
    },
    renderIdTitle() {
      return this.targetId
    },
    renderLedgerManholeNoTitle() {
      return this.target?.ledger_manhole_no;
    }
  },
};
</script>

<style lang="scss" scoped>
.v-tab {
  font-size: 16px !important;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  background-color: #9298a8;
  overflow: hidden;
  width: 50px;
  line-height: 1.2;
  &--active {
    background-color: #81ffff;
  }
}
.custom-btn {
  border: 2px solid #27f8f9;
  margin-left: 32px;
  box-shadow: 0px 10px 20px #081539;
}

.tab-disable{
  background-color: #9298a8;
  color: white !important;
}
::v-deep .theme--dark.v-btn.v-btn--disabled {
  color: white !important;
  padding: 0 32px;
}

::v-deep .v-tabs-bar .v-tab:not(.v-tab--active) {
  color: white !important;
}
</style>
