<template>
  <v-container fluid class="admin-layout">
    <the-layout-drawer />
    <the-layout-toolbar />
    <transition
        name="fade-transform"
        mode="out-in"
      >
      <keep-alive>
        <router-view />
      </keep-alive>
    </transition>
  </v-container>
</template>

<script>
import TheLayoutDrawer from './TheLayoutDrawer.vue';
import TheLayoutToolbar from './TheLayoutToolbar.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    TheLayoutDrawer,
    TheLayoutToolbar,
  },

  async mounted() {
    await this.$store.dispatch("user/getStatus")
    if(this.currentUser.role === "user"){
      this.$router.push({ path: "/*" })
    }
  },

  computed: {
    ...mapGetters(["currentUser"]),
  }

}
</script>

<style lang="scss" scoped>
.admin-layout{
  min-height: calc(var(--vh, 1vh) * 100 - 48px);
}
</style>