<template>
  <v-container class="form-detail mt-4">
    <v-form ref="form" lazy-validation v-model="valid" :key="componentKey">
      <div
        v-if="isCreateNew"
        class="d-flex justify-space-between align-center pl-4 pr-4 mb-4"
      >
        <p class="primary--text mb-0">対象を選ぶ</p>
        <v-col cols="auto" class="pr-0 pt-0 pb-0">
          <v-autocomplete
            v-model="select"
            :items="listTargets.resources"
            :loading="listTargets.loading"
            :search-input.sync="search"
            color="white"
            item-text="label"
            item-value="id"
            solo
            placeholder="検索・選択"
            hide-no-data
            hide-details="auto"
            @input="selectData"
            :rules="targetRules"
            class="form-detail_autocomplete"
            outlined
          >
            <template v-slot:append-item>
              <div v-intersect="endIntersect" />
            </template>
          </v-autocomplete>
        </v-col>
      </div>
      <v-divider v-if="isCreateNew" class="mb-4"></v-divider>
      <v-card color="transparent" elevation="0" class="">
        <p class="primary--text pl-4 pr-4">{{ $t("survey.inspectInfo") }}</p>
        <div v-for="(info, i) in arrInspection" :key="i" class="pl-4 pr-4">
          <item-survey
            lightTheme
            :title="$t(info.title)"
            :data="info.data"
            :value="info.defaultValue"
            :type="info.type"
            :column="info.column"
            :unit="info.unit"
            :rules="info.rules"
            :nameData="(item) => $t(item.name)"
            valueData="id"
            :callbackSelect="selectType"
            :disabled="!isCreateNew && info.column === 'survey_type'"
          ></item-survey>
        </div>
        <v-tabs
          centered
          class="form-detail_tabs"
          background-color="transparent"
          grow
          flat
          height="40"
          v-if="!roundFormat"
        >
          <v-tab class="">{{ $t("survey.manholeCover") }}</v-tab>
          <v-tab class="">{{ $t("survey.insideManhole") }}</v-tab>
          <v-tab-item class="pl-4 pr-4">
            <v-card color="transparent" elevation="0" class="" dark>
              <p class="primary--text">
                {{ $t("survey.manhole_lid_inspection_details") }}
              </p>
              <p class="secondary--text">
                {{ $t("survey.installation_standard_conformity") }}
              </p>
              <div
                v-for="(info, i) in arrManholeLid"
                :key="`arrManholeLid1_${i}`"
              >
                <item-survey
                  lightTheme
                  :title="$t(info.title)"
                  :data="info.data"
                  :value="info.defaultValue"
                  :type="info.type"
                  :column="info.column"
                  :unit="info.unit"
                  :rules="info.rules"
                  :nameData="(item) => $t(item.name)"
                  valueData="id"
                ></item-survey>
              </div>
              <p class="secondary--text">
                {{ $t("survey.functional_problem") }}
              </p>
              <div
                v-for="(info, i) in arrManholeLid2"
                :key="`arrManholeLid2_${i}`"
              >
                <item-survey
                  lightTheme
                  :title="$t(info.title)"
                  :data="info.data"
                  :value="info.defaultValue"
                  :type="info.type"
                  :column="info.column"
                  :unit="info.unit"
                  :rules="info.rules"
                  :nameData="(item) => $t(item.name)"
                  valueData="id"
                ></item-survey>
              </div>
              <p class="secondary--text">
                {{ $t("survey.performance_degradation") }}
              </p>
              <div
                v-for="(info, i) in arrManholeLid3"
                :key="`arrManholeLid3_${i}`"
              >
                <item-survey
                  lightTheme
                  :title="$t(info.title)"
                  :data="info.data"
                  :value="info.defaultValue"
                  :type="info.type"
                  :column="info.column"
                  :unit="info.unit"
                  :rules="info.rules"
                  :nameData="(item) => $t(item.name)"
                  valueData="id"
                ></item-survey>
              </div>
              <surface-wear lightTheme :arrSurface="arrSurface"></surface-wear>
              <div
                v-for="(info, i) in arrManholeLid4"
                :key="`arrManholeLid4_${i}`"
              >
                <item-survey
                  lightTheme
                  :title="$t(info.title)"
                  :data="info.data"
                  :value="info.defaultValue"
                  :type="info.type"
                  :column="info.column"
                  :unit="info.unit"
                  :rules="info.rules"
                  :nameData="(item) => $t(item.name) || item.name"
                  valueData="id"
                  :placeholder="$t(info.placeholder)"
                ></item-survey>
              </div>
            </v-card>
          </v-tab-item>
          <v-tab-item class="pl-4 pr-4">
            <v-card color="transparent" elevation="0" class="" dark>
              <p class="primary--text">
                {{ $t("survey.inside_the_manhole_inspection_details") }}
              </p>
              <div v-for="(info, i) in arrInside1" :key="`arr1_${i}`">
                <item-survey
                  lightTheme
                  :title="$t(info.title)"
                  :data="info.data"
                  :value="info.defaultValue"
                  :type="info.type"
                  :column="info.column"
                  :unit="info.unit"
                  :rules="info.rules"
                  :nameData="(item) => $t(item.name)"
                  valueData="id"
                ></item-survey>
              </div>
              <div class="d-block mb-5">
                <v-img
                  :src="require('@/assets/GL-img.png')"
                  contain
                  width="320"
                  class="form-detail_img"
                />
              </div>
              <div v-for="(info, i) in arrInside2" :key="`arr2_${i}`">
                <item-survey
                  lightTheme
                  :title="$t(info.title)"
                  :data="info.data"
                  :value="info.defaultValue"
                  :type="info.type"
                  :column="info.column"
                  :unit="info.unit"
                  :rules="info.rules"
                  :nameData="(item) => $t(item.name)"
                  valueData="id"
                ></item-survey>
              </div>
              <div class="d-flex align-center mb-3" style="height: 40px">
                {{ $t("survey.judgment_result") }}
              </div>
              <div v-for="(info, i) in arrInside3" :key="`arr3_${i}`">
                <item-survey
                  lightTheme
                  :title="$t(info.title)"
                  :data="info.data"
                  :value="info.defaultValue"
                  :type="info.type"
                  :column="info.column"
                  :unit="info.unit"
                  :rules="info.rules"
                  :nameData="(item) => $t(item.name)"
                  valueData="id"
                  :placeholder="$t(info.placeholder)"
                ></item-survey>
              </div>
            </v-card>
          </v-tab-item>
        </v-tabs>
        <v-card v-else color="transparent" elevation="0" class="pl-4 pr-4">
          <p class="primary--text mt-11 mb-9">
            {{ $t("survey.manhole_lid_inspection_details") }}
          </p>
          <div class="d-flex align-center mb-3" style="height: 40px">
            {{ $t("survey.manhole_cover_type") }}
          </div>
          <div v-for="(info, i) in arrNewFormat1" :key="`arrNewFormat1_${i}`">
            <item-survey
              :title="$t(info.title)"
              :data="info.data"
              :value="info.defaultValue"
              :type="info.type"
              :column="info.column"
              :unit="info.unit"
              :rules="info.rules"
              :nameData="(item) => $t(item.name)"
              valueData="id"
              lightTheme
            ></item-survey>
          </div>
          <div class="d-flex align-center mb-7 mt-9" style="height: 40px">
            {{ $t("survey.damage_manhole_cover") }}
          </div>
          <div v-for="(info, i) in arrNewFormat2" :key="`arrNewFormat2_${i}`">
            <item-survey
              :title="$t(info.title)"
              :data="info.data"
              :value="info.defaultValue"
              :type="info.type"
              :column="info.column"
              :unit="info.unit"
              :rules="info.rules"
              :nameData="(item) => $t(item.name)"
              valueData="id"
              lightTheme
            ></item-survey>
          </div>
          <div class="d-flex align-center mb-7 mt-9" style="height: 40px">
            {{ $t("survey.surrounding_pavement") }}
          </div>
          <div v-for="(info, i) in arrNewFormat3" :key="`arrNewFormat3_${i}`">
            <item-survey
              :title="$t(info.title)"
              :data="info.data"
              :value="info.defaultValue"
              :type="info.type"
              :column="info.column"
              :unit="info.unit"
              :rules="info.rules"
              :nameData="(item) => $t(item.name)"
              valueData="id"
              lightTheme
            ></item-survey>
          </div>
          <p class="primary--text mb-9 mt-11">
            {{ $t("survey.above_ground") }}
          </p>
          <div class="d-flex align-center mb-7 mt-9" style="height: 40px">
            {{ $t("survey.judgment_result") }}
          </div>
          <div v-for="(info, i) in arrNewFormat4" :key="`arrNewFormat4_${i}`">
            <item-survey
              :title="$t(info.title)"
              :data="info.data"
              :value="info.defaultValue"
              :type="info.type"
              :column="info.column"
              :unit="info.unit"
              :rules="info.rules"
              :nameData="(item) => $t(item.name) || item.name"
              valueData="id"
              :placeholder="$t(info.placeholder)"
              lightTheme
            ></item-survey>
          </div>
        </v-card>
      </v-card>
      <div class="d-flex align-end justify-end form-detail_fixed-btn">
        <v-btn
          @click="submit"
          height="40"
          depressed
          :disabled="disabledBtn"
          class="mr-3 mb-10"
          color="primary dark--text"
          >{{ $t("profile.keep") }}</v-btn
        >
        <v-btn
          @click="goBack"
          height="40"
          depressed
          color="primary--text"
          class="mr-3 mb-10"
          >{{ $t("confirmDialog.cancel") }}</v-btn
        >
      </div>
    </v-form>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import ItemSurvey from "../../components/ItemSurvey.vue";
import {
  inspectInfo,
  manholeLidInfo,
  manholeLidInfo2,
  manholeLidInfo3,
  manholeLidInfo4,
  insideManhole,
  surfaceWear,
  newFormat,
} from "../survey/defaultData/dataSurveyContents";
import moment from "moment";
import SurfaceWear from "../survey/SurfaceWear.vue";
import _ from "lodash";

export default {
  components: { ItemSurvey, SurfaceWear },
  data: () => {
    return {
      valid: true,
      arrInspection: [],
      arrManholeLid: [],
      arrManholeLid2: [],
      arrManholeLid3: [],
      arrManholeLid4: [],
      submitData: [],
      arrInside1: [],
      arrInside2: [],
      arrInside3: [],
      arrSurface: [],
      componentKey: 0,
      search: null,
      select: null,
      page: 0,
      roundFormat: false,
      arrNewFormat1: [],
      arrNewFormat2: [],
      arrNewFormat3: [],
      arrNewFormat4: [],
      surveyType: 2,
    };
  },
  watch: {
    $route() {
      if (this.$route.params.id) {
        this.fetchData();
        this.forceRerender();
      }
    },
    search: _.debounce(function () {
      this.getListTargets();
    }, 500),
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.surveyType = Number(this.$route?.query?.type);
      if (this.isCreateNew) {
        this.getListTargets();
        await this.$store.dispatch("survey/createNew");
      } else {
        if (this.surveyType === 2) {
          await this.$store.dispatch(
            "survey/getRoundSurvey",
            this.$route.params.id
          );
        } else {
          await this.$store.dispatch("survey/getPost", this.$route.params.id);
        }
      }
      this.select = null;
      this.arrInspection = inspectInfo;
      this.arrManholeLid = manholeLidInfo;
      this.arrManholeLid2 = manholeLidInfo2;
      this.arrManholeLid3 = manholeLidInfo3;
      this.arrManholeLid4 = manholeLidInfo4;
      this.arrInside1 = insideManhole.slice(0, 5);
      this.arrInside2 = insideManhole.slice(5, 8);
      this.arrInside3 = insideManhole.slice(8);
      this.arrSurface = surfaceWear;
      this.arrNewFormat1 = newFormat.slice(0, 3);
      this.arrNewFormat2 = newFormat.slice(3, 7);
      this.arrNewFormat3 = newFormat.slice(7, 9);
      this.arrNewFormat4 = newFormat.slice(9);
      this.fillAllData();
    },
    forceRerender() {
      this.componentKey += 1;
    },
    async getListTargets() {
      await this.$store.dispatch("adminPosts/fetchTargets", {
        page: this.page,
        sortBy: "id",
        keyword: this.search,
      });
    },
    endIntersect(entries, observer, isIntersecting) {
      if (
        isIntersecting &&
        this.listTargets.currentPage + 1 < this.listTargets.totalPages
      ) {
        this.page += 1;
        this.getListTargets();
      }
    },
    fillData(arrData) {
      arrData.map((itemDefault) => {
        const bindingData = this.dataSurvey;
        this.surveyType = bindingData.survey_type;
        if (bindingData.survey_type === 2) {
          this.roundFormat = true;
        } else this.roundFormat = false;
        switch (itemDefault.type) {
          case "input":
          case "area":
            itemDefault.defaultValue = bindingData[itemDefault.column] || "";
            break;
          case "input-unit":
            itemDefault.defaultValue = bindingData[itemDefault.column] ?? "";
            break;
          case "select":
            itemDefault.defaultValue.id = bindingData[itemDefault.column] || "";
            break;
          case "date":
            itemDefault.defaultValue.id = bindingData[itemDefault.column]
              ? moment(bindingData[itemDefault.column])
                  .tz("Asia/Tokyo")
                  .format("YYYY-MM-DD HH:mm")
              : "";
            break;
          default:
            itemDefault.defaultValue = bindingData[itemDefault.column] || "";
            return itemDefault;
        }
        if (itemDefault.column === "investigator" && this.isCreateNew) {
          itemDefault.defaultValue = this.currentUser.nickname;
        }
        return itemDefault;
      });
    },
    fillAllData() {
      this.fillData(this.arrInspection);
      this.fillData(this.arrManholeLid);
      this.fillData(this.arrManholeLid2);
      this.fillData(this.arrManholeLid3);
      this.fillData(this.arrManholeLid4);
      this.fillData(this.arrInside1);
      this.fillData(this.arrInside2);
      this.fillData(this.arrInside3);
      this.fillData(this.arrSurface);
      this.fillData(this.arrNewFormat1);
      this.fillData(this.arrNewFormat2);
      this.fillData(this.arrNewFormat3);
      this.fillData(this.arrNewFormat4);
    },
    selectType(e, column) {
      if (column === "survey_type") {
        this.surveyType = e;
        if (e === 2) {
          this.roundFormat = true;
        } else this.roundFormat = false;
      }
    },
    submit() {
      this.$store.dispatch("survey/changeForm", {
        column: "investigator",
        value: this.currentUser.nickname,
      });
      const objFinal = this.isCreateNew
        ? { ...this.defaultForm, ...this.form }
        : this.form;
      if (this.isCreateNew) {
        if (this.surveyType === 2) {
          const req = {
            data: {
              target_id: this.select,
              isAdminCreate: true,
              ...objFinal,
            },
          };
          this.select
            ? this.$store.dispatch("survey/createRoundSurvey", req)
            : this.$refs.form.validate();
        } else {
          const req = {
            onCreate: true,
            targetId: this.select,
            surveyData: objFinal,
            isAdminCreate: true,
          };
          this.select
            ? this.$store.dispatch("survey/updateSurvey", req)
            : this.$refs.form.validate();
        }
      } else {
        if (this.surveyType === 2) {
          const req = {
            id: this.$route.params.id,
            data: objFinal,
            targetId: this.dataSurvey.target_id,
            isAdminCreate: true,
          };
          this.$store.dispatch("survey/updateRoundSurvey", req);
        } else {
          const req = {
            id: this.$route.params.id,
            body: objFinal,
          };
          this.$store.dispatch("survey/editAdminSurvey", req);
        }
        this.$router.push(
          `/admin/post/${this.$route.params.id}/photos?type=${this.surveyType}`
        );
      }
    },
    goBack() {
      this.$router.push("/admin/posts");
    },
    selectData() {
      if (this.select) {
        this.valid = true;
      }
    },
  },
  computed: {
    ...mapGetters([
      "dataSurvey",
      "form",
      "defaultForm",
      "enableSurveyBtn",
      "currentUser",
      "listTargets",
    ]),
    isCreateNew() {
      return this.$route.name === "新規投稿";
    },
    disabledBtn() {
      return !this.valid || (!this.isCreateNew && !this.enableSurveyBtn);
    },
    targetRules() {
      return [(v) => !!v || "既存一覧から選択"];
    },
  },
};
</script>
<style lang="scss" scoped>
.form-detail {
  padding: 0;
  position: relative;
  p {
    font-size: 16px;
  }
  &_dropdown {
    ::v-deep .v-input__slot {
      min-height: 40px !important;
      margin-right: 0;
      margin-left: auto;
    }

    ::v-deep .v-messages {
      text-align: right;

      &__message {
        line-height: 1.2;
      }
    }

    ::v-deep .v-text-field__details {
      padding-right: 0;
    }
  }

  &_tabs {
    ::v-deep .v-tabs-bar {
      .v-slide-group__wrapper {
        border-bottom: thin solid grey;
      }
      .v-tabs-slider-wrapper {
        color: #81ffff;
      }
      .v-tab {
        font-size: 16px !important;
      }
      .v-tab--active {
        color: #81ffff;
        background-color: transparent;
        border-bottom: 2px solid #81ffff;
      }
    }
    ::v-deep .v-tabs-items {
      background-color: transparent;
      margin-top: 32px;
      font-size: 12px;
    }
  }
  &_autocomplete {
    max-width: 40vw;
    ::v-deep .v-input__slot {
      min-height: 40px !important;
      margin-right: 0;
      margin-left: auto;
    }

    ::v-deep .v-messages {
      text-align: right;
      &__message {
        line-height: 1.3;
      }
    }

    ::v-deep .v-text-field__details {
      padding-right: 0;
    }
  }

  &_img {
    border-radius: 0 !important;
    margin: auto;
  }

  &_fixed-btn {
    position: sticky;
    bottom: 0;
    right: 0;
    width: 100%;
    padding-top: 1.5rem;
    background-color: #1E1E1E;
    border-radius: 0 0 12px 12px;
  }

  ::v-deep .v-select__selections input {
    display: none;
  }
  ::v-deep .v-text-field.v-text-field--solo .v-input__control {
    min-height: 40px;
    font-size: 16px;
  }

  ::v-deep .v-select__selections input {
    display: none;
  }

  ::v-deep .v-select__selection {
    max-width: 100%;
  }

  .v-text-field--outlined {
    ::v-deep fieldset {
      border-color: #27f8f9;
      box-shadow: 0px 10px 20px black;
    }
  }
}
</style>
