<template>
  <v-container>
    <v-card class="mx-auto manhole-info" outlined dark>
      <v-container class="full-mobile overflow-hidden">
        <leaf-let class="view-map" :manholeMap="true" :key="index" ref="mapv" />
      </v-container>
      <v-container>
        <v-form ref="form" lazy-validation v-model="valid">
          <v-card
            color="transparent"
            elevation="0"
            class="pr-3 pl-3 pt-2 mb-12"
          >
            <!-- Manhole lid basic information -->
            <p class="text-center">{{$t('survey.manholeID')}} {{ target.id }}</p>
            <item-survey
              lightTheme
              :title="$t('survey.ledgerManholeNo')"
              :value="this.target ? this.target.ledger_manhole_no : ''"
              type="input"
              column="ledger_manhole_no"
              valueData="ledger_manhole_no"
              ></item-survey>
            <p class="primary--text">{{ $t("survey.manholeLidBasic") }}</p>
            <div v-for="(info, i) in basicInfo" :key="i">
              <item-survey
                lightTheme
                :title="$t(info.title)"
                :data="info.data"
                :value="info.defaultValue"
                :type="info.type"
                :column="info.column"
                :nameData="(item) => $t(item.name)"
                valueData="id"
                :callbackSelect="selectData"
                :unit="info.unit"
                :rules="info.rules"
              ></item-survey>
            </div>
          </v-card>
          <v-card
            color="transparent"
            elevation="0"
            class="pr-3 pl-3 pt-2 mb-12"
          >
            <!-- Inside the manhole Basic information -->
            <p class="primary--text">{{ $t("survey.insideManholeBasic") }}</p>
            <div v-for="(info, i) in insideInfo" :key="i">
              <item-survey
                lightTheme
                :title="$t(info.title)"
                :data="info.data"
                :value="info.defaultValue"
                :type="info.type"
                :column="info.column"
                :nameData="(item) => $t(item.name)"
                valueData="id"
                :callbackSelect="selectData"
                :unit="info.unit"
                :rules="info.rules"
              ></item-survey>
            </div>
          </v-card>
          <v-card
            color="transparent"
            elevation="0"
            class="pr-3 pl-3 pt-2 mb-12"
          >
            <!-- Inspection area basic information -->
            <p class="primary--text">
              {{ $t("survey.inspectionAreaBasicInformation") }}
            </p>
            <div v-for="(info, i) in inspectionInfo" :key="i">
              <item-survey
                lightTheme
                :title="$t(info.title)"
                :data="info.data"
                :value="info.defaultValue"
                :type="info.type"
                :column="info.column"
                :nameData="(item) => $t(item.name)"
                valueData="id"
                :callbackSelect="selectData"
                :unit="info.unit"
                :rules="info.rules"
              ></item-survey>
            </div>
          </v-card>
          <div class="d-flex justify-space-between">
            <v-btn
              height="40"
              small
              color="error"
              class="sh-urbane dark--text ml-3"
              @click="() => $router.push('/admin/manholes')"
            >
              <span>
                {{ $t("confirmDialog.cancel") }}
              </span>
            </v-btn>
            <v-btn
              :disabled="disableSubmitButton()"
              @click="submit"
              height="40"
              depressed
              color="primary white--text btn-keep"
              class="mr-3 mb-10"
              >{{ $t("profile.keep") }}</v-btn
            >
          </div>
        </v-form>
      </v-container>
    </v-card>
  </v-container>
</template>
<script>
import ItemSurvey from "../../components/ItemSurvey.vue";
import LeafLet from "../../components/LeafLet.vue";
import {
  manholeLid,
  insideManhole,
  inspectionArea,
} from "../survey/defaultData/dataManholeInfo";
import game from "@/game";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  components: { ItemSurvey, LeafLet },
  data() {
    return {
      valid: true,
      index: 1,
      basicInfo: [],
      insideInfo: [],
      inspectionInfo: [],
      submitData: [],
    };
  },
  watch: {
    $route() {
      const id = this.$route.params.id;
      id && this.$route.name === "マンホール詳細" && this.fetchData()
    },
  },

  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch(
        "instantPost/getTargetById",
        this.$route.params.id
      );

      this.basicInfo = manholeLid;
      this.insideInfo = insideManhole;
      this.inspectionInfo = inspectionArea;
      this.fillData(this.basicInfo);
      this.fillData(this.insideInfo);
      this.fillData(this.inspectionInfo);
      game.playView = this;
      game.setPlayView(this);
    },
    fillData(arrData) {
      arrData.map((itemDefault) => {
        const fakeData = this.target;
        switch (itemDefault.column) {
          case "adjustment_ring_dimension":
            switch (fakeData[itemDefault.column]?.length) {
              case 0:
                itemDefault.defaultValue = ["", "", "", ""];
                break;
              default:
                itemDefault.defaultValue =
                  fakeData[itemDefault.column]?.split(",");
                break;
            }
            break;
          case "area_characteristic":
            itemDefault.defaultValue.id = JSON.parse(
              "[" + fakeData[itemDefault.column] + "]"
            );
            break;
          default:
            if (itemDefault.type === "input" || itemDefault.type === "input-unit") {
              itemDefault.defaultValue = fakeData[itemDefault.column];
            } else if (itemDefault.type === "date") {
              itemDefault.defaultValue.id = fakeData[itemDefault.column]
                ? moment(fakeData[itemDefault.column]).tz("Asia/Tokyo").format("YYYY-MM-DD HH:mm")
                : "";
            } else {
              itemDefault.defaultValue.id = fakeData[itemDefault.column];
            }
            return itemDefault;
        }
      });
    },
    selectData(value, column, arr) {
      if (column === "area_characteristic") {
        this.submitData.push([column, arr.id.toString()]);
      } else {
        this.submitData.push([column, arr?.length ? arr.toString() : value]);
      }
    },
    submit() {
      this.$store.dispatch("instantPost/editTarget", {
        target_id: this.$route.params.id,
        data: this.formInstantPost,
      });
    },
    onMapTypeClicked() {
      console.log("click manhole", game.getMapType());

      if (game.getMapType() == "roadmap") game.setMapType("satellite");
      else game.setMapType("roadmap");
      this.index++;
    },

    disableSubmitButton() {
      return !this.enableSubmitButton || !this.valid;
    },
  },
  computed: {
    ...mapGetters([
      "dataSurvey",
      "target",
      "formInstantPost",
      "defaultFormInstantPost",
      "enableSubmitButton",
    ]),
    toggleDeleteDialog: {
      get() {
        return this.$store.state.instantPost.toggleDeleteDialog;
      },
      set(value) {
        this.$store.commit("instantPost/TOGGLE_DELETE_DIALOG", value);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.manhole-info {
  p {
    font-size: 16px;
  }
  .view-map {
    ::v-deep #map-app {
      height: 25vh;
      position: relative;
      .leaflet-control-zoom,
      .leaflet-control-attribution {
        display: none;
      }
    }
  }

  .btn-keep {
    box-shadow: 0px 10px 20px black;
  }
}
</style>