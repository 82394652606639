<template>
  <div class="full-mobile">
    <v-tabs
      center-active
      centered
      class=""
      color="dark"
      background-color="transparent"
      grow
      hide-slider
      flat
      height="40"
    >
      <!-- survey contents -->
      <v-tab
        :to="`/admin/post/${$route.params.id}/info?type=${$route.query.type}`"
        >{{ $t("survey.inspectionItems") }}</v-tab
      >
      <!-- survey photos -->
      <v-tab
        :to="`/admin/post/${$route.params.id}/photos?type=${$route.query.type}`"
        >{{ $t("survey.inspectionImage") }}</v-tab
      >
      <v-tab
        :to="`/admin/post/${$route.params.id}/comments?type=${$route.query.type}`"
      >
        <span
          >{{ $t("survey.comment") }}
          <span class="quantity-comments">{{
            totalCommentsCount > 99 ? "99+" : totalCommentsCount
          }}</span>
        </span>
      </v-tab>
    </v-tabs>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import game from "@/game";

export default {
  components: {},
  data: () => ({}),
  mounted() {},
  methods: {},
  computed: {
    ...mapGetters(["totalCommentsCount"]),
  },
};
</script>

<style lang="scss" scoped>
.v-tab {
  font-size: 16px !important;
  background-color: rgba(0, 0, 0, 0.2);
  overflow: hidden;
  width: 50px;
  line-height: 1.2;

  &--active {
    background-color: #81ffff;
  }
}

::v-deep .theme--dark.v-btn.v-btn--disabled {
  color: white !important;
  padding: 0 32px;
}

::v-deep .v-tabs-bar .v-tab:not(.v-tab--active) {
  color: white !important;
}

.quantity-comments {
  font-size: 8px;
  margin: 0 2px;
  background-color: #d5482f;
  padding: 4px;
  border-radius: 100%;
  color: white;
}
</style>
