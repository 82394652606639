//import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import IconInvite from '../assets/IconInvite.vue'
import IconQR from '../assets/IconQR.vue'
import IconUpload from '../assets/IconUpload.vue'
import IconStop from '../assets/IconStop.vue'
import IconNext from '../assets/IconNext.vue'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: true,
        themes: {
            light: {
                primary: '#000',
                gray: '#707070',
            },
            dark: {
                background: '#ff6600',
                surface: '#434343',
                primary: '#81ffff',
                secondary: '#AACCff',
                white: '#fff',
                gray: '#707070',
                // secondary: '',
                // accent: '',
                success: '#00D39f',
                // info: '',
                // warning: '',
                error: '#ff9669',
                dark: '#182750',
                bgGeneral: '#3D4A6C',
                darken: '#001339'
            }
        },
        options: {
            //customProperties: true
        },
    },
    icons: {
        //iconfont: 'mdi', // default - only for display purposes
        values: {
            iconInvite: {
                component: IconInvite
            },
            iconQR: {
                component: IconQR
            }, 
            iconUpload: {
                component: IconUpload
            },
            iconStop: {
                component: IconStop
            },
            iconNext: {
                component: IconNext
            }
        },
    },
});