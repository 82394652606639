import game from "@/game";
import router from "../../router";
import i18n from '../../i18n.js';
import { eventBus } from '../../common/eventBus.js';

const state = {
  status: {},
  currentUser: {},
  changeUserSettingSuccess: {},
  enableCamera: false,
};

const mutations = {
  SET_STATUS: (state, payload) => {
    if (payload.code === 200) {
      state.status = payload.data.user;
      state.currentUser = payload.data.user;
      state.enableCamera = true
    } else {
      router.push({ path: "/accounts/signin" });
    }
  },
  CHANGE_USER_SETTING_SUCCESS: (state, payload) => {
    state.currentUser = { ...state.currentUser, nickname: payload.data.user.nickname }
    state.changeUserSettingSuccess = payload
  },
  CHANGE_USER_SETTING_FAILED: (state, payload) => {
    state.changeUserSettingSuccess = payload
  },
};

const actions = {
  getStatus({ commit }) {
    return game.loadStatus(function (res) {
      commit("SET_STATUS", res);
    });
  },
  changeUserSetting({ commit }, data) {
    game.changeUserSetting(data.nickname, data.password, function (res) {
      switch (res.code) {
        case 200:
          commit("CHANGE_USER_SETTING_SUCCESS", res);
          eventBus.$emit('showNotification', {
            active: true,
            type: "success",
            timeout: 3000,
            message: i18n.t(`messageNotification.${data.nickname ? 'updateUsername' : 'updatePassword'}`)
          })
          break;
        default:
          commit("CHANGE_USER_SETTING_FAILED", res);
          switch (true) {
            case res.error.includes("nickname_is_exist"):
              eventBus.$emit('showNotification', {
                active: true,
                type: "error",
                timeout: 3000,
                message: i18n.t('messageNotification.nicknameExist')
              })
              break;
            case res.error.includes("nickname_contains_profane_language"):
              eventBus.$emit('showNotification', {
                active: true,
                type: "error",
                timeout: 3000,
                message: i18n.t('messageNotification.nicknameProfane')
              })
              break;
            case res.error.includes("old_password_is_incorrect"):
              eventBus.$emit('showNotification', {
                active: true,
                type: "error",
                timeout: 3000,
                message: i18n.t('messageNotification.oldPassword')
              })
              break;
            case res.error.includes("new_password_must_be_different_old_password"):
              eventBus.$emit('showNotification', {
                active: true,
                type: "error",
                timeout: 3000,
                message: i18n.t('validate.newPassword')
              })
              break;
            default:
              eventBus.$emit('showNotification', {
                active: true,
                type: "error",
                timeout: 3000,
                message: res.error
              })
              break;

          }
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
