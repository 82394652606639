
import game from "@/game"

const state = {
  language: '',
  notifications: [],
  isLoadingNotifications: false,
  hasLoadMoreNotifications: false,
  totalNotiCount: 0,
  isHideCamera: false,
  toggleMenu: false,
  totalNotificationsNotRead: 0,
};

const getters = {
  toggleMenu: state => state.global.toggleMenu,
}

const mutations = {
  SET_LANGUAGE: (state, payload) => {
    state.language = payload
  },
  NOTIFICATIONS: (state, payload) => {
    state.hasLoadMoreNotifications = payload.currentPage + 1 < payload.totalPages
    if(payload.currentPage === 0){
      state.notifications = payload.resources
    } else{
      state.notifications = [...state.notifications, ...payload.resources]
    }
    state.totalNotiCount = payload.totalItems
  },
  HIDE_CAMERA: (state, payload) => {
    state.isHideCamera = payload
  },
  TOGGLE_MENU: (state, payload) => {
    state.toggleMenu = payload
  },
  TOTAL_NOTIFICATIONS: (state, payload) => {
    state.totalNotificationsNotRead = payload.count
  },
  READ_NOTIFICATION: (state) => {
    state.totalNotificationsNotRead = state.totalNotificationsNotRead - 1
  }
};

const actions = { 
  changeLanguage({ commit }, payload) {
    commit("SET_LANGUAGE", payload.language);
    payload.i18n.locale = payload.language
    localStorage.setItem('language', payload.language)
  },
  getNotifications({ commit }, params) {
    game.loadNotifications( params, function (res) {
      commit("NOTIFICATIONS", res.data);
    });
  },
  getNotificationNotRead({commit}) {
    game.getNotificationNotRead(function (res) {
      commit("TOTAL_NOTIFICATIONS", res.data)
    });
  },
  readNotification({commit}, params) {
    game.readNotification( params, function (res) {
      switch(res.code) {
        case 200:
          commit("READ_NOTIFICATION", res.data)
          break
        default:
          break;
      }
    });
  },
  toggleHideCamera({commit}, action) {
    commit("HIDE_CAMERA", action);
  },
  toggleMenuAction({commit}, action) {
    commit("TOGGLE_MENU", action);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
