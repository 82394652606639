<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500" persistent>
      <v-card light class="card-custom">
        <v-card-title class="justify-content-center mb-3">
          {{ title }}
          <v-btn
            fab
            dark
            small
            depressed
            color="primary"
            class="absolute"
            @click="dialog = false"
          >
            <v-icon color="dark"> mdi-window-close </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text
          class="pb-3 pl-0 pr-0"
          v-for="content in contents"
          :key="content"
        >
          {{ content }}
        </v-card-text>

        <v-card-actions class="justify-content-center mt-3">
          <v-btn
            v-if="textBtn"
            class="pl-5 pr-5"
            color="primary dark--text"
            :to="linkBtn"
          >
            {{ textBtn }}
          </v-btn>
          <v-btn
            class="pl-5 pr-5"
            color=" dark--text grey lighten-1"
            v-if="cancelBtn || closeBtn"
            @click="dialog = false"
          >
            {{ closeBtn ? this.$t("groups.close") : this.$t("groups.cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { eventBus } from "../../common/eventBus";
export default {
  props: {
    title: { type: String, required: false },
    contents: { type: Array, required: false },
    textBtn: { type: String, required: false },
    cancelBtn: { type: Boolean, required: false },
    closeBtn: { type: Boolean, required: false },
    linkBtn: { type: String, required: false },
  },

  data() {
    return {
      dialog: false,
    };
  },

  created() {
    eventBus.$on("showConfirmDialog", () => {
      this.dialog = !this.dialog;
    });
  },

  beforeDestroy() {
    // removing eventBus listener
    eventBus.$off("showConfirmDialog");
  },
};
</script>

<style lang="scss" scoped>
.card-custom {
  padding: 19px;
  text-align: center;
  .justify-content-center {
    justify-content: center;
  }
}
</style>
