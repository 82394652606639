<template>
  <div>
    <v-container> <Header :backHome="this.$route.name !== 'Survey Comment'" :title="titleHeader" /> </v-container>
    <survey-ctrl></survey-ctrl>
    <router-view></router-view>
    <toolbarCtrl ref="toolbar"></toolbarCtrl>
  </div>
</template>
<script>
import Header from "../../components/Header.vue";
import toolbarCtrl from "../../components/toolbarCtrl.vue";
import SurveyCtrl from "./SurveyCtrl.vue";
export default {
  components: {
    toolbarCtrl,
    Header,
    SurveyCtrl,
  },
  data: () => ({
    titleHeader: ""
  }),
  created() {
    this.renderHeader()
  },
  watch: {
    $route() {
      this.renderHeader()
    },
  },
  methods: {
    onClickBack() {
      this.$router.push(`/user/play`);
    },
    renderHeader() {
      switch(this.$route.name){
        case "Survey Comment":
          this.titleHeader = this.$t('survey.comment')
          break
        default:
          this.titleHeader = this.$t('survey.header')
          break
      }
    }
  }
};
</script>
<style lang="scss">
</style>