<template>
  <div class="surface-wear" :style="cssProps">
    <v-img
      :src="require('@/assets/surface-wear.png')"
      contain
      width="180"
      class="surface-wear_img"
    />
    <div v-for="(info, i) in arrSurface" :key="i">
      <item-survey
        :lightTheme="lightTheme"
        :title="info.title"
        :data="info.data"
        :value="info.defaultValue"
        :type="info.type"
        :column="info.column"
        :unit="info.unit"
        :rules="info.rules"
        nameData="name"
        valueData="id"
        :disabled="disabled"
      ></item-survey>
    </div>
    <v-col cols="auto" class="pr-0 pt-0 pb-0 position-relative">
      <v-text-field
        dense
        class="surface-wear_input-unit"
        hide-details="auto"
        solo
        :background-color="lightTheme ? 'transparent' : 'bgGeneral'"
        disabled
        :value="avgData"
        outlined
      >
      </v-text-field>
      <div
        class="surface-wear_input-unit--text d-flex align-center"
        style="color: rgba(255, 255, 255, 0.5)"
      >
        mm
      </div>
    </v-col>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ItemSurvey from "../../components/ItemSurvey.vue";
export default {
  components: { ItemSurvey },
  props: {
    arrSurface: Array,
    lightTheme: Boolean,
    disabled: Boolean,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {
    ...mapGetters(["surface"]),
    avgData() {
      const sumData = Object.values(this.surface);
      const avg =
        sumData?.length > 0 && sumData?.filter(el => el !== null && el !== '').length === sumData?.length
          ? sumData.reduce((prev, cur) => Number(prev) + Number(cur)) /
            sumData.length
          : null;
      return avg;
    },
    cssProps() {
      return {
        "--box-shadow": this.lightTheme
          ? "0px 10px 20px black"
          : "0px 10px 20px #081539",
      };
    },
  },
  updated() {},
};
</script>
<style lang="scss" scoped>
.surface-wear {
  position: relative;
  margin-bottom: 20px;
  &_img {
    position: absolute;
    top: 0;
    left: 0;
  }
  &_input-unit {
    ::v-deep .v-input__slot {
      max-width: 100px;
      padding-right: 40px !important;
    }
    ::v-deep .v-input__slot {
      min-height: 40px !important;
      margin-right: 0;
      margin-left: auto;
    }

    ::v-deep .v-messages {
      text-align: right;
      max-width: 60vw;
      &__message {
        line-height: 1.3;
      }
    }

    ::v-deep .v-text-field__details {
      padding-right: 0;
    }
    &--text {
      position: absolute;
      top: 10px;
      right: 10px;
    }

    ::v-deep .v-text-field__slot input {
      text-align: right;
    }
  }
}
::v-deep .v-messages {
  max-width: 40vw !important;
}
.v-text-field--outlined {
  ::v-deep fieldset {
    box-shadow: var(--box-shadow);
  }
}
</style>