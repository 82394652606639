<template>
  <v-toolbar color="transparent" class="header-toolbar" dark flat fixed>
    <v-btn
      fab
      dark
      x-small
      depressed
      color="primary"
      class="header-toolbar_btn-back"
      @click="goBack"
    >
      <v-icon color="dark" size="32" v-if="iconBack"> mdi-chevron-left </v-icon>
      <v-icon color="dark" size="28" v-else> mdi-close </v-icon>
    </v-btn>
    <v-card-text class="flex-row text-center pb-0 pt-6" v-if="title">
      <span class="primary--text header-toolbar_title">{{ title }}</span>
    </v-card-text>
  </v-toolbar>
</template>
<script>
export default {
  props: {
    iconBack: Boolean,
    title: String,
    backHome: Boolean
  },
  methods: {
    goBack() {
      if (window.history.length > 2 && !this.backHome) {
        return this.$router.go(-1);
      } else return this.$router.push("/user/play");
    }
  }
};
</script>
<style lang="scss" scoped>
.header-toolbar {
  position: relative;
  text-align: center;
  &_btn-back {
    position: absolute;
    right: 0;
  }
  &_title {
    font-size: 20px;
  }
}
</style>