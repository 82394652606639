<template>
  <v-container class="pt-10 custom-container">
    <div class="profile-container">
      <div class="profile-container_scroll">
        <Header iconBack />
        <div class="profile-container_logo-user">
          <v-avatar size="132" class="profile-container_logo-user--avatar">
            <v-img
              alt="user avatar"
              contain
              :src="require('@/assets/user_setting.png')"
              transition="scale-transition"
              class="profile-container_logo-user--avatar-img"
            />
            <v-btn
              depressed
              small
              width="56"
              color="primary dark--text"
              class="profile-container_logo-user--avatar-btn sh-urbane"
              >{{ currentUser.id }}</v-btn
            >
          </v-avatar>
        </div>
        <v-card-text class="flex-row text-center pt-2">
          <span class="primary--text profile-container_title">{{
            currentUser.nickname
          }}</span>
          <v-form
            ref="usernameForm"
            lazy-validation
            class="profile-container_form"
            v-model="usernameValid"
          >
            <label class="primary--text">{{ $t("setting.username") }}</label>
            <v-text-field
              :rules="usernameRules"
              background-color="#3d4a6c"
              class="mb-4 sh-textfield"
              dense
              hide-details="auto"
              solo
              :label="$t('setting.username')"
              outlined
              v-model="username"
              ref="username"
            >
            </v-text-field>
            <v-btn
              height="40"
              block
              depressed
              color="dark--text"
              class="mb-5 mt-5 profile-container_form--keep-btn"
              @click="updateUsername"
            >
              {{ $t("setting.updateProfile") }}
            </v-btn>
          </v-form>
          <v-form
            ref="passwordForm"
            lazy-validation
            class="profile-container_form"
            v-model="passwordValid"
          >
            <label class="primary--text">{{ $t("setting.newPassword") }}</label>
            <v-text-field
              :rules="newPasswordRules"
              autocomplete="new-password"
              background-color="#3d4a6c"
              class="mb-4 sh-textfield"
              dense
              hide-details="auto"
              solo
              :label="$t('setting.newPassword')"
              outlined
              v-model="new_password"
              ref="new_password"
              type="password"
            >
            </v-text-field>
            <label class="primary--text">{{
              $t("setting.confirmNewPassword")
            }}</label>
            <v-text-field
              :rules="confirmNewPasswordRules"
              autocomplete="off"
              background-color="#3d4a6c"
              class="mb-4 sh-textfield"
              dense
              hide-details="auto"
              solo
              :label="$t('setting.confirmNewPassword')"
              outlined
              v-model="confirm_newpassword"
              ref="confirm_newpassword"
              type="password"
            >
            </v-text-field>
            <v-btn
              height="40"
              block
              depressed
              color="dark--text"
              class="mb-1 mt-5 profile-container_form--keep-btn"
              @click="updatePassword"
            >
              <!-- {{ $t("profile.keep") }} -->
              {{ $t("setting.updatePassword") }}
            </v-btn>
          </v-form>
        </v-card-text>
      </div>
      <div class="profile-container_btn-logout">
        <v-btn
          height="40"
          block
          depressed
          color="primary dark--text"
          class=""
          @click="logOut"
        >
          {{ $t("setting.logOut") }}
        </v-btn>
      </div>
    </div>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import Header from "../../components/Header.vue";

export default {
  components: { Header },
  data: () => ({
    username: "",
    new_password: "",
    confirm_newpassword: "",
    usernameValid: false,
    passwordValid: false,
  }),
  watch: {
    changeUserSettingSuccess() {
      if (this.changeUserSettingSuccess?.code === 200) {
        this.$refs.new_password.reset();
        this.$refs.confirm_newpassword.reset();
        this.$refs.username.reset();
      }
    },
  },
  async mounted() {},
  methods: {
    updateUsername() {
      this.$refs.usernameForm.validate() &&
        this.$store.dispatch("user/changeUserSetting", {
          nickname: this.username,
        });
    },
    updatePassword() {
      this.$refs.passwordForm.validate() &&
        this.$store.dispatch("user/changeUserSetting", {
          password: this.new_password,
        });
    },
    logOut() {
      this.$store.dispatch("authen/signOut");
    },
  },
  computed: {
    ...mapGetters(["currentUser", "changeUserSettingSuccess"]),
    usernameRules() {
      return [
        (v) => (v && v.length <= 50) || this.$t("validate.usernameLess"),
        (v) => /^[A-Za-z0-9_]+$/.test(v) || this.$t("validate.usernameAccept"),
      ];
    },
    newPasswordRules() {
      return [
        (v) => (v && v.length >= 6) || this.$t("validate.passwordRule"),
        (v) => (v && v.length <= 30) || this.$t("validate.passwordRule"),
        this.new_password !== this.current_password ||
          this.$t("validate.newPassword"),
      ];
    },
    confirmNewPasswordRules() {
      return [
        (v) => v === this.new_password || this.$t("signUp.passwordMatch"),
        this.confirm_newpassword === this.new_password ||
          this.$t("signUp.passwordMatch"),
      ];
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-container {
  min-height: 100vh;
  height: 100vh;
}
.profile-container {
  padding: 20px;
  padding-bottom: 80px;
  height: 100%;
  position: relative;
  &_scroll {
    height: 100%;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &_logo-user {
    margin-top: -48px;
    display: flex;
    &--avatar {
      margin: auto;
      position: relative;
      overflow: unset;
      // background-color: aquamarine;
      &-img {
        ::v-deep .v-image__image {
          background-size: contain;
        }
      }
      &-btn {
        position: absolute;
        bottom: -4px;
        left: 16px;
        font-size: 16px;
        border: 4px solid #182750;
        ::v-deep &.primary {
          border-color: #182750 !important;
        }
      }
    }
  }
  &_title {
    font-size: 18px;
  }
  &_form {
    margin-top: 45px;
    label {
      font-size: 12px;
    }
    &--keep-btn {
      background: transparent linear-gradient(180deg, #81ffff 0%, #434fe2 100%)
        0% 0% no-repeat padding-box !important;
      font-size: 12px;
    }
    ::v-deep .v-messages__message {
      line-height: 1.5;
    }
    ::v-deep .v-text-field__details {
      margin-bottom: 0;
    }
  }
  &_btn-logout {
    display: flex;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 20px;
  }
}

.v-text-field--outlined {
  ::v-deep fieldset {
    border-color: #81ffff;
    box-shadow: 0px 10px 20px #081539;
  }
  &.v-input--has-state ::v-deep fieldset {
    border-color: currentColor;
  }
}
</style>