<template>
  <component :is="layout">
    <router-view :layout.sync="layout"/>
  </component>
</template>
<script>

import LayoutAuthentication from '../components/layouts/LayoutAuthentication.vue'
export default {
  data() {
    return {
      layout: LayoutAuthentication,
    };
  },
}
</script>
<style lang="scss" scoped>

</style>