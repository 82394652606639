import { render, staticRenderFns } from "./PostDetailCtrl.vue?vue&type=template&id=b96eca08&scoped=true&"
import script from "./PostDetailCtrl.vue?vue&type=script&lang=js&"
export * from "./PostDetailCtrl.vue?vue&type=script&lang=js&"
import style0 from "./PostDetailCtrl.vue?vue&type=style&index=0&id=b96eca08&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b96eca08",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VTab,VTabs})
