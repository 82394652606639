<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="4">
        <v-card flat color="dark">
          <v-card-text class="pb-2 text-center">
            <router-link to="/">
              <v-avatar color="dark" size="160" tile>
                <v-img
                  alt="user avatar"
                  class="shrink"
                  contain
                  :src="require('../assets/shibuya-brand.svg')"
                  transition="scale-transition"
                  width="160"
                  height="140"
                />
              </v-avatar>
            </router-link>
          </v-card-text>

          <v-card-title
            class="pb-4 primary--text subtitle-2 text-uppercase font-weight-black justify-center"
          >
            <span v-if="isConfirmToken">SET YOUR PASSWORD</span>
            <span v-else>{{ $t('signUp.resetPassword') }}</span>
          </v-card-title>

          <v-card-text class="pb-0">
            <v-form ref="form" lazy-validation>
              <v-text-field
                :label="$t('signIn.password')"
                type="password"
                outlined
                dense
                class="mb-2 sh-textfield"
                v-model="password"
                hide-details="auto"
                autocomplete="off"
                :rules="passwordRules"
              >
              </v-text-field>
              <v-text-field
                :label="$t('signUp.confirmPassword')"
                type="password"
                outlined
                dense
                class="mb-2 sh-textfield"
                v-model="confirm"
                hide-details="auto"
                autocomplete="off"
                :rules="confirmPasswordRules"
              >
              </v-text-field>
              <v-btn
                block
                depressed
                color="primary dark--text"
                class="mb-2 mt-4"
                v-on:click="onSetUpPassword()"
                :disabled="!isNotValid"
              >
                <span v-if="isConfirmToken">SET PASSWORD</span>
                <span v-else>{{ $t('signUp.resetPassword') }}</span>
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import { eventBus } from '../common/eventBus'
export default {
  data: function() {
    return {
      password: "",
      confirm: "",
    };
  },
  mounted: function() {
    this.checkExpired();
    this.checkVerified();
  },
  watch: {
    language: {
      deep: true,
      handler() {
        this.$refs.form.reset()
      }
    },
    responseValidToken() {
      switch (this.responseValidToken.error) {
        case "invalid_confirmation_email":
            eventBus.$emit('showNotification', {
                active: true,
                type: "success",
                message: this.$t('messageNotification.verifyConfirmEmail'),
            })
            this.$router.push({path: "/accounts/signin"});
            break;
        case "invalid_token":
            eventBus.$emit('showNotification', {
                active: true,
                type: "error",
                message: this.$t('messageNotification.verifyInvalidToken'),
            })
            this.$router.push({path: "/accounts/resetpassword"});
            break;
        default:
          eventBus.$emit('showNotification', {
                active: false
            })
          break;
      }
    },
    responseActiveEmail() {
      if (!this.responseActiveEmail.data.user_id) return;
      this.$cookie.set("user_id", this.responseActiveEmail.data.user_id);
      this.$router.push({ path: "/user/play" });
    }
  },
  methods: {
    onSetUpPassword() {
      console.log("setupbutton", this.password, this.confirm);
      let tokenConfirm = this.$route.query?.confirmation_token;
      let tokenResetPassword = this.$route.query?.reset_password_token;
      tokenConfirm && this.$store.dispatch("authen/activeEmail", {token: tokenConfirm, password: this.password});
      tokenResetPassword && this.$store.dispatch("authen/resetPassword", {tokenResetPassword: tokenResetPassword, password: this.password});
    },
    checkExpired() {
      var _this = this;
      let tokenResetPassword = _this.$route.query?.reset_password_token;
      tokenResetPassword && this.$store.dispatch("authen/checkExpiredResetPassword", {token: tokenResetPassword});
    },
    async checkVerified () {
        var _this = this;
        let token = _this.$route.query?.confirmation_token
        token && this.$store.dispatch("authen/verifyEmail", token);
    }
  },
  computed: {
    ...mapGetters(["language", "responseValidToken", "responseActiveEmail"]),
    passwordRules() {
      return [
        v => (v && v !== '') || this.$t('signIn.passwordRequired'),
        v => (v && v.length >= 6) || this.$t('signUp.validateLengthPassword'),
        v => (v && v.length <= 30) || this.$t('signUp.validateLengthPassword')
      ]
    },

    confirmPasswordRules() {
      return [
        (v) => v === this.password || this.$t('signUp.passwordMatch'),
        this.password === this.confirm || this.$t('signUp.passwordMatch')
      ];
    },

    isNotValid() {
      return this.password && this.confirm && this.password === this.confirm;
    },

    isConfirmToken() {
      return this.$route.query?.confirmation_token;
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  padding-bottom: 0;
}
.v-card__text {
  padding-bottom: 0;
}
</style>
