<template>
  <v-container class="manholes">
    <Header title="マンホール" backHome />
    <div class="manholes_groupButton search-filter mt-3 mb-3">
      <item-survey
        :data="listFilter"
        :value="searchBy"
        :type="'select'"
        :callbackSelect="selectFilter"
        nameData="name"
        valueData="value"
      ></item-survey>
      <v-text-field
        append-icon="mdi-magnify"
        label="検索"
        single-line
        hide-details
        class="mt-0"
        v-model="search"
      ></v-text-field>
      <v-icon
        color="primary"
        size="32"
        v-model="sortType"
        class="ml-3"
        @click="handleSort"
        >{{ iconSort }}</v-icon
      >
    </div>
    <div class="mt-5 manholes_content">
      <infinity-scroll
        maxHeight="calc(100vh - 200px)"
        height="calc(100vh - 200px)"
        :isLoading="isLoadingManholes"
        :loadData="loadDataManholes"
        :hasLoadMore="hasLoadMoreManholes"
        lightTheme
      >
        <div v-for="(item, i) in manholesUser" :key="i">
          <manhole-item :item="item" />
        </div>
      </infinity-scroll>
    </div>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import Header from "../../components/Header.vue";
import InfinityScroll from "../../components/InfinityScroll/InfinityScroll.vue";
import ManholeItem from "../../components/user/ManholeItem.vue";
import ItemSurvey from "../../components/ItemSurvey.vue";
import _ from "lodash";

export default {
  components: {
    Header,
    InfinityScroll,
    ManholeItem,
    ItemSurvey,
  },
  data: () => ({
    currentDate: "",
    search: "",
    isShowDate: true,
    page: 0,
    iconSort: "mdi-sort-descending",
    searchBy: "id",
    sortType: "DESC",
  }),
  watch: {
    search: _.debounce(function () {
      this.page = 0
      this.fetchData();
      this.scorllTop()
    }, 500),
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    ...mapGetters(["manholesUser", "isLoadingManholes", "hasLoadMoreManholes", "currentUser"]),
    listFilter() {
      const listFilter = [];
      listFilter.push({
        name: "マンホールID",
        value: "id",
      });
      listFilter.push({
        name: this.$t('survey.ledgerManholeNo'),
        value: "ledger_manhole_no",
      });
      listFilter.push({
        name: "作成者名",
        value: "nickname",
      });
      if (this.currentUser.role === 'admin') {
        listFilter.push({
          name: "都道府県",
          value: "pref",
        });
        listFilter.push({
          name: "市区町村",
          value: "city",
        });
      }
      listFilter.push({
        name: "作成日",
        value: "createdAt",
      });
      return listFilter;
    },
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch("instantPost/getListManholesUser", {
        page: this.page,
        listManhole: true,
        keyword: this.search,
        sortType: this.sortType,
        searchBy: this.searchBy,
        sortBy: this.searchBy,
      });
    },
    scorllTop() {
      const listElm = document.querySelector("#infinity-list");
      console.log(listElm)
      listElm.scrollTo(0,0)
    },
    loadDataManholes() {
      this.page++;
      this.fetchData();
    },
    moment(item) {
      return moment(item);
    },
    handleSort() {
      this.sortType = this.sortType === "DESC" ? "ASC" : "DESC";
      this.iconSort =
        this.sortType === "ASC" ? "mdi-sort-ascending" : "mdi-sort-descending";
      this.page = 0;
      this.fetchData();
      this.scorllTop()
    },
    selectFilter(e) {
      this.searchBy = e;
      this.page = 0;
      this.fetchData()
      this.scorllTop()
    },
  },
};
</script>

<style lang="scss" scoped>
.manholes {
  max-width: 800px;
  &_date-title {
    font-size: 12px;
    padding: 8px 0;
  }
  &_groupButton {
    width: auto;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin: 0 auto;
    @media (max-width: 414px) {
      width: 100%;
    }
    @media (max-width: 810px) {
      &.search-filter {
        width: 100%;
      }
    }
    ::v-deep .v-text-field {
      input {
        padding: 10px 10px 10px 0;
      }
    }
  }
  ::v-deep .item-box {
    margin-bottom: 0rem;
    --box-shadow: 0px 0px 0px transparent !important;
    margin-right: 1rem;
  }
}
</style>
