
const state = {
  settings: {
    locale: 'en',
    dense: true,
    footer: false,
    navbar: {
      show: true,
      dense: false,
      logo: false,
    },
    fullscreen: {
      show: false,
      btn: true,
    },
    settingsPanel: {
      show: false,
      btn: true,
    },
    theme: {
      index: 0,
      dark: false,
    },
  },
};

const mutations = {
  NAVBAR_TOGGLE: (state) => {
    state.settings.navbar.show = !state.settings.navbar.show;
  },

  NAVBAR_STATE: (state, payload) => {
    state.settings.navbar.show = payload.state;
  },
};

const actions = { 
  navbarToggle: async (context, payload) => {
    context.commit('NAVBAR_TOGGLE', payload);
  },

  navbarState: async (context, payload) => {
    context.commit('NAVBAR_STATE', payload);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};