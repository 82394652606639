<template>
  <div class="layout-authentication">
    <!-- <v-container class="pb-0 text-right">
     <v-menu offset-y>
       <template v-slot:activator="{ on, attrs }">
         <v-btn
           text color="black&#45;&#45;text"
           v-bind="attrs"
           v-on="on"
         >
           Language
           <v-icon size="28" color="white">mdi-chevron-down</v-icon>
         </v-btn>
       </template>
       <v-col v-model="$root.$i18n.locale">
         <v-card
           v-for="(lang, i) in languageList" :key="`Lang${i}`" :value="lang.keyCode"
           color="sh-urbane transparent"
           elevation='0'
         >
           <v-btn class="mb-3 justify-start"
                  block color="sh-urbane transparent"
                  elevation='0'
                  v-if="checkLanguageShow(lang.keyCode)"
                  @click="changeOption(lang.keyCode)"
           >
             {{lang.name}}
           </v-btn>
         </v-card>

       </v-col>
     </v-menu>
    </v-container> -->

    <main class="layout-authentication_main">
      <slot/>
    </main>
  </div>
</template>

<script>

import formatLanguage from '../../common/formatLanguage.js';
import { mapGetters } from "vuex";

export default {
  name: 'LayoutAuthentication',
  data () {
    return {
      languageList: [
        {keyCode: 'en', name: 'English'},
        {keyCode: 'ja', name: '日本語'}
      ],
      activeLanguage: false,
    }
  },

  methods: {
    changeOption(value) {
      this.$store.dispatch("global/changeLanguage", {language: value, i18n: this.$i18n})
      console.log(this.$emit('form'))
      this.$emit('input', false)
    },
    checkLanguageShow (lang) {
      return (this.language || this.$root.$i18n.locale || formatLanguage(navigator.language)) !== lang
    }
  },
  computed: {
    ...mapGetters(["language"]),
  },
}
</script>

<style scoped lang="scss">
.v-btn{
  text-transform: none;
}
</style>
