<template>
  <v-list
    :rounded="rounded"
    class="pt-0 custom-list"
    v-bind:class="lightTheme ? 'transparent': 'dark'"
    :style="styleMaxHeight"
    id="infinity-list"
  >
    <loading :showLoading="isLoading"></loading>
    <slot />
  </v-list>
</template>

<script>
import Loading from "../Loading/Loading.vue";
export default {
  components: { Loading },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    hasLoadMore: {
      type: Boolean,
      default: true,
    },
    loadData: {
      type: Function,
      default: () => {},
    },
    maxHeight: {
      type: String,
      default: "50vh",
      required: false
    },
    height: {
      type: String,
      default: "auto",
      required: false
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    typeMessage: {
      type: String,
      default: ''
    },
    lightTheme: Boolean
  },
  data: () => ({
    setHeight: null,
  }),
  mounted: function () {
    this.loadMore();
  },
  methods: {
    loadMore() {
      const listElm = document.querySelector("#infinity-list");
      listElm.addEventListener("scroll", () => {
        if (
          listElm.scrollTop + listElm.clientHeight + 2 >=
            listElm.scrollHeight &&
          this.hasLoadMore &&
          !this.reverse
        ) {
          this.loadData();
        }
        if (this.reverse && this.hasLoadMore && listElm.scrollTop < 1) {
          this.setHeight = listElm.scrollHeight;
          this.loadData();
        }
      });
    },
  },
  computed: {
    styleMaxHeight() {
      return `max-height: ${this.maxHeight}; height: ${this.height}`;
    },
  },
  updated: function () {
    let el = document.querySelector("#infinity-list");
    if (this.reverse) {
      if(this.typeMessage !== '') {
        this.setHeight = null
      }
      if (this.setHeight) {
        el.scrollTop = el.scrollHeight - this.setHeight;
      } else {
        el.scrollTop = el.scrollHeight;
      }
    } 
  },
};
</script>

<style lang="scss" scoped>
.custom-list {
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
