import Vue from "vue";
import VueRouter from "vue-router";
import game from "@/game"
import store from "@/store";

import Admin from '../layout/Admin.vue'
import AdminUsers from '../views/admin/AdminUsers.vue'
import AdminUser from '../views/admin/AdminUser.vue'
import AdminPosts from '../views/admin/AdminPosts.vue'
import AdminManholes from '../views/admin/AdminManholes.vue'
import AdminManhole from '../views/admin/AdminManhole.vue'
import AdminPost from '../views/admin/AdminPost.vue'
import AdminCreate from '../views/admin/AdminCreate.vue'
import AdminGovernmentDetail from '../views/admin/AdminGovernmentDetail.vue'
import Accounts from "../views/Accounts.vue";
import SignIn from "../views/SignIn.vue";
import SignUp from "../views/SignUp.vue";
import ResetPassword from "../views/ResetPassword.vue";
import WaitingConfirmation from "../views/WaitingConfirmation.vue";
import SettingPassword from "../views/SettingPassword.vue";
import Play from "../views/user/Play.vue";
import User from "../views/user/User.vue";
import Notifications from '../views/user/Notifications.vue'
import Profile from '../views/user/Profile.vue'
import Settings from '../views/user/Settings.vue'
import NotFoundComponent from '../views/NotFoundComponent.vue'
import Manholes from '../views/user/Manholes.vue'
import UserManhole from '../views/user/Manhole.vue'

import Survey from "../views/survey/Survey.vue";
import ManholeInfo from "../views/survey/ManholeInfo.vue";
import SurveyContents from "../views/survey/SurveyContents.vue";
import SurveyPhotos from "../views/survey/SurveyPhotos.vue";
import SurveyComment from "../views/survey/SurveyComment.vue"

Vue.use(VueRouter);

const routes = [
  {
    path: "/twsg",
    beforeEnter() {
      window.location.replace(window.location.origin + "/api/twitter_signin");
    },
  },
  {
    path: "/linesg",
    beforeEnter() {
      window.location.replace(window.location.origin + "/api/line_signin");
    },
  },
  {
    path: "/weesg",
    beforeEnter() {
      window.location.replace(window.location.origin + "/api/wee_signin");
    },
  },
  {
    path: "/wee_sync",
    beforeEnter() {
      game.weeConnection(true);
    },
  },
  {
    path: "/wee_disconnect",
    beforeEnter() {
      game.weeConnection(false);
    },
  },
  {
    path: "/",
    redirect: "/accounts/signin",
    name: "Accounts",
    component: Accounts,
    children: [
      {
        path: "/accounts/signin",
        name: "SignIn",
        component: SignIn,
      },
      {
        path: "/accounts/signup",
        name: "SignUp",
        component: SignUp,
      },
      {
        path: "/accounts/resetpassword",
        name: "ResetPassword",
        component: ResetPassword,
      },
      {
        path: "/accounts/signup/waitingconfirmation",
        name: "SignupWaitingConfirmation",
        component: WaitingConfirmation,
      },
      {
        path: "/accounts/resetpassword/waitingconfirmation",
        name: "ResetPasswordConfirmation",
        component: WaitingConfirmation,
      },
      {
        path: "/accounts/verifyemail/waitingconfirmation",
        name: "VerifyEmailConfirmation",
        component: WaitingConfirmation,
      },
      {
        path: "/accounts/confirmpassword",
        name: "SettingPassword",
        component: SettingPassword,
      },
    ],
  },
  {
    path: "/user",
    name: "user",
    component: User,
    children: [
      {
        path: "/user/play",
        name: "Play",
        component: Play,
      },
      {
        path: "/user/pin",
        name: "Pin",
        component: Play,
      },
      {
        path: "/user/notifications",
        name: "Notifications",
        component: Notifications,
      },
      {
        path: '/user/profile/settings',
        name: 'Settings',
        component: Settings
      },
      {
        path: '/user/manholes',
        name: 'User Manholes',
        component: Manholes
      },
      {
        path:'/user/manhole/:id',
        name: 'User Manhole',
        component: UserManhole,
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/user/profile",
    name: "Profile",
    component: Profile,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin",
    name: "admin",
    component: Admin,
    children: [
      {
        path: "/admin/users",
        name: "ユーザー管理",
        component: AdminUsers,
      },
      {
        path: "/admin/governments",
        name: "市区町村管理者アカウント管理",
        component: AdminUsers,
      },
      {
        path: "/admin/manholes",
        name: "マンホール管理",
        component: AdminManholes,
      },
      {
        path: "/admin/manhole/:id/info",
        name: "マンホール詳細",
        component: AdminManhole,
      },
      {
        path: "/admin/posts",
        name: "管理者",
        component: AdminPosts,
      },
      {
        path: "/admin/round",
        name: "巡視管理",
        component: AdminPosts,
      },
      {
        path: "/admin/post/:id/info",
        name: "詳細情報を投稿する",
        component: AdminPost,
      },
      {
        path: "/admin/post/:id/photos",
        name: "写真を投稿する",
        component: AdminPost,
      },
      {
        path: "/admin/post/:id/comments",
        name: "コメントする",
        component: AdminPost,
      },
      {
        path: "/admin/user/:id",
        name: "ユーザー詳細",
        component: AdminUser,
      },
      {
        path: "/admin/create/post",
        name: "新規投稿",
        component: AdminCreate,
      },
      {
        path: "/admin/government/:id",
        name: "市区町村管理者アカウント詳細",
        component: AdminGovernmentDetail,
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/survey",
    name: "Survey",
    component: Survey,
    children: [
      {
        path: "/survey/manhole-info/:id",
        name: "Manhole Info",
        component: ManholeInfo,
      },
      {
        path: "/survey/contents/:id",
        name: "Survey Contents",
        component: SurveyContents,
      },
      {
        path: "/survey/photos/:id",
        name: "Survey Photos",
        component: SurveyPhotos,
      },
      {
        path: "/survey/target/:idTarget/type/:idType/survey/:idSurvey/comments",
        name: "Survey Comment",
        component: SurveyComment,
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "*",
    component: NotFoundComponent,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    game.tryCookieSignIn((res) => {
      if(res.data?.user_id) {
        store.dispatch("user/getStatus")
        next();
      }
    });
  } else {
    next();
  }
});
export default router;
