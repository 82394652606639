<template>
  <v-card dark class="list-user">
    <v-card>
      <v-card-title class="primary--text">
        <div class="list-user_groupButton search-filter mt-3 mb-3">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="検索"
            class="mr-4"
            single-line
            hide-details
          ></v-text-field>
        </div>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>

        <user-create :type="isUsersAdminPage ? 'user' : 'government'" />
      </v-card-title>
      <v-data-table
        :page="renderDataTable.currentPage"
        :pageCount="renderDataTable.currentPage + 1"
        :server-items-length="renderDataTable.totalItems"
        :items="renderDataTable.resources"
        :items-per-page="10"
        :footer-props="{
          'disable-items-per-page': true,
          'disable-pagination': renderDataTable.loading,
          'items-per-page-text': '１ページあたりの行数',
          'page-text': '{2}中の{0}-{1}'
        }"
        :search="search"
        :headers="headers"
        :options.sync="options"
        :loading="renderDataTable.loading"
        loading-text='読込中です...'
        no-data-text='データがありません'
      >
        <template v-slot:[`item.active`]="{ item }">
          {{ item.active ? "有効" : "無効" }}
        </template>

        <template v-slot:[`item.city`]="{ item }">
          {{ renderAreaName(item, "city") }}
        </template>

        <template v-slot:[`item.pref`]="{ item }">
          {{ renderAreaName(item, "pref") }}
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon class="mr-2 item-view mt-5 mb-5" @click="handleView(item)">
            mdi-eye
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";
import UserCreate from "../../components/admin/UserCreate.vue";

export default {
  components: {
    UserCreate,
  },
  data() {
    return {
      search: "",
      options: {},
      confirmedValue: "a",
      headers: [
        {
          text: "ユーザーID",
          align: "start",
          sortable: true,
          value: "id",
          width: "150px",
        },
        {
          text: "メールアドレス",
          value: "email",
          sortable: true,
          width: "350px",
        },
        {
          text: "ユーザー名",
          value: "nickname",
          sortable: true,
          width: "200px",
        },
        {
          text: "都道府県",
          value: "pref",
          sortable: true,
        },
        {
          text: "市区町村",
          value: "city",
          sortable: true,
        },
        {
          text: "状態",
          value: "active",
          sortable: true,
        },
        {
          text: "操作",
          value: "actions",
          sortable: false,
        },
      ],
      dataTable: {},
      key: 0,
    };
  },

  computed: {
    ...mapGetters(["users", "governments"]),

    isUsersAdminPage() {
      return this.$route.path === "/admin/users";
    },

    isGovernmentAdminPage() {
      return this.$route.path === "/admin/governments";
    },

    renderDataTable() {
      return this.$route.path === "/admin/users" ? this.users : this.governments;
    },
  },

  watch: {
    options: {
      handler() {
        this.fetchData();
      },
    },
    search: _.debounce(function () {
      this.fetchData();
    }, 500),

    $route() {
      let configs = {
        page: 0,
        keyword: null,
        limit: 10,
        sortType: "ASC",
      };
      this.search = "";
      if (this.isUsersAdminPage) {
        this.$store.dispatch("adminGovernments/refreshDataGovernments");
        this.$store.dispatch("adminUsers/fetchUsers", configs);
      } else if (this.isGovernmentAdminPage) {
        this.$store.dispatch("adminUsers/refreshDataUsers");
        this.$store.dispatch("adminGovernments/fetchGovernments", configs);
      } else {
        this.$store.dispatch("adminUsers/refreshDataUsers");
      }
    },

    deep: true,
  },
  mounted() {},

  methods: {
    handleView(item) {
      const _this = this;
      _this.isUsersAdminPage
        ? _this.$router.push({ path: `/admin/user/${item.id}` })
        : _this.$router.push({ path: `/admin/government/${item.id}` });
    },

    fetchData() {
      const _this = this;
      const { page, itemsPerPage, sortBy, sortDesc } = _this.options;
      const sortKey = sortBy[0] === "active" ? "activated" : sortBy[0];
      let configs = {
        page: page - 1,
        keyword: _this.search,
        limit: itemsPerPage,
        sortBy: sortKey,
        sortType: sortDesc[0] ? "DESC" : "ASC",
      };
      _this.isUsersAdminPage && _this.$store.dispatch("adminUsers/fetchUsers", configs)
      _this.isGovernmentAdminPage && _this.$store.dispatch("adminGovernments/fetchGovernments", configs);
    },

    renderAreaName(item, type) {
      if (type === "city") {
        return item.city?.cityname ? item.city.cityname : "";
      } else {
        return item.pref?.name ? item.pref?.name : "";
      }
    },
  },
};
</script>

<style lang="scss">
.list-user {
  &_groupButton {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    @media (max-width: 414px) {
      width: 100%;
    }
    @media (max-width: 810px) {
      &.search-filter {
        width: 100%;
      }
    }
  }
  .v-data-footer {
    .v-input__append-inner {
      display: none;
    }
  }
  .v-text-field {
    input {
      padding: 10px 10px 10px 0;
    }
  }
}
</style>