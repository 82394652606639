import { render, staticRenderFns } from "./SurveyCtrl.vue?vue&type=template&id=39ef8927&scoped=true&"
import script from "./SurveyCtrl.vue?vue&type=script&lang=js&"
export * from "./SurveyCtrl.vue?vue&type=script&lang=js&"
import style0 from "./SurveyCtrl.vue?vue&type=style&index=0&id=39ef8927&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39ef8927",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VCardText,VContainer,VTab,VTabs})
