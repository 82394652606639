<template>
  <div class="survey-img mt-5 mb-5" :style="cssProps">
    <v-img
      class="mb-2"
      lazy-src="https://picsum.photos/id/11/10/6"
      width="100%"
      :src="url"
    >
    </v-img>
    <v-row justify="space-between">
      <v-col cols="7">
        <v-select
          v-model="selected"
          :items="items"
          :item-text="(item) => $t(item.text)"
          class="select-box"
          :background-color="!lightTheme ? 'bgGeneral' : 'transparent'"
          :height="40"
          hide-details
          outlined
          solo
          dense
          @change="selectData"
          :disabled="disabled"
        />
      </v-col>
      <v-col cols="5" class="text-right">
        <v-btn height="40" outlined @click="handleDetele" :disabled="disabled">
          {{ $t("survey.delete") }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import { defaultTypeImage } from '../../views/survey/defaultData/dataImageType'

export default {
  props: {
    url: String,
    type: Number,
    id: Number,
    lightTheme: Boolean,
    targetId: Number,
    surveyId: Number,
    disabled: Boolean,
  },

  data: () => ({
    selected: null,
    items: [],
  }),

  mounted() {
    this.selected = this.type;
    this.items = defaultTypeImage
  },

  methods: {
    selectData(val) {
      const data = {
        imgId: this.id,
        type: val,
        targetId: this.targetId ? this.targetId : this.$route.params.id,
        surveyId: this.surveyId,
      };
      this.$store.dispatch("survey/surveyUpdateImage", data);
    },

    handleDetele() {
      const data = {
        imgId: this.id,
        targetId: this.targetId ? this.targetId : this.$route.params.id,
        surveyId: this.surveyId,
      };
      this.$store.dispatch("survey/removeImage", data);
    },
  },
  computed: {
    cssProps() {
      return {
        "--background": this.lightTheme
          ? "transparent"
          : "#3D4A6C 0% 0% no-repeat padding-box",
        "--box-shadow": this.lightTheme
          ? "0px 10px 20px black"
          : "0px 10px 20px #081539",
        "background-color": "rgba(255,255,255,0.2)",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.survey-img {
  .select-box {
    width: fit-content;
  }
  .v-btn--outlined {
    border-color: #27f8f9;
    border-width: 2px;
    box-shadow: var(--box-shadow);
    background: var(--background);
  }
}
::v-deep .v-select__selection {
  max-width: 100%;
}
::v-deep .v-select__selection {
  max-width: 100%;
}
::v-deep .v-select__selections input {
  display: none;
}
::v-deep .v-list-item__title {
  font-size: 16px !important;
  line-height: 1.2 !important;
}
</style>