<template>
  <div
    class="item-box d-flex"
  >
    <v-col
      cols="auto"
      class="d-flex pa-0"
    >
      <v-switch readonly v-model="value.active" @click="showConfirmation">
      </v-switch>
    </v-col>
    <selection-dialog
      :isOpen="showConfirmationDialog"
      :contents="[$t('adminUser.titleDialogChangeStatus')]"
      :clickAccept="clickDisableStatus"
      :clickCancel="closeChangeStatus"
    />
  </div>
</template>

<script>
import SelectionDialog from "../modal/SelectionDialog.vue";

export default {
  props: {
    value: Object,
    type: String,
  },
  components: { 
    SelectionDialog,
  },
  data() {
    return {
      showConfirmationDialog: false
    };
  },
  mounted() {
  },
  methods: {
    showConfirmation () {
      if(this.value.active){
        this.showConfirmationDialog = true
      } else{
        switch(this.type) {
          case "government":
            this.$store.dispatch("adminGovernments/changeGovernmentActiveDetail", this.value.id)
            break;
          default:
            this.$store.dispatch("adminUsers/changeUserDetailActive", this.value.id)
            break;
        }
      }
    },

    closeChangeStatus() {
      this.showConfirmationDialog = false
    },

    clickDisableStatus() {
      switch(this.type) {
        case "government":
          this.$store.dispatch("adminGovernments/changeGovernmentActiveDetail", this.value.id)
          break;
        default:
          this.$store.dispatch("adminUsers/changeUserDetailActive", this.value.id)
          break;
      }
      this.showConfirmationDialog = false
    }
  },
  computed: {
  },
};
</script>

<style lang="scss" scoped>
.item-box {
  font-size: 13px;
  height: 67px;
  select {
    width: 7rem;
    color: #fff;
    cursor: pointer;
    -webkit-appearance: auto;
    option{
      color: #000;
      cursor: pointer;
    }
  }
  
  select:focus{
    outline: none;
  }
}

</style>
