<template>
  <div class="text-center">
    <v-dialog v-model="isOpen" :width="290" persistent>
      <v-card light class="card-custom">
        <v-card-title class="justify-content-center mb-3">
          {{ title }}
        </v-card-title>

        <div
          class="flex-column d-flex"
        >
          <div>
            <v-select
              v-model="selectedTypeImage"
              :items="itemsTypeImage"
              :item-text="(item) => $t(item.text)"
              :height="40"
              hide-details
              outlined
              dense
              light
              @change="(e) => selectedTypeImage = e"
            />
            <v-btn 
              color="dark white--text"
              class="mb-2 pa-0 mt-2"
              depressed
              block
              v-bind:class="{'normal-button': !cancelBtn}"
            >
              <upload-image-survey 
                :verSelected="verSelected"
                :listVersion="listVersion"
                :isCreateNew="isCreateNew"
                :textUploadImage="$t('instantPost.textAcceptConfirmFinal')"
                :selectedTypeImage="selectedTypeImage"
                :uploadInMap="uploadInMap"
                :isAdminPage="isAdminPage"
              />
            </v-btn>
          </div>
         
          <v-btn
            class="ml-0 mb-2"
            depressed
            block
            @click="clickCancel(isOpen)"
          >
            {{ textCancel }}
          </v-btn>

          <v-btn
            v-if="clickManhole"
            class="ml-0 mt-10"
            depressed
            block
            @click="clickManhole()"
          >
            {{ textManhole }}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import UploadImageSurvey from '../user/UploadImageSurvey.vue';
import { defaultTypeImage } from '../../views/survey/defaultData/dataImageType';

export default {
  components: { UploadImageSurvey },
  props: {
    title: { type: String, required: false },
    isOpen: { type: Boolean, default: false, required: true },
    clickCancel: {
      type: Function,
      required: false,
      default: function(){
        this.isOpen = false
      }
    },
    clickManhole: {
      type: Function,
      required: false,
    },
    textBtn: { type: String, required: false, default: "OK" },
    textCancel: { type: String, required: false, default: "キャンセル" },
    textManhole: { type: String, required: false, default: "マンホール情報を表示" },
    cancelBtn: { type: Boolean, required: false, default: true },
    verSelected: {required: false, default: 'create'},
    listVersion: {type: Array, required: false},
    isCreateNew: {type: Boolean, required: false, default: true},
    uploadInMap: {type: Boolean, required: false, default: false},
    isAdminPage: {type: Boolean, required: false, default: false},
  },
  data() {
    return {
      dialog: false,
      itemsTypeImage: [],
      selectedTypeImage: 1,
    };
  },

  mounted() {
    this.itemsTypeImage = defaultTypeImage
  },
};
</script>

<style lang="scss" scoped>
.card-custom {
  padding: 19px 19px 50px 19px;
  text-align: center;
  .justify-content-center {
    justify-content: center;
    padding: 16px 5px 10px;
    font-size: 18px;
  }
}
</style>
