<template>
  <div>
    <v-btn
      depressed
      class="mb-2"
      color="primary dark--text"
      @click="dialog = true"
    >
      {{ isGovernmentPage ? "市区町村管理者アカウントを作成する" : "アカウント新規作成"}}
    </v-btn>
    <v-dialog
      light
      v-model="dialog"
      max-width="500px"
      class="user-create"
    >
      <v-card class="create-user">
        <v-card-title class="justify-center">
          <span class="text-h5">{{ isGovernmentPage ? "市区町村管理者アカウントを作成する" : "アカウント新規作成"}}</span>
        </v-card-title>
        <v-form 
          ref="form"
          v-model="valid"
          lazy-validation
          autocomplete="off"
        >
          <v-card-text class="pb-0">
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  class="pt-0 pb-2"
                  v-if="isGovernmentPage"
                >
                  <v-text-field
                    v-model="username"
                    :rules="usernameRules"
                    label="ユーザー名"
                    ref="username"
                    type="search"
                    color="black darken-2"
                    autocomplete="off"
                    class="custom-input"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  class="pt-0 pb-2"
                >
                  <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    label="メールアドレス"
                    ref="email"
                    autocomplete="off"
                    color="black darken-2"
                    type="search"
                    class="custom-input"
                  >
                  </v-text-field>
                </v-col>
                <prefecture-city-admin 
                  :choosePrefecture="choosePrefecture"
                  :chooseCity="chooseCity"
                  :prefecturesData="prefectures.resources"
                  :citiesData="cities.resources"
                  :city="city"
                  :pref="pref"
                  :endIntersectPrefecture="endIntersectPrefecture"
                  :endIntersectCity="endIntersectCity"
                  :currentUser="currentUser"
                >
                </prefecture-city-admin>
                <v-col
                  cols="12"
                  sm="6"
                  class="pt-0 pb-2"
                >
                  <v-text-field
                    v-model="password"
                    :rules="passwordRules"
                    label="パスワード"
                    color="black darken-2"
                    type="password"
                    ref="password"
                    class="custom-input"
                    autocomplete="off"
                  >
                  </v-text-field>
                </v-col>
                
                <v-col
                  cols="12"
                  sm="6"
                  class="pt-0 pb-2"
                >
                  <v-text-field
                    v-model="confirmPassword"
                    :rules="confirmPasswordRules"
                    label="パスワードを確認する"
                    type="password"
                    color="black darken-2"
                    ref="confirmPassword"
                    class="custom-input"
                  >
                  </v-text-field>
                </v-col>
                
                <v-col
                  cols="12"
                  class="pt-0 pb-2"
                >
                  <v-checkbox
                    v-model="isSkipEmail"
                    :label="`メールアドレスなしでアカウントを作成する`"
                    color="black darken-2"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

        </v-form>
        

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="gray"
            text
            @click="closeDialog"
          >
            キャンセル
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="createNewGovernment"
            v-if="isGovernmentPage"
          >
            保存
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="createNewUser"
            v-else
          >
            保存
          </v-btn>
        </v-card-actions>
        <v-spacer class="pt-3"></v-spacer>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PrefectureCityAdmin from './PrefectureCityAdmin.vue';

export default {
  props: {
    type: { type: String, required: false },
  },
  components: { PrefectureCityAdmin },
  data: () => ({
    dialog: false,
    isSkipEmail: false,
    email: '',
    username: '',
    password: '',
    confirmPassword: '',
    city: 0,
    pref: 0, 
    valid: true,
  }),

  watch: {
    resetFormCreateUser: {
      handler() {
        if(this.resetFormCreateUser){
          this.resetForm()
        }
      },
    },

    resetFormCreateGovernment: {
      handler() {
        if(this.resetFormCreateGovernment){
          this.resetForm()
        }
      },
    },
    pref () {
      if(this.pref !== '') {
        this.$store.dispatch("adminUsers/getCities", {
          id: this.pref,
          page: 0,
        })
      }
    },
    deep: true,
  },

  async mounted(){
    if(!this.roleGovermentAdmin){
      await this.$store.dispatch("adminUsers/getPrefectures", 0)
    }
  },

  computed: {
    ...mapGetters([
      "resetFormCreateUser", 
      "prefectures", 
      "cities", 
      "resetFormCreateGovernment", 
      "currentUser"
    ]),

    emailRules() {
      return [
        v => !!v || this.$t('validate.requiredUsername'),
        v => /.+@.+\..+/.test(v) || this.$t('validate.validAdminEmailAddress'),
      ]
    },
    passwordRules() {
      return [
        (v) => (v && v.length >= 6) || this.$t("validate.passwordAdminRule"),
        (v) => (v && v.length <= 30) || this.$t("validate.passwordAdminRule")
      ]
    },
    usernameRules() {
      return [
        v => !!v || this.$t('validate.requiredUsername'),
        (v) => /^[A-Za-z0-9_]+$/.test(v) || this.$t("validate.usernameAccept"),
        (v) => (v && v.length <= 50) || this.$t("validate.usernameLess"),
      ]
    },

    confirmPasswordRules() {
      return [
        (v) => v === this.password || this.$t("validate.passworAdmindMatch"),
        this.confirmPassword === this.password ||
          this.$t("validate.passworAdmindMatch"),
      ];
    },

    isGovernmentPage() {
      return this.type === "government"
    },

    roleGovermentAdmin() {
      return this.currentUser.role === 'government'
    }
  },

  methods: {
    createNewUser() {
      const _this = this;

      const finalPref = this.roleGovermentAdmin ? this.currentUser.pref.id : _this.pref
      const finalCity = this.roleGovermentAdmin ? this.currentUser.city.id : _this.city

      let user = {
        email: _this.email,
        password: _this.password,
        isSkipEmail: _this.isSkipEmail,
        pref: finalPref,
        city: finalCity,
      } 
      this.$refs.form.validate()
      if(_this.email && _this.password && finalPref && finalCity && 
      _this.confirmPassword && _this.confirmPassword === _this.password){
        _this.$store.dispatch("adminUsers/createNewUser", user);
      }
    },

    createNewGovernment() {
      let government = {
        nickname: this.username,
        email: this.email,
        password: this.password,
        isSkipEmail: this.isSkipEmail,
        pref: this.pref,
        city: this.city,
      } 
      this.$refs.form.validate()
      if(this.username && this.email && this.password && this.pref && this.city && 
      this.confirmPassword && this.confirmPassword === this.password){
        this.$store.dispatch("adminGovernments/createNewGovernment", government);
      }

    },

    closeDialog() {
      this.dialog = false
      this.resetForm()
    },

    resetForm() {
      const _this = this;
      _this.isSkipEmail = false;
      _this.$refs.form.resetValidation()
      if(this.roleGovermentAdmin){
        _this.$refs.email.reset()
        _this.$refs.password.reset()
        _this.$refs.confirmPassword.reset()
      } else{
        _this.$refs.form.reset()
      }
    },

    choosePrefecture(e) {
      this.pref = e,
      this.city = 0
    },

    chooseCity(e) {
      this.city = e
    },

    endIntersectPrefecture(isIntersecting) {
      if (isIntersecting && this.prefectures.currentPage + 1 < this.prefectures.totalPages) {
        this.$store.dispatch("adminUsers/getPrefectures", this.prefectures.currentPage + 1)
      }
    },

    endIntersectCity(isIntersecting) {
      if (isIntersecting && this.cities.currentPage + 1 < this.cities.totalPages) {
        this.$store.dispatch("adminUsers/getCities", {
          id: this.pref,
          page: this.cities.currentPage + 1,
        })
      }
    }

  },


}
</script>

<style lang="scss">
.create-user{
  .custom-input {
    input{
      padding: 10px 0 !important;
    }
    
  }
  .v-input__slot::after {
    border-color: black !important;
  }

  .v-input__slot::before {
    border-color: black !important;
  }
  .v-text-field__slot .error--text {
    color: rgba(0, 0, 0, 0.6) !important;
  }
  .v-select__slot .error--text::before{
    color: rgba(0, 0, 0, 0.6) !important;
  }
  input {
    caret-color: rgba(0, 0, 0, 0.6) !important;
  }
}


</style>