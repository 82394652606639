<template>
  <div class="text-center">
    <v-bottom-sheet
      v-model="displayChangeLanguage"
      inset
      outlined
      overlay-opacity=".1"
      elevation='0'
      class="elevation-0"
      flat
    >
      <v-sheet
        elevation="0"
        color="dark darken-1"
        flat
      >
        <v-card
          flat
          color="dark darken-1"
          class="mx-auto"
        >
          <v-card-title class="text-h font-weight-bold flex-row justify-center">
            {{ $t('setting.selectDefaultLanguage') }}
            <!--            <v-btn fab dark small depressed color="primary" class="absolute" @click="displayChangeLanguage = false">-->
            <!--              <v-icon color="dark">-->
            <!--                mdi-window-close-->
            <!--              </v-icon>-->
            <!--            </v-btn>-->
          </v-card-title>
          <v-container class="">
            <v-col v-model="$root.$i18n.locale">
              <v-btn class="mb-3 justify-start"
                     v-bind:class="activeLanguageFun(lang.keyCode)"
                     block color="sh-urbane transparent"
                     elevation='0'
                     v-for="(lang, i) in language" :key="`Lang${i}`" :value="lang.keyCode"
                     @click="changeOption(lang.keyCode)"
              >
                {{ lang.name }}
              </v-btn>
            </v-col>

          </v-container>
        </v-card>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
export default {
  name: 'LanguageOption',
  props: {
    value: Boolean,
  },
  data () {
    return {
      language: [
        {keyCode: 'en', name: 'English'},
        {keyCode: 'ja', name: '日本語'}
      ],
      activeLanguage: false,
    }
  },
  mounted: function() {
  },
  methods: {
    changeOption(value) {
      this.$store.dispatch("global/changeLanguage", {language: value, i18n: this.$i18n})
      this.$emit('input', false)
    },
    activeLanguageFun (value) {
      return {
        activeLanguage: this.$root.$i18n.locale === value,
      }
    }
  },
  computed: {
    displayChangeLanguage: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.v-sheet,
.v-card {
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
}
.activeLanguage::before{
  opacity: 0.08;
}
</style>
