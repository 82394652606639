<template>
  <div class="text-center">
    <v-dialog v-model="isOpen" :width="isCameraTargetDialog ? 290 : 500" persistent>
      <v-card light class="card-custom">
        <v-card-title class="justify-content-center mb-3">
          {{ title }}
          <v-btn v-if="showCloseBtn" fab dark small depressed color="primary" class="absolute" @click="clickCancel(isOpen)">
            <v-icon color="dark">
              mdi-window-close
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text
          class="pb-3 pl-0 pr-0"
          v-for="(content, i) in contents"
          :key="i"
        >
          {{ content }}
        </v-card-text>

        <v-card-actions
          class="justify-content-center mt-3"
          v-if="!isCameraTargetDialog">
          <v-btn
            class="pl-5 pr-5 custom-btn cancel"
            color="white black--text"
            @click="clickCancel(isOpen)"
            v-if="cancelBtn"
          >
            {{ $t('confirmDialog.cancel') }}
          </v-btn>
          <v-btn
            class="pl-5 pr-5 custom-btn"
            color="primary black--text"
            @click="clickAccept"
            v-bind:class="{'normal-button': !cancelBtn}"
          >
            {{ textBtn }}
          </v-btn>
        </v-card-actions>
        <div
          v-else
          class="flex-column d-flex"
        >
          <v-btn
            color="dark white--text"
            class="mb-2"
            depressed
            block
            @click="clickAccept"
          >
            {{ textBtn }}
          </v-btn>
          <v-btn
            class="ml-0 mb-2"
            depressed
            block
            @click="clickCancel(isOpen)"
          >
            {{ textCancel }}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  props: {
    title: { type: String, required: false },
    contents: { type: Array, required: false },
    isOpen: { type: Boolean, default: false, required: true },
    isCameraTargetDialog: { type: Boolean, default: false, required: false },
    clickCancel: {
      type: Function,
      required: false,
      default: function(){
        this.isOpen = false
      }
    },
    clickAccept: { type: Function, required: false, default: function() {} },
    textBtn: { type: String, required: false, default: "OK" },
    textCancel: { type: String, required: false, default: "キャンセル" },
    cancelBtn: { type: Boolean, required: false, default: true },
    showCloseBtn: {type: Boolean, required: false, default: false},
  },

  data() {
    return {
      dialog: false,
    };
  },

};
</script>

<style lang="scss" scoped>
.card-custom {
  padding: 19px;
  text-align: center;
  .justify-content-center {
    justify-content: center;
    .custom-btn {
      min-width: 35%;
      margin: 0 8px;
      padding: 20px 0;
      border-radius: 18px;
      &.cancel {
        box-shadow: 0px 0px 1px 1px black;
      }
    }
    .button-camera{
      min-width: 50%;
      margin: 0 8px;
      padding: 20px 0;
      border-radius: 18px;
    }
    .normal-button {
      width: fit-content;
    }
  }
}
</style>
