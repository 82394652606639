import axios from "axios";
import iconv from "iconv-lite";

axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
// INIT STORE
const game = {};

// SET

game.apibase = process.env.VUE_APP_SERVER_API;
game.s3base = process.env.VUE_APP_S3_URL;

game.last_lng = 0;
game.last_lat = 0;

game.focus_circle = null;
game.range_circle = null;
game.add_target_cross = null;

game.add_target_mode = false;

game.selected_map_layer = "global_targets";
game.map_type = "satellite";
game.map_type_button_enabled = true;

game.conf = {
  range_circle_diameter: 100, // in meter,
};
game.geolocation_error = false;

function setMapType(t) {
  game.map_type = t;
}
function getMapType() {
  return game.map_type;
}
function returnCurrentLocation() {
  return { lat: game.last_lat, lng: game.last_lng };
}
function setMapTypeButtonEnabled(flag) {
  game.map_type_button_enabled = flag;
}
function getMapTypeButtonEnabled() {
  return game.map_type_button_enabled;
}

function setUserId(uid) {
  game.user_id = uid;
}
function getUserId() {
  return game.user_id;
}

function selectMapLayer(name) {
  game.selected_map_layer = name;
}
function getSelectedMapLayer() {
  return game.selected_map_layer;
}

game.next_auto_pan_to = null;
function setNextAutoPanTo(latlng) {
  game.next_auto_pan_to = latlng;
}
function getNextAutoPanTo() {
  return game.next_auto_pan_to;
}
game.next_auto_zoom = null;
function setNextAutoZoom(z) {
  game.next_auto_zoom = z;
}
function getNextAutoZoom() {
  return game.next_auto_zoom;
}
function getAPIBase() {
  return game.apibase;
}
game.play_view = null; // TODO: exportしてる方にもplayViewというのがあり、きたない。こっちに統一したい
function setPlayView(v) {
  game.play_view = v;
}
function getPlayView() {
  return game.play_view;
}
function generateUuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
function emailSignIn(em, pw, cb) {
  axios
    .post(game.apibase + "/sign_in", {
      email: em,
      password: pw,
    })
    .then(function (res) {
      game.user_id = res.data.user_id;
      cb(res.data);
    })
    .catch((res) => {
      cb(res?.response?.data);
    });
}
function emailSignUp(em, cb) {
  axios
    .post(game.apibase + "/sign_up", {
      email: em,
    })
    .then(function (res) {
      cb(res);
    })
    .catch(function (error) {
      if (cb) cb(error.response.data);
    });
}

function activateEmail(req, cb) {
  axios
    .post(game.apibase + "/activate_email", {
      token: req.token,
      password: req.password,
    })
    .then(function (res) {
      if (cb) cb(res.data);
    })
    .catch(function (error) {
      if (cb) cb(error?.response?.data);
    });
}

function validToken(tk, cb) {
  axios
    .get(game.apibase + `/valid_token?confirmation_token=${tk}`)
    .then(function (res) {
      if (cb) cb(res.data);
    })
    .catch(function (error) {
      if (cb) cb(error.response.data);
    });
}

function changeUserSetting(nickname, password, cb) {
  axios
    .put(game.apibase + "/users/settings", {
      nickname,
      password,
    })
    .then(function (res) {
      if (cb) cb(res.data);
    })
    .catch(function (error) {
      if (cb) cb(error.response.data);
    });
}

async function surveyUploadImage(formData, cb) {
  const config = { headers: { "Content-Type": "undefined" } };
  await axios
    .post(game.apibase + "/survey/upload-image", formData, config)
    .then(function (res) {
      if (cb) cb(res.data);
    });
}
async function surveyUpdateImage(data, cb) {
  await axios
    .post(game.apibase + `/survey/image-category/${data.imgId}/survey/${data.surveyId}`, data)
    .then(function (res) {
      cb(res);
    })
    .catch(function (error) {
      if (cb) cb(error?.response?.data);
    });
}

async function surveyDeleteImage(data, cb) {
  await axios
    .delete(game.apibase + `/survey/delete-image/${data.imgId}/survey/${data.surveyId}`, { data })
    .then(function (res) {
      cb(res);
    })
    .catch(function (error) {
      if (cb) cb(error.response.data);
    });
}

function loadStatus(cb) {
  return axios
    .get(game.apibase + "/status")
    .then(function (res) {
      game.status = res.data;
      if (cb) cb(res.data);
    })
    .catch(function (error) {
      if (cb) cb(error.response?.data);
    });
}

function postAddTarget(payload, cb) {
  axios.post(game.apibase + "/add_target", payload).then(function (res) {
    if (cb) cb(res.data);
  }).catch(function (error) {
    if (cb) cb(error.response?.data);
  });
}

function postMoveTarget(target_id, lat, lng, cb) {
  axios
    .post(game.apibase + "/move_target", {
      target_id: target_id,
      lat: lat,
      lng: lng,
    })
    .then(function (res) {
      if (cb) cb(res.data);
    });
}

function editTarget(target_id, payload, cb) {
  axios
    .put(game.apibase + `/targets/${target_id}`, payload)
    .then(function (res) {
      if (cb) cb(res.data);
    });
}

function getTargetById(target_id, cb) {
  return axios
    .get(game.apibase + `/targets/${target_id}`)
    .then(function (res) {
      if (cb) cb(res.data);
    })
    .catch(function (error) {
      if (cb) cb(error.response.data);
    });
}

function getListTargets(lng, lat, cb) {
  const zoom = game.map.getZoom();
  if(zoom >= 17) {
    axios
    .get(game.apibase + `/targets/?lng=${lng}&lat=${lat}`)
    .then(function (res) {
      drawTargets(res.data.data, "global_targets")
      if (cb) cb(res.data);
    });
  } else{
    drawTargets({}, "global_targets");
  }
 
}

function postDeleteTarget(target_id, cb) {
  axios.delete(game.apibase + `/targets/${target_id}`).then(function (res) {
    if (cb) cb(res.data);
  });
}

function geolocationCallback(pos) {
  updateGPSLocation(pos.coords.latitude, pos.coords.longitude);
  game.geolocation_error = false;
}
var g_last_post_location = 0;

function updateGPSLocation(lat, lng) {
  if (countLayer(game.map) == 0) return;

  const to_sync_map = game.last_lat == 0;
  game.last_lat = lat;
  game.last_lng = lng;
  if (to_sync_map) game.map.panTo([game.last_lat, game.last_lng]);

  if (game.latLngCallback) game.latLngCallback(game.last_lat, game.last_lng);

  if (document.hasFocus()) {
    const nt = new Date().getTime();
    if (nt > g_last_post_location + 10 * 1000) {
      g_last_post_location = nt;
    }
  }
  if (game.map.player)
    game.map.player.setLatLng(game.L.latLng(game.last_lat, game.last_lng));
  else {
    var curposicon = game.L.icon({
      iconUrl: require("./assets/current_position.png"), //TODO:svg
      iconSize: [20, 20], // size of the icon
      iconAnchor: [10, 10], // point of the icon which will correspond to marker's location
    });
    game.map.player = game.L.marker([game.last_lat, game.last_lng], {
      icon: curposicon,
    }).addTo(game.map);
  }
  game.range_circle.setLatLng(game.L.latLng(game.last_lat, game.last_lng));
}

function panTo(lat, lng) {
  if (game.map) game.map.panTo([lat, lng]);
}

function setFocusCircleLocation(lat, lng) {
  game.focus_circle.cur_lat = lat;
  game.focus_circle.cur_lng = lng;
  game.focus_circle.setLatLng(game.L.latLng(lat, lng));
}

function setAddTargetCrossLocation(lat, lng) {
  game.add_target_cross.setLatLng(game.L.latLng(lat, lng));
}

function getAddTargetCrossLocation() {
  return game.add_target_cross.getLatLng();
}

var g_loading_targets = null; //ロード中のレイヤ名をいれとく。ロードが終わったらnull
function isLoadingTargets() {
  return g_loading_targets;
}

function countLayer(map) {
  var cnt = 0;
  map.eachLayer(function () {
    cnt++;
  });
  return cnt;
}

function clearTargets() {
  let cnt = 0;
  game.map.eachLayer(function (layer) {
    cnt++;
    if (layer.clean_on_redraw) game.map.removeLayer(layer);
  });
  return cnt;
}

// function addPolygonShape(ary, style) {
//   if (ary[0][0][0].length) {
//     // multipolygon!
//   } else {
//     ary = [ary]; // multiあつかいにあわせる
//   }
//   for (let ai = 0; ai < ary.length; ai++) {
//     const pol = ary[ai];
//     for (let bi = 0; bi < pol.length; bi++) {
//       const verts = [];
//       for (let i = 0; i < pol[bi].length; i++)
//         verts.push([pol[bi][i][1], pol[bi][i][0]]); // swap lat and lon
//       const p = game.L.polygon(verts).addTo(game.map);
//       p.setStyle(style);
//       p.clean_on_redraw = true;
//     }
//   }
// }

// function drawPolygons(polygons, dirname) {
//   for (let i = 0; i < polygons.length; i++) {
//     const polygon = polygons[i];
//     let color = getPolygonColor(polygon);
//     const codenum = polygon.keycode ? polygon.keycode : polygon.jcode;
//     const codestr = polygon.pref < 10 ? "0" + codenum : "" + codenum;
//     const url = game.s3base + "/" + dirname + "/" + codestr + ".json";
//     axios({
//       url: url,
//       method: "GET",
//       keycode: polygon.keycode,
//     }).then(function (res) {
//       if (countLayer(game.map) == 0) return;
//       const style = {
//         fillColor: color[0],
//         fillOpacity: color[1],
//         color: color[0],
//         dashArray: "5,5",
//       };
//       addPolygonShape(res.data, style);

//       if (polygon.stats) {
//         const lat = (polygon.lat0 + polygon.lat1) / 2;
//         const lng = (polygon.lon0 + polygon.lon1) / 2;
//         const name = polygon.sname ? polygon.sname : polygon.cityname;
//         const tm = new game.L.Marker([lat, lng], {
//           icon: new game.L.DivIcon({
//             //                        className: 'my-div-icon',
//             html:
//               '<div style="width:80px; color:#1a284d; text-align:center; font-size:9px; font-weight:bold; background-color:' +
//               polygon.stats.color +
//               '; ">' +
//               name +
//               "<br/>" +
//               polygon.stats.msg +
//               "</div>",
//           }),
//         });
//         tm.addTo(game.map);
//         tm.clean_on_redraw = true;
//       }
//     });
//   }
// }

function drawTargets(data, mode) {
  mode;
  if (!game.L) {
    return;
  }
  clearTargets();
  // data?.polygons?.length > 0 && drawPolygons(data.polygons, "polygons");
  const ctr = game.map.getCenter();
  data?.targets?.sort(function (a, b) {
    const ad = sphericalTrigonometry(ctr.lat, ctr.lng, a.lat, a.lon);
    const bd = sphericalTrigonometry(ctr.lat, ctr.lng, b.lat, b.lon);
    if (ad < bd) return -1;
    else return 1;
  });
  for (let i = 0; i < data?.targets?.length; i++) {
    const tgt = data.targets[i];
    // const dist = sphericalTrigonometry(ctr.lat, ctr.lng, tgt.lat, tgt.lon);
    const urlIcon = data.targets[i].survey_count > 0 ? require("./assets/manhole_under_review.svg") : require("./assets/manhole_other_owner.png")
    let sz = 20;
    let manhole_icon = game.L.icon({
      iconUrl: urlIcon,
      iconSize: [sz, sz],
      iconAnchor: [sz / 2, sz / 2],
    });
    let ic = game.L.marker([tgt.lat, tgt.lon], { icon: manhole_icon }).addTo(
      game.map
    );
    ic.clean_on_redraw = true;
  }
}

//
// 球面三角法
//
function sphericalTrigonometry(lat1, lng1, lat2, lng2) {
  // 赤道半径
  const R = 6378137.0;

  function rad(deg) {
    return (deg * Math.PI) / 180;
  }

  return (
    R *
    Math.acos(
      Math.cos(rad(lat1)) *
        Math.cos(rad(lat2)) *
        Math.cos(rad(lng2) - rad(lng1)) +
        Math.sin(rad(lat1)) * Math.sin(rad(lat2))
    )
  );
}

function findNearestTarget(latlng, listTargets) {
  const zoom = game.map.getZoom();

  if (!latlng.lat) {
    return;
  }
  if (!listTargets) {
    return null;
  }
  let targets = listTargets.targets;
  let mindist = zoom > 18 ? 3.5 : 10;
  let ans_tgt = null;
  for (let j = 0; j < targets.length; j++) {
    const tgt = targets[j];
    if (!tgt) {
      continue;
    }
    const dist = sphericalTrigonometry(
      latlng.lat,
      latlng.lng,
      tgt.lat,
      tgt.lon
    );
    if (dist < mindist) {
      mindist = dist;
      ans_tgt = tgt;
      ans_tgt.dist = mindist;
    }
  }
  if (mindist > 50) {
    return null;
  }
  if (!ans_tgt) {
    return null;
  }
  return ans_tgt;
}

function setLatLngCallback(cb) {
  game.latLngCallback = cb;
}

function setGeolocationErrorCallBack(cb) {
  game.geolocationErrorCallback = cb;
}

function setNotificationsCallBack(cb) {
  game.notificationCallback = cb;
}

game.init_gps_done = false;

function setupGPS() {
  function onError(err) {
    game.errmsg = err.message;
    updateGPSLocation(35.56972432866644, 139.93689452948513); // tokyowan
    game.geolocation_error = true;
  }

  game.is_mobile = false;
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    game.is_mobile = true;
  }
  if (/Android/i.test(navigator.userAgent)) {
    game.is_android = true;
  }

  // geolocation に対応しているか否かを確認
  if ("geolocation" in navigator) {
    var opt = {
      enableHighAccuracy: game.is_mobile,
      timeout: 10000,
      maximumAge: 0,
    };
    if (game.init_gps_done === false) {
      navigator.geolocation.getCurrentPosition(
        geolocationCallback,
        onError,
        opt
      );
      setInterval(function () {
        /*if(document.hasFocus())*/
        navigator.geolocation.getCurrentPosition(
          geolocationCallback,
          onError,
          opt
        );
        game.geolocationErrorCallback(game.geolocation_error);
      }, 2 * 1000);
    }
    //navigator.geolocation.watchPosition(geolocationCallback, onError, opt);
  } else {
    game.errmsg = "ブラウザが位置情報取得に対応していません";
    game.geolocation_error = true;
  }
  game.init_gps_done = true;
}

function setLeafletMap(map, leaflet) {
  game.L = leaflet;
  game.map = map;

  game.focus_circle = game.L.circleMarker([0, 0], {
    color: "blue",
    fillColor: "blue",
    fillOpacity: 0.1,
    opacity: 0.4,
    radius: 15,
  }).addTo(game.map);
  game.range_circle = game.L.circle([0, 0], {
    color: "black",
    opacity: 0.3,
    //        fillColor: "gray",
    fillOpacity: 0,
    radius: game.conf.range_circle_diameter / 2,
    dashArray: "5, 5",
  }).addTo(game.map);
  game.focus_circle.cnt = 0;
  game.focus_circle.cur_lat = 0;
  game.focus_circle.cur_lng = 0;
  setInterval(function () {
    game.focus_circle.cnt++;
    if (game.focus_circle.cnt % 2 == 0) {
      game.focus_circle.setLatLng(game.L.latLng(0, 0));
    } else {
      game.focus_circle.setLatLng(
        game.L.latLng(game.focus_circle.cur_lat, game.focus_circle.cur_lng)
      );
    }
  }, 500);

  var addtgticon = game.L.icon({
    iconUrl: require("./assets/yellow_transparent_manhole.png"),
    iconSize: [20, 20], // size of the icon
    iconAnchor: [10, 10], // point of the icon which will correspond to marker's location
  });
  game.add_target_cross = game.L.marker([0, 0], { icon: addtgticon }).addTo(
    game.map
  );
}

function confirmPost(post_id, status, cb) {
  axios
    .get(
      game.apibase + "/confirm_post?post_id=" + post_id + "&status=" + status
    )
    .then(function (res) {
      if (cb) cb(res.data);
    });
}

function getTargetInfo(target_id, infocb) {
  axios
    .get(game.apibase + "/target_info?target_id=" + target_id)
    .then(function (res) {
      if (infocb) infocb(res.data);
    });
}

function loadNotifications(params, cb) {
  axios
    .get(game.apibase + `/notifications?page=${params.page}`)
    .then(function (res) {
      if (cb) cb(res.data);
    })
    .catch((res) => {
      if (cb) cb(res);
    });
}

function getNotificationNotRead(cb) {
  axios
    .get(game.apibase + "/notifications/notRead")
    .then(function (res) {
      if (cb) cb(res.data);
    })
    .catch((res) => {
      if (cb) cb(res);
    });
}

function readNotification(id, cb) {
  axios
    .put(game.apibase + `/notifications/${id}`)
    .then(function (res) {
      if (cb) cb(res.data);
    })
    .catch((res) => {
      if (cb) cb(res);
    });
}

function tryCookieSignIn(cb) {
  axios
    .get(game.apibase + "/cookie_signin")
    .then(function (res) {
      game.user_id = res.data.user_id;
      if (cb) cb(res.data);
    })
    .catch((res) => {
      if (cb) cb(res);
    });
}

function signOut(cb) {
  axios.delete(game.apibase + "/sign_out").then(function (res) {
    if (cb) cb(res.data);
  });
}

function getPlayerLocation() {
  if (game.map && game.map.player) {
    return game.map.player.getLatLng();
  } else {
    return null;
  }
}

function getMapCenter() {
  return game.map.getCenter();
}

function getMapZoom() {
  return game.map.getZoom();
}

function weeConnection(connect) {
  loadStatus(function (res) {
    const uid = res.user_id;
    if (!uid) return;
    const wlid = "GC" + uid;
    const action = connect ? "connect" : "disconnect";
    const cburl = window.location.origin + "/api/wee_" + action + "_callback";
    var mode = "";
    if (window.location.origin.indexOf("staging") > 0) mode = "staging.";
    else if (window.location.origin.indexOf("develop") > 0)
      mode = "development.";
    const url =
      "https://" +
      mode +
      "wholeearthexplorer.org/" +
      action +
      "/wee/user?wallet_id=" +
      wlid +
      "&callback_url=" +
      cburl;
    window.location.replace(url);
  });
}

function toggleAddTargetMode() {
  game.add_target_mode = !game.add_target_mode;
}

function isAddTargetMode() {
  return game.add_target_mode;
}

function forgotPassword(em, cb) {
  axios
    .post(game.apibase + "/forgot_password", {
      email: em,
    })
    .then(function (res) {
      cb(res.data);
    })
    .catch((error) => {
      if (error.response) {
        cb(error.response.data);
      } else if (error.request) {
        cb(error.request);
      } else {
        cb(error.message);
      }
    });
}

function resetPassword(tk, pw, cb) {
  axios
    .post(game.apibase + "/reset_password", {
      token: tk,
      password: pw,
    })
    .then(function (res) {
      cb(res.data);
    })
    .catch((res) => {
      cb(res.response.data);
    });
}

function checkExpiredResetPassword(tk, cb) {
  axios
    .post(game.apibase + "/valid_reset_password_token", {
      reset_password_token: tk,
    })
    .then(function (res) {
      cb(res.data);
    })
    .catch((res) => {
      cb(res);
    });
}

function resendConfirmationMailer(em, cb) {
  axios
    .post(game.apibase + "/resend_confirmation_mailer", {
      email: em,
    })
    .then(function (res) {
      cb(res);
    });
}

function getListManholesUser(params, cb) {
  return axios
    .get(game.apibase + `/targets/listByPage`, { params: params })
    .then(function (res) {
      if (cb) cb(res.data);
    })
    .catch(function (error) {
      if (cb) cb(error.response.data);
    });
}

function debugCollectionAPI() {
  axios
    .get(game.apibase + "/users/" + getUserId() + "/posts?type=owning")
    .then(function (res) {
      console.log("RRRRRRRRRRRRRRRRRRRRRRRRRRRR:", res);
    });
}

// survey

function getAllSurvey(targetId, cb) {
  return axios
    .get(game.apibase + `/survey/all?targetId=${targetId}`)
    .then(function (res) {
      if (cb) cb(res.data);
    })
    .catch(function (error) {
      if (cb) cb(error.response.data);
    });
}

function getSurvey(surveyId, cb) {
  return axios
    .get(game.apibase + `/survey?surveyId=${surveyId}`)
    .then(function (res) {
      console.log("get survey: axios post res:", res);
      if (cb) cb(res.data);
    })
    .catch(function (error) {
      console.log("exception:", error);
      if (cb) cb(error.response.data);
    });
}

function updateSurvey(req, cb) {
  axios.post(game.apibase + `/survey/update`, req).then(function (res) {
    cb(res);
  });
}

function createRoundSurvey(req, cb) {
  axios
    .post(game.apibase + `/round_survey`, req)
    .then(function (res) {
      cb(res);
    })
    .catch(function (error) {
      if (cb) cb(error);
    });
}

function updateRoundSurvey(req, cb) {
  axios
    .put(game.apibase + `/round_survey/${req.id}`, req)
    .then(function (res) {
      cb(res);
    })
    .catch(function (error) {
      if (cb) cb(error);
    });
}

function getRoundSurvey(id, cb) {
  return axios
    .get(game.apibase + `/round_survey/${id}`)
    .then(function (res) {
      cb(res.data);
    })
    .catch(function (error) {
      if (cb) cb(error);
    });
}

function addSurveyComment(req, cb) {
  return axios
    .post(game.apibase + `/survey/${req.id}/comments`, req.body)
    .then(function (res) {
      if (cb) cb(res.data);
    })
    .catch(function (error) {
      if (cb) cb(error);
    });
}

function addRoundComment(req, cb) {
  return axios
    .post(game.apibase + `/round_survey/${req.id}/comments`, req.body)
    .then(function (res) {
      if (cb) cb(res.data);
    })
    .catch(function (error) {
      if (cb) cb(error);
    });
}

function getSurveyComments(params, cb) {
  return axios
    .get(game.apibase + `/survey/${params.id}/comments?page=${params.page}`)
    .then(function (res) {
      if (cb) cb(res.data);
    })
    .catch(function (error) {
      if (cb) cb(error.response.data);
    });
}

function getRoundComments(params, cb) {
  return axios
    .get(
      game.apibase + `/round_survey/${params.id}/comments?page=${params.page}`
    )
    .then(function (res) {
      if (cb) cb(res.data);
    })
    .catch(function (error) {
      if (cb) cb(error.response.data);
    });
}

//Admin survey

async function getAdminSurveys(params, cb) {
  await axios
    .get(game.apibase + `/admin/surveys`, { params: params })
    .then(function (res) {
      if (cb) cb(res?.data);
    })
    .catch(function (error) {
      if (cb) cb(error?.response?.data);
    });
}

async function getAdminRoundSurveys(params, cb) {
  await axios
    .get(game.apibase + `/admin/round_survey`, { params: params })
    .then(function (res) {
      if (cb) cb(res?.data);
    })
    .catch(function (error) {
      if (cb) cb(error?.response?.data);
    });
}

async function exportSurvey(id, cb) {
  try {
    const response = await axios.get(game.apibase + `/admin/surveys/${id}/export`, { responseType: "blob" });
    const url = URL.createObjectURL(
      new Blob([response.data], {
        type: "application/vnd.ms-excel",
      })
    );
    const surveyResponse = await axios.get(game.apibase + `/survey?surveyId=${id}`);
    const targetId = surveyResponse?.data?.data?.surveyData?.target_id;
    const targetResponse = await axios.get(game.apibase + `/targets/${targetId}`);
    const ledgerManholeNo = targetResponse?.data?.data?.target?.ledger_manhole_no;
    // Create download link.
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${ledgerManholeNo}(ID ${targetId})点検・調査記録表.xlsx`);
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    if (cb) cb(error?.response?.data);
  }
}

async function exportRoundSurvey(id, cb) {
  try {
    const response = await axios.get(game.apibase + `/admin/round_survey/${id}/export`, { responseType: "blob" });
    const url = URL.createObjectURL(
      new Blob([response.data], {
        type: "application/vnd.ms-excel",
      })
    );
    const roundSurveyResponse = await axios.get(game.apibase + `/round_survey/${id}`);
    const targetId = roundSurveyResponse?.data?.data?.surveyData?.target_id;
    const targetResponse = await axios.get(game.apibase + `/targets/${targetId}`);
    const ledgerManholeNo = targetResponse?.data?.data?.target?.ledger_manhole_no;
    // Create download link.
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${ledgerManholeNo}(ID ${targetId})点検・調査記録表.xlsx`);
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    if (cb) cb(error?.response?.data);
  }
}

function getPostDetail(id, cb) {
  return axios
    .get(game.apibase + `/admin/surveys/${id}`)
    .then(function (res) {
      if (cb) cb(res?.data);
    })
    .catch(function (error) {
      if (cb) cb(error?.response?.data);
    });
}

// ADMIN
async function getUsers(params, cb) {
  await axios
    .get(game.apibase + `/admin/users`, { params: params })
    .then(function (res) {
      if (cb) cb(res?.data);
    })
    .catch(function (error) {
      if (cb) cb(error?.response?.data);
    });
}

async function changeActiveUser(id, cb) {
  await axios
    .post(game.apibase + `/admin/users/${id}/change_active`)
    .then(function (res) {
      if (cb) cb(res?.data);
    })
    .catch(function (error) {
      if (cb) cb(error?.response?.data);
    });
}
function editAdminSurvey(req, cb) {
  return axios
    .put(game.apibase + `/admin/surveys/${req.id}/edit`, req.body)
    .then(function (res) {
      if (cb) cb(res);
    })
    .catch(function (error) {
      if (cb) cb(error?.response?.data);
    });
}

function deleteAdminSurvey(id, cb) {
  return axios
    .delete(game.apibase + `/admin/surveys/${id}`)
    .then(function (res) {
      if (cb) cb(res?.data);
    })
    .catch(function (error) {
      if (cb) cb(error?.response?.data);
    });
}

function deleteRoundSurvey(id, cb) {
  return axios
    .delete(game.apibase + `/admin/round_survey/${id}`)
    .then(function (res) {
      if (cb) cb(res?.data);
    })
    .catch(function (error) {
      if (cb) cb(error?.response?.data);
    });
}

function getUserDetail(id, cb) {
  return axios
    .get(game.apibase + `/admin/users/${id}`)
    .then(function (res) {
      if (cb) cb(res?.data);
    })
    .catch(function (error) {
      if (cb) cb(error?.response?.data);
    });
}

async function createUser(body, cb) {
  await axios
    .post(game.apibase + `/admin/users`, body)
    .then(function (res) {
      if (cb) cb(res?.data);
    })
    .catch(function (error) {
      if (cb) cb(error?.response?.data);
    });
}

function getPrefectures(page, cb) {
  return axios
    .get(game.apibase + `/admin/prefs?page=${page}`)
    .then(function (res) {
      if (cb) cb(res?.data);
    })
    .catch(function (error) {
      if (cb) cb(error?.response?.data);
    });
}

function getCities(params, cb) {
  return axios
    .get(game.apibase + `/admin/prefs/${params.id}/cities?page=${params.page}`)
    .then(function (res) {
      if (cb) cb(res?.data);
    })
    .catch(function (error) {
      if (cb) cb(error?.response?.data);
    });
}

async function getGovernments(params, cb) {
  await axios
    .get(game.apibase + `/admin/admins`, { params: params })
    .then(function (res) {
      if (cb) cb(res?.data);
    })
    .catch(function (error) {
      if (cb) cb(error?.response?.data);
    });
}

async function changeActiveGovernment(id, cb) {
  await axios
    .post(game.apibase + `/admin/admins/${id}/change_active`)
    .then(function (res) {
      if (cb) cb(res?.data);
    })
    .catch(function (error) {
      if (cb) cb(error?.response?.data);
    });
}

async function createGovernment(body, cb) {
  await axios
    .post(game.apibase + `/admin/admins`, body)
    .then(function (res) {
      if (cb) cb(res?.data);
    })
    .catch(function (error) {
      if (cb) cb(error?.response?.data);
    });
}

function getGovernmentDetail(id, cb) {
  return axios
    .get(game.apibase + `/admin/admins/${id}`)
    .then(function (res) {
      if (cb) cb(res?.data);
    })
    .catch(function (error) {
      if (cb) cb(error?.response?.data);
    });
}

async function editGovernment(data, cb) {
  await axios
    .put(game.apibase + `/admin/admins/${data.id}`, data.body)
    .then(function (res) {
      if (cb) cb(res?.data);
    })
    .catch(function (error) {
      if (cb) cb(error?.response?.data);
    });
}

async function changePasswordGovernment(data, cb) {
  await axios
    .post(game.apibase + `/admin/admins/${data.id}/change-password`, data.body)
    .then(function (res) {
      if (cb) cb(res?.data);
    })
    .catch(function (error) {
      if (cb) cb(error?.response?.data);
    });
}

function getAllTargetsAdmin(params, cb) {
  return axios
    .get(game.apibase + `/admin/targets`, { params: params })
    .then(function (res) {
      if (cb) cb(res.data);
    })
    .catch(function (error) {
      if (cb) cb(error.response.data);
    });
}

function getTargetByIdAdmin(target_id, cb) {
  return axios
    .get(game.apibase + `/admin/targets/${target_id}`)
    .then(function (res) {
      if (cb) cb(res.data);
    })
    .catch(function (error) {
      if (cb) cb(error.response.data);
    });
}

async function exportSurveys(req, encoding, cb) {
  await axios
    .post(game.apibase + `/admin/surveys/export/list`, req)
    .then(function (response) {
      const url = URL.createObjectURL(
        new Blob([iconv.encode(response.data, encoding)], {
          type: "application/csv",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", encoding === 'utf-8' ? `surveys.csv` : `surveys_${encoding.toLowerCase().replace('-', '_')}.csv`);
      document.body.appendChild(link);
      link.click();
    })
    .catch(function (error) {
      if (cb) cb(error?.response?.data);
    });
}

async function exportRoundSurveys(req, encoding, cb) {
  await axios
    .post(game.apibase + `/admin/round_survey/export/list`, req)
    .then(function (response) {
      const url = URL.createObjectURL(
        new Blob([iconv.encode(response.data, encoding)], {
          type: "application/csv",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", encoding === 'utf-8' ? `round_surveys.csv` : `round_surveys_${encoding.toLowerCase().replace('-', '_')}.csv`);
      document.body.appendChild(link);
      link.click();
    })
    .catch(function (error) {
      if (cb) cb(error?.response?.data);
    });
}

function getLayer(cb) {
  return axios
    .get(game.apibase + `/layers`)
    .then(function (res) {
      if (cb) cb(res.data);
    })
    .catch(function (error) {
      console.log({ error });
      if (cb) cb(error.response.data);
    });
}

export default {
  conf: game.conf,
  s3base: game.s3base,
  is_localhost: game.is_localhost,
  setUserId: setUserId,
  getUserId: getUserId,
  emailSignIn: emailSignIn,
  emailSignUp: emailSignUp,
  activateEmail: activateEmail,
  setupGPS: setupGPS,
  setLatLngCallback: setLatLngCallback,
  setGeolocationErrorCallBack: setGeolocationErrorCallBack,
  setNotificationsCallBack: setNotificationsCallBack,
  setLeafletMap: setLeafletMap,
  findNearestTarget: findNearestTarget,
  loadStatus: loadStatus,
  getTargetInfo: getTargetInfo,
  confirmPost: confirmPost,
  loadNotifications: loadNotifications,
  getNotificationNotRead: getNotificationNotRead,
  readNotification: readNotification,
  tryCookieSignIn: tryCookieSignIn,
  signOut: signOut,
  getAPIBase: getAPIBase,
  sphericalTrigonometry: sphericalTrigonometry,
  getPlayerLocation: getPlayerLocation,
  getMapCenter: getMapCenter,
  getMapZoom: getMapZoom,
  weeConnection: weeConnection,
  setFocusCircleLocation: setFocusCircleLocation,
  generateUuidv4: generateUuidv4,
  toggleAddTargetMode: toggleAddTargetMode,
  isAddTargetMode: isAddTargetMode,
  setAddTargetCrossLocation: setAddTargetCrossLocation,
  getAddTargetCrossLocation: getAddTargetCrossLocation,
  postAddTarget: postAddTarget,
  postMoveTarget: postMoveTarget,
  editTarget: editTarget,
  getTargetById: getTargetById,
  getListTargets: getListTargets,
  postDeleteTarget: postDeleteTarget,
  forgotPassword: forgotPassword,
  resetPassword: resetPassword,
  checkExpiredResetPassword: checkExpiredResetPassword,
  resendConfirmationMailer: resendConfirmationMailer,
  validToken: validToken,
  changeUserSetting: changeUserSetting,
  surveyUploadImage: surveyUploadImage,
  surveyUpdateImage: surveyUpdateImage,
  surveyDeleteImage: surveyDeleteImage,
  selectMapLayer: selectMapLayer,
  getSelectedMapLayer: getSelectedMapLayer,
  setMapType: setMapType,
  getMapType: getMapType,
  setNextAutoPanTo: setNextAutoPanTo,
  getNextAutoPanTo: getNextAutoPanTo,
  setNextAutoZoom: setNextAutoZoom,
  getNextAutoZoom: getNextAutoZoom,
  setPlayView: setPlayView,
  getPlayView: getPlayView,
  panTo: panTo,
  setMapTypeButtonEnabled: setMapTypeButtonEnabled,
  getMapTypeButtonEnabled: getMapTypeButtonEnabled,
  isLoadingTargets: isLoadingTargets,
  getListManholesUser: getListManholesUser,
  debugCollectionAPI: debugCollectionAPI,
  getSurvey: getSurvey,
  getAllSurvey: getAllSurvey,
  updateSurvey: updateSurvey,
  returnCurrentLocation: returnCurrentLocation,
  getAdminSurveys: getAdminSurveys,
  getAdminRoundSurveys: getAdminRoundSurveys,
  exportSurvey: exportSurvey,
  exportRoundSurvey: exportRoundSurvey,
  exportSurveys: exportSurveys,
  exportRoundSurveys: exportRoundSurveys,
  getPostDetail: getPostDetail,
  addSurveyComment: addSurveyComment,
  getSurveyComments: getSurveyComments,
  getUsers: getUsers,
  changeActiveUser: changeActiveUser,
  getUserDetail: getUserDetail,
  createUser: createUser,
  editAdminSurvey: editAdminSurvey,
  deleteAdminSurvey: deleteAdminSurvey,
  deleteRoundSurvey: deleteRoundSurvey,
  getCities: getCities,
  getPrefectures: getPrefectures,
  getGovernments: getGovernments,
  changeActiveGovernment: changeActiveGovernment,
  createGovernment: createGovernment,
  getGovernmentDetail: getGovernmentDetail,
  editGovernment: editGovernment,
  changePasswordGovernment: changePasswordGovernment,
  getAllTargetsAdmin: getAllTargetsAdmin,
  getTargetByIdAdmin: getTargetByIdAdmin,
  createRoundSurvey: createRoundSurvey,
  updateRoundSurvey: updateRoundSurvey,
  getRoundSurvey: getRoundSurvey,
  getRoundComments: getRoundComments,
  addRoundComment: addRoundComment,
  getLayer: getLayer,
};
