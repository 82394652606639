<template>
    <v-list flat>
      <v-list-item-group
        v-model="selectedItem"
        color="primary"
      >
        <div
          v-for="(item, i) in items"
          :key="i"
        >
          <v-list-item
            link
            :to="item.link"
            v-if="item.keyValue !== 'governments'"
            class="d-flex"
          >
            
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
              link
              :to="item.link"
              v-else-if="showGoverments(item)"
              class="d-flex"
            >
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
          </v-list-item>
        </div>
      </v-list-item-group>
    </v-list>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  data: () => ({
    selectedItem: 1,
    items: [
      { text: 'ユーザー', icon: 'mdi-account', link: '/admin/users', keyValue: 'users' },
      { text: '点検内容', icon: 'mdi-note-edit', link: '/admin/posts', keyValue: 'posts'},
      { text: '市区町村管理者', icon: 'mdi-shield-account', link: '/admin/governments', keyValue: 'governments' },
      { text: 'マンホール', icon: 'mdi-alpha-m-circle', link: '/admin/manholes', keyValue: 'manholes' },
      { text: '巡視', icon: 'mdi-alpha-r-circle-outline', link: "/admin/round", keyValue: 'round_survey'},
    ],
  }),

  computed: {
    ...mapGetters(["currentUser"]),
  },

  methods: {
    showGoverments(item) {
      return item.keyValue === 'governments' && this.currentUser.role === 'admin'
    }
  }
};

</script>

<style>
  .layout-drawer {
    padding-bottom: 0px;
    padding-top: 0px;
  }
  .layout-drawer__icon {
    margin-bottom: 8px;
    margin-top: 8px;
  }
</style>
