<template>
  <v-card class="mx-auto admin-post" max-width="90%" outlined dark>
    <v-list-item>
      <v-list-item-content>
        <div class="text-overline primary--text admin-post_title mb-2 mt-2">
          点検内容を投稿する
        </div>
      </v-list-item-content>
    </v-list-item>
    <!-- <post-detail-ctrl></post-detail-ctrl> -->
    <v-divider></v-divider>
    <FormPostDetail/>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import FormPostDetail from './FormPostDetail.vue';
// import FormPostPhoto from './FormPostPhoto.vue';
// import PostDetailCtrl from "./PostDetailCtrl.vue";

export default {
  components: {  FormPostDetail },
  data() {
    return {};
  },
 
  mounted() {},
  methods: {
    exportCSV() {
      this.$store.dispatch("adminPosts/exportSurvey", this.$route.params.id);
    },
    changeStatus() {
      this.$store.dispatch("adminPosts/deletePost", this.$route.params.id);
    },
  },
  computed: {
    ...mapGetters(["dataSurvey", "changeStatusPost"]),
  },
};
</script>

<style lang="scss" scoped>
.admin-post {
  &_title {
    font-size: 20px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  ::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > td,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
    font-size: 0.75rem;
  }
  &_images {
    max-width: 50vw;
    padding: 16px 0;
  }
}
</style>>
